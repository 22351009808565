import { Component } from 'react';
import { Link } from 'react-router-dom';

class AppAccountBreadcrumb extends Component<any, any> {
  render() {
    return (
      <Link to={`/admin/account`}>
        <div className={`bg-[#B02A2A] text-white py-1.5 px-3 rounded-xl`}>Аккаунт фонда</div>
      </Link>
    );
  }
}

export default AppAccountBreadcrumb;
