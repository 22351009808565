export const PARTY_ACCOUNTS = [
  '40703810638090109172',
  '40703810002140130695',
  '40703810203010100138',
  '40703810604000000275',
  '40703810605160100150',
  '40703810107000100220',
  '40703810308000108033',
  '40703810510020100149',
  '40703810411020100320',
  '40703810912000100204',
  '40703810013000103370',
  '40703810470120100453',
  '40703810974000004780',
  '40703810300000007938',
  '40703810617000000122',
  '40703810418350101711',
  '40702810960330100697',
  '40703810320130000003',
  '40703810222240103666',
  '40703810236170111066',
  '40703810260310000050',
  '40703810026030101731',
  '40703810627320101183',
  '40703810529010102431',
  '40703810030000000163',
  '40703810331280126019',
  '40703810832000100929',
  '40703810033020101254',
  '40703810155000000024',
  '40703810535000000507',
  '40703810609400007912',
  '40703810136000100551',
  '40703810238090000642',
  '40703810338090000649',
  '40703810541020005129',
  '40703810204210001053',
  '40703810342050000989',
  '40703810143020109921',
  '40703810444020040167',
  '40703810845000100290',
  '40703810546000000045',
  '40703810247000110137',
  '40703810748000105691',
  '40703810249090110534',
  '40703810750000000429',
  '40703810351010100910',
  '40703810201000102832',
  '40703810102350002749',
  '40703810606020100001',
  '40703810409160105669',
  '40703810860320102526',
  '40703810160350050128',
  '40703810060300100403',
  '40703810725000102897',
  '40703810628000102967',
  '40703810640480000013',
  '40703810137180101147',
  '40703810339010000223',
  '40703810476000136001',
  '40703810660340100795',
  '40703810662190100571',
  '40703810565000100049',
  '40703810071020110007',
  '40703810752090000422',
  '40703810653000060525',
  '40703810254400015050',
  '40703810955230108488',
  '40703810256000001006',
  '40703810950340110659',
  '40703810016020102554',
  '40703810240150000002',
  '40703810359190100680',
  '40703810760000100252',
  '40703810561000100511',
  '40703810763020100134',
  '40703810764030101704',
  '40703810766000000141',
  '40703810167100106126',
  '40703810568170103041',
  '40703810769020100165',
  '40703810470000102241',
  '40703810767460000223',
  '40703810772020100246',
  '40703810460360000059',
  '40703810475020100335',
  '40703810936180100037',
  '40703810967470100162',
  '40703810877030100143',
];
