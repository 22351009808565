import { Component } from 'react';
import AppNumberWithSpaces from '../../shared/AppNumberWithSpaces';

interface Props {
  fund_name?: string;
  amount?: number;
  code?: string;
}

class AppDonationRequestHeaderInfo extends Component<Props, any> {
  render() {
    const { fund_name, amount } = this.props;
    return (
      <div
        className={`text-white bg-primaryGradient py-1 px-2 w-full flex items-center justify-between rounded-lg gap-x-4`}
      >
        <div className={`text-lg lg:text-xl font-semibold uppercase`}>
          <p className={`leading-tight`}>
            {this.props.code && <span className={`font-semibold`}>№{this.props.code}</span>}{' '}
            {fund_name}
          </p>
        </div>
        <div className={`flex flex-row items-center`}>
          <p className={`text-base uppercase mr-4 font-medium leading-4`}>Сумма пожертвования:</p>
          <p className={`text-lg lg:text-xl font-semibold`}>
            <AppNumberWithSpaces value={amount || 0} />
            &nbsp;₽
          </p>
        </div>
      </div>
    );
  }
}

export default AppDonationRequestHeaderInfo;
