import React from 'react';
import { DonationRequest } from '../../../types/DonationRequest';
import DonationRequestRow from './DonationRequestRow';

interface DonationRequestsTableProps {
  donationRequests: DonationRequest[];
  onOpenHandler: (donationRequest: DonationRequest) => void;
}

const DonationRequestsTable: React.FC<DonationRequestsTableProps> = ({
  donationRequests,
  onOpenHandler,
}) => (
  <table className="w-full border-collapse bg-gray-200">
    <thead>
      <tr className="bg-white text-center">
        <th className="p-2 uppercase text-xs font-semibold border border-gray-300">№ заявки</th>
        <th className="p-2 uppercase text-xs font-semibold border border-gray-300">
          Статус рассмотрения
        </th>
        <th className="p-2 uppercase text-xs font-semibold border border-gray-300">
          Статус оплаты
        </th>
        <th className="p-2 uppercase text-xs font-semibold border border-gray-300">
          Дата подачи заявки
        </th>
        <th className="p-2 uppercase text-xs font-semibold border border-gray-300">
          Дата обновления статуса
        </th>
        <th className="p-2 uppercase text-xs font-semibold border border-gray-300">
          Дата регистрации юр.лица
        </th>
        <th className="p-2 uppercase text-xs font-semibold border border-gray-300">
          Наименование юр. лица
        </th>
        <th className="p-2 uppercase text-xs font-semibold border border-gray-300">Сумма</th>
        <th className="p-2 uppercase text-xs font-semibold border border-gray-300">Руководство</th>
        <th className="p-2 uppercase text-xs font-semibold border border-gray-300" colSpan={3}>
          Юридические документы
        </th>
        <th className="p-2 uppercase text-xs font-semibold border border-gray-300">Подробнее</th>
      </tr>
    </thead>
    <tbody>
      {donationRequests.map((donationRequest, index) => (
        <DonationRequestRow
          key={index}
          donationRequest={donationRequest}
          onOpenHandler={onOpenHandler}
          rowStyle={`${
            index % 2 === 0
              ? 'bg-white border border-gray-300 text-sm'
              : 'bg-slate-200 border border-gray-300 text-sm'
          }`}
        />
      ))}
    </tbody>
  </table>
);

export default DonationRequestsTable;
