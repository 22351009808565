import React from 'react';
import AppRoutes from './routes';
import GlobalProvider from './providers/global';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div className="App">
      <GlobalProvider>
        <AppRoutes />
      </GlobalProvider>
      <ToastContainer />
      <p style={{ display: 'none' }}>0.9.22</p>
    </div>
  );
}

export default App;
