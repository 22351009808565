import React from 'react';
import dayjs from 'dayjs';

interface FilterBarProps {
  status: string;
  setStatus: React.Dispatch<React.SetStateAction<string>>;
  ogrnFilter: string;
  setOgrnFilter: React.Dispatch<React.SetStateAction<string>>;
  innFilter: string;
  setInnFilter: React.Dispatch<React.SetStateAction<string>>;
  startDate: Date | null;
  setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
  endDate: Date | null;
  setEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
  searchHandler: () => void;
  resetFilters: () => void;
}

const FilterBar: React.FC<FilterBarProps> = ({
  status,
  setStatus,
  ogrnFilter,
  setOgrnFilter,
  innFilter,
  setInnFilter,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  searchHandler,
  resetFilters,
}) => {
  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    if (name === 'start_date') {
      setStartDate(value ? new Date(value) : null);
    } else if (name === 'end_date') {
      setEndDate(value ? new Date(value) : null);
    }
  };

  return (
    <div className="flex flex-wrap bg-primary rounded-lg py-2 px-5 items-center gap-4">
      <div className="text-white uppercase font-semibold text-sm">Фильтрация:</div>
      <div className="w-full sm:w-auto">
        <select
          id="status"
          name="status"
          className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
          value={status}
          onChange={(e) => setStatus(e.target.value)}
        >
          <option value="all">Все</option>
          <option value="fund">Заявки от рег. фондов</option>
          <option value="in_processing">Заявка в обработке</option>
          <option value="approved">Заявка утверждена</option>
          <option value="rejected">Заявка отклонена</option>
          <option value="paid">Заявка оплачена</option>
          <option value="by_not_paid">Заявка не оплачена</option>
        </select>
      </div>
      <div className="w-full sm:w-auto">
        <input
          type="text"
          name="ogrn_filter"
          placeholder="Поиск по ОГРН"
          value={ogrnFilter}
          onChange={(e) => setOgrnFilter(e.target.value)}
          className="w-full rounded-lg focus:outline-none"
        />
      </div>
      <div className="w-full sm:w-auto">
        <input
          type="text"
          name="inn_filter"
          placeholder="Поиск по ИНН"
          value={innFilter}
          onChange={(e) => setInnFilter(e.target.value)}
          className="w-full rounded-lg focus:outline-none"
        />
      </div>
      <div className="flex flex-col sm:flex-row items-start sm:items-center text-sm w-full sm:w-auto">
        <span className="mr-4 text-white font-semibold">В период с</span>
        <input
          type="date"
          name="start_date"
          max={dayjs().format('YYYY-MM-DD')}
          value={startDate ? dayjs(startDate).format('YYYY-MM-DD') : ''}
          onChange={handleDateChange}
          className="rounded-lg"
        />
      </div>
      <div className="flex flex-col sm:flex-row items-start sm:items-center text-sm w-full sm:w-auto">
        <span className="mr-4 text-white font-semibold">по</span>
        <input
          type="date"
          name="end_date"
          min={startDate ? dayjs(startDate).format('YYYY-MM-DD') : undefined}
          value={endDate ? dayjs(endDate).format('YYYY-MM-DD') : ''}
          onChange={handleDateChange}
          className="rounded-lg"
        />
      </div>
      <div className="flex space-x-2 w-full sm:w-auto">
        <button
          className="bg-white text-primary rounded-lg font-semibold px-6 py-2 w-full"
          onClick={searchHandler}
        >
          Поиск
        </button>
        <button
          className="bg-gray-200 text-primary rounded-lg font-semibold px-6 py-2 w-full"
          onClick={resetFilters}
        >
          Сбросить
        </button>
      </div>
    </div>
  );
};

export default FilterBar;
