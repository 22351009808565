import { Component } from 'react';
import { DonationRequest, DonationRequestStatus } from '../../../types/DonationRequest';
import { apiWrapper } from '../../../utils/api';

interface Props {
  donationRequest: DonationRequest;
  status: DonationRequestStatus;
  id: number;
  onUpdateHandler?: (updatedData: DonationRequest) => void;
}

class AppDonationRequestApprovement extends Component<Props, any> {
  updateStatusHandler = async (isApproved: boolean): Promise<void> => {
    await apiWrapper
      .patch(`/donation_requests/${this.props.id}`, {
        donation_request: {
          status: isApproved ? 'approved' : 'rejected',
        },
      })
      .then((responseData: any) => {
        if (this.props.onUpdateHandler) this.props.onUpdateHandler(responseData);
      });
  };

  render() {
    let me = this;
    const { status } = this.props;

    let statusText;
    switch (status) {
      case DonationRequestStatus.Rejected:
        statusText = 'заявка не соответствует требованиям';
        break;
      case DonationRequestStatus.InProgress:
        statusText = 'заявка в обработке';
        break;
      case DonationRequestStatus.Approved:
        statusText = 'заявка утверждена';
        break;
      default:
        statusText = 'Не определено';
    }

    return (
      <div className={`flex flex-col w-full pt-3`}>
        <div className={`w-full text-center flex flex-col items-center justify-center`}>
          <div
            data-testid="status-icon-container"
            className={`
            w-8 h-8 rounded-full flex items-center justify-center bg-white
            ${status === DonationRequestStatus.Rejected ? 'bg-redGradient' : ''}
            ${status === DonationRequestStatus.Approved ? 'bg-greenGradient' : ''}
          `}
          >
            {status === DonationRequestStatus.Rejected && (
              <i className="fas fa-times text-white text-lg"></i>
            )}
            {status === DonationRequestStatus.InProgress && (
              <i className="fas fa-spinner fa-spin text-gray-400 text-2xl"></i>
            )}
            {status === DonationRequestStatus.Approved && (
              <i className="fas fa-check text-white text-lg"></i>
            )}
          </div>
          <div
            className={`
            font-semibold uppercase text-base mt-1 max-w-xs leading-tight
            ${status === DonationRequestStatus.Rejected ? 'text-red-800' : ''}
            ${status === DonationRequestStatus.InProgress ? 'text-gray-400' : ''}
            ${status === DonationRequestStatus.Approved ? 'text-green-500' : ''}
          `}
          >
            {statusText}
          </div>
          {me.props.donationRequest.main_status_updater ? (
            <span
              style={{ fontSize: '0.7em', marginTop: '20px' }}
              className={`
              font-semibold uppercase text-base mt-1 max-w-xs leading-tight
              ${status === DonationRequestStatus.Rejected ? 'text-red-800' : ''}
              ${status === DonationRequestStatus.InProgress ? 'text-gray-400' : ''}
              ${status === DonationRequestStatus.Approved ? 'text-green-500' : ''}
            `}
            >
              <i className={'fal fa-user'}></i>&nbsp;{me.props.donationRequest.main_status_updater}
            </span>
          ) : (
            <></>
          )}
          <div></div>
          {me.props.donationRequest.debet &&
          me.props.donationRequest.debet === Number(me.props.donationRequest.amount) ? (
            <div
              className={
                'm-0 flex flex-row items-center content-center flex-nowrap whitespace-nowrap text-green-600 text-sm uppercase font-semibold mb-2'
              }
            >
              <i className={'fas fa-check-circle mr-1'}></i>
              <p>Заявка оплачена</p>
            </div>
          ) : (
            <div
              className={
                'm-0 flex flex-row items-center content-center flex-nowrap whitespace-nowrap text-red-600 uppercase font-semibold mb-2'
              }
            >
              <i className={'fas fa-times-circle mr-1'}></i>
              <p>Заявка не оплачена</p>
            </div>
          )}
        </div>
        <div
          style={{}}
          className={`w-full grid grid-cols-12 gap-x-1 mt-${
            me.props.donationRequest.main_status_updater ? 2 : 8
          }`}
        >
          <div className="col-span-6">
            <button
              className="h-full w-full border-none rounded-lg text-center text-white uppercase py-3 bg-greenGradient text-sm font-semibold"
              aria-label={statusText === 'заявка утверждена' ? 'Снять текущий статус' : `УТВЕРДИТЬ`}
              onClick={() => this.updateStatusHandler(true)}
            >
              {statusText === 'заявка утверждена' ? 'СНЯТЬ УТВЕРЖДЕНИЕ' : `УТВЕРДИТЬ`}
            </button>
          </div>
          <div className="col-span-6">
            <button
              className="h-full w-full border-none rounded-lg text-center text-white uppercase py-3 bg-redGradient text-sm font-semibold"
              aria-label={`ОТКАЗАТЬ`}
              onClick={() => this.updateStatusHandler(false)}
            >
              {statusText === 'заявка не соответствует требованиям' ? 'СНЯТЬ ОТКАЗ' : 'ОТКАЗАТЬ'}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default AppDonationRequestApprovement;
