export enum InputStatus {
  NOT_VALID = -1,
  WAITING,
  VALID,
}

export const inputStatusTranslation: Record<InputStatus, string> = {
  [InputStatus.NOT_VALID]: 'Данные не соответствуют сведениям ЕГРЮЛ',
  [InputStatus.WAITING]: 'Ожидание',
  [InputStatus.VALID]: 'Валидировано',
};
