import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import AppInput from '../../shared/AppInput';
import { DonationRequest } from '../../../types/DonationRequest';
import { apiWrapper } from '../../../utils/api';

interface AppPurposeDialogProps {
  id: number | string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (updatedData: DonationRequest) => void;
}

interface AppPurposeDialogState {
  purpose: string;
}

class AppPurposeDialog extends Component<AppPurposeDialogProps, AppPurposeDialogState> {
  constructor(props: AppPurposeDialogProps) {
    super(props);
    this.state = {
      purpose: '',
    };
  }

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    this.setState({ [name]: value } as unknown as Pick<
      AppPurposeDialogState,
      keyof AppPurposeDialogState
    >);
  };

  handleSubmit = async (): Promise<void> => {
    await apiWrapper
      .patch(`/donation_requests/${this.props.id}`, {
        donation_request: {
          purpose: this.state.purpose,
        },
      })
      .then((responseData: any) => {
        this.props.onSubmit(responseData);
      });

    this.setState({ purpose: '' });
    this.props.onClose();
  };

  onCloseHandler = (): void => {
    this.setState({ purpose: '' });
    this.props.onClose();
  };

  render() {
    const { isOpen } = this.props;
    const { purpose } = this.state;

    return (
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={this.onCloseHandler}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-screen-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    Редактирование: Целевое назначение пожертвования
                  </Dialog.Title>
                  <div className="mt-2">
                    <AppInput
                      id="purpose"
                      name="purpose"
                      label="Целевое назначение пожертвования"
                      type="text"
                      value={purpose}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div className="mt-4 w-full flex flex-row justify-between">
                    <div>
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-[#C9C8D6] px-4 py-2 text-sm font-medium text-[#8C89AC] focus:outline-none"
                        onClick={this.onCloseHandler}
                      >
                        Закрыть
                      </button>
                    </div>
                    <div>
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-none px-4 py-2 text-sm font-medium text-white focus:outline-none bg-primaryGradient"
                        onClick={() => this.handleSubmit()}
                      >
                        Сохранить
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  }
}

export default AppPurposeDialog;
