import React from 'react';
import UserProvider from './user';

class GlobalProvider extends React.Component<any, any> {
  render() {
    return <UserProvider>{this.props.children}</UserProvider>;
  }
}

export default GlobalProvider;
