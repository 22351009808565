import React from 'react';

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartEvent,
  Legend,
  LinearScale,
  LogarithmicScale,
  Title,
  Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LogarithmicScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface FloatingBarChartProps {
  labels: string[];
  data: any[];
  title: string;
  scaleType?: string;
  triggerIndexOfLabel: (_triggeredIndex: number) => void;
}

interface FloatingBarChartState {}

export default class FloatingBarChart extends React.Component<
  FloatingBarChartProps,
  FloatingBarChartState
> {
  containerRef: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();

  render() {
    let me = this;
    return (
      <div ref={me.containerRef} className={'relative'}>
        <Bar
          options={{
            onClick(event: ChartEvent) {
              // alert(12);
              event.native?.stopPropagation();
            },
            responsive: true,
            plugins: {
              legend: {
                display: false,
                position: 'bottom',
              },
              title: {
                display: false,
              },
            },
            scales: {
              x: {
                display: true,
                ticks: {
                  font: {
                    size: me.props.labels.length > 15 ? 7 : undefined,
                  },
                },
              },
              y: {
                display: true,
                type: 'logarithmic',
              },
            },
          }}
          data={{
            labels: me.props.labels,
            datasets: me.props.data,
          }}
        />
        <div
          onClick={(_e: React.MouseEvent<HTMLDivElement>) => {
            if (!me.containerRef.current) return;
            let ctx = me.containerRef.current.getElementsByTagName('canvas')[0].getContext('2d');
            if (!ctx) return;
            _e.stopPropagation();
            let width = me.containerRef.current.offsetWidth;
            // let height = me.containerRef.current.offsetWidth;
            let tickSize = width / (me.props.labels.length + 2);
            let xOfClick = _e.clientX - 1.5 * tickSize - me.containerRef.current.offsetLeft;
            if (xOfClick < 0) xOfClick = 0;
            let index = Math.floor((xOfClick / width) * (me.props.labels.length + 2));
            if (index >= me.props.labels.length) index = me.props.labels.length - 1;
            me.props.triggerIndexOfLabel(index);
            // alert(
            //   xOfClick +
            //     ' ' +
            //     width +
            //     ' ' +
            //     height +
            //     ' ' +
            //     ,
            // );
          }}
          style={{
            display: 'flex',
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            height: '30px',
            background: 'transparent',
            zIndex: 2,
            cursor: 'pointer',
          }}
        />
      </div>
    );
  }
}
