import dayjs from 'dayjs';

export const prepareDate = (
  start_date_state: string | Date | null,
  end_date_state: string | Date | null,
): {
  start_date: string;
  end_date: string;
} => {
  let start_date = '';
  let end_date = '';

  if (start_date_state) {
    start_date = dayjs(start_date_state).format('YYYY-MM-DD');
  }

  if (end_date_state) {
    end_date = dayjs(end_date_state).format('YYYY-MM-DD');
  }

  return { start_date, end_date };
};
