import React, { Component } from 'react';

interface AppNumberWithSpacesProps {
  value: number;
}

class AppNumberWithSpaces extends Component<AppNumberWithSpacesProps, any> {
  render() {
    const { value } = this.props;
    const formattedValue = value.toLocaleString('ru-RU', {
      minimumFractionDigits: value % 1 === 0 ? 0 : 2,
      maximumFractionDigits: 2,
    });

    return <>{formattedValue}</>;
  }
}

export default AppNumberWithSpaces;
