import React, { ReactNode, useContext } from 'react';
import { UserContext } from '../../../store/user';
import { Navigate, useLocation } from 'react-router-dom';

interface ProtectedRouteProps {
  path: string;
  children: ReactNode;
  isAdminRoute?: boolean;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children,
  path,
  isAdminRoute = false,
}) => {
  const user = useContext(UserContext);
  const location = useLocation();

  if (user.loading) {
    return (
      <div className="w-screen h-screen bg-primary z-10 flex flex-col items-center justify-center">
        <i className="far fa-sync fa-spin text-white fa-5x"></i>
        <p className="text-center text-white font-semibold uppercase text-lg mt-10">Загрузка</p>
      </div>
    );
  }

  // Allow unauthenticated users to access `/login` or `/admin/login` pages
  if (
    !user.is_authed &&
    (path === '/login' || path === '/registration' || path === '/admin/login')
  ) {
    return <>{children}</>;
  }

  if (!user.is_authed) {
    // If user is not authenticated, redirect to the appropriate login page
    const navigateTo = path.includes('admin') ? '/admin/login' : '/login';
    return <Navigate to={navigateTo} state={{ from: location }} replace />;
  }

  // Block access to `/admin` routes for non-moderators
  if (path.startsWith('/admin') && !user.is_moderator) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  if (user.is_moderator) {
    // Moderators can only access `/admin` routes, except `/login`
    if (!path.startsWith('/admin') && path !== '/login') {
      return <Navigate to="/admin" state={{ from: location }} replace />;
    }
  }

  // Allow access to the route if none of the above conditions apply
  return <>{children}</>;
};

export default ProtectedRoute;
