import React, { Component, Fragment } from 'react';
import Dashboard from '../../../containers/admin/Dashboard';
import { Fund } from '../../../types/Fund';
import { apiWrapper } from '../../../utils/api';
import AppNumberWithSpaces from '../../../components/shared/AppNumberWithSpaces';
import AppTable from '../../../components/shared/AppTable';
import { Transition } from '@headlessui/react';
import { errorToast } from '../../../utils/errorToast';

interface DonaterInfo {
  organization_name: string;
  total_donated: number | string;
}

interface LimitPageState {
  donated: number;
  funds: Fund[];
  current_fund: Fund | null;
  donaters: DonaterInfo[];
  loading: boolean;
}

class LimitPage extends Component<any, LimitPageState> {
  constructor(props: any) {
    super(props);
    this.state = {
      donated: 0,
      funds: [],
      current_fund: null,
      donaters: [],
      loading: false,
    };
  }

  async componentDidMount() {
    await apiWrapper.get('/funds').then(async (response: any) => {
      this.setState(
        {
          funds: [...response],
          current_fund: { ...response[0] },
        },
        async () => {
          await this.getLimit();
          await this.getDonaters();
        },
      );
    });
  }

  async setCurrentFund(fund: Fund): Promise<void> {
    this.setState({ current_fund: fund, donaters: [], loading: true }, async () => {
      await this.getLimit(); // Fetch the new limit for the selected fund
      await this.getDonaters(); // Fetch new donaters
    });
  }

  async getLimit(): Promise<void> {
    if (this.state?.current_fund) {
      await apiWrapper.get(`/funds/${this.state.current_fund?.id}/limit`).then((response: any) => {
        this.setState({ donated: response.donated });
        if (Number(response.donated) > Number(this.state?.current_fund?.limit)) {
          errorToast('Лимит превышен');
        }
      });
    }
  }

  async getDonaters(): Promise<void> {
    if (this.state?.current_fund) {
      this.setState({ loading: true });
      await apiWrapper
        .get(`/funds/${this.state.current_fund?.id}/donaters`)
        .then((response: any) => {
          this.setState({ donaters: [...response], loading: false });
        })
        .catch(() => {
          this.setState({ donaters: [], loading: false });
        });
    }
  }

  render() {
    const { loading } = this.state;

    return (
      <Dashboard
        content={
          <div className={`pt-10 pb-4 px-4 bg-slate-200 overflow-y-scroll`}>
            <div
              className="grid grid-cols-12 gap-2 overflow-y-scroll"
              style={{ height: 'calc(100vh - 180px)' }}
            >
              <div className="col-span-3 overflow-y-scroll">
                <div className={`relative h-full`}>
                  <div
                    className={`absolute left-0 top-0 w-full text-white text-sm text-center p-2 font-semibold bg-primaryGradient rounded-t-lg uppercase`}
                  >
                    Региональные отделения Партии
                    {/*ЖЕРТВОВАТЕЛИ*/}
                  </div>
                  <div className={`bg-white h-full pt-10`}>
                    {this.state.funds.map((f: Fund, index: number) => (
                      <div
                        key={index}
                        className={`cursor-pointer text-[#2C2C2C] uppercase font-semibold leading-tight px-3 py-4 border-b border-b-[#C9C8D6] border-r-4
                                  ${
                                    this.state?.current_fund?.id === f?.id
                                      ? 'border-r-primary'
                                      : 'border-r-transparent'
                                  }
                                `}
                        onClick={() => this.setCurrentFund(f)}
                      >
                        {f.name}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-span-9">
                {this.state.current_fund && (
                  <Transition
                    as={Fragment}
                    show={!loading}
                    enter="transform transition duration-[400ms]"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transform duration-200 transition ease-in-out"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className={`h-full`} key={`fund-${this.state.current_fund.id}`}>
                      <div
                        className={`w-full text-white text-sm text-center p-2 font-semibold bg-primaryGradient rounded-t-lg uppercase`}
                      >
                        Поступления
                      </div>
                      <div className={`bg-white h-full p-4`}>
                        <div className={`w-full border-2 border-[#C9C8D6] rounded-xl py-2 px-4`}>
                          <div
                            className={`h-8 w-full bg-primary text-white font-semibold text-center text-sm py-1 flex flex-row items-center justify-center mb-2`}
                          >
                            <div>Общий лимит поступлений</div>
                            <div className={`ml-2 bg-white text-primary rounded-full px-3 py-1`}>
                              <AppNumberWithSpaces value={Number(this.state?.current_fund.limit)} />
                              &nbsp;₽
                            </div>
                          </div>
                          {/*{this.state.donated && this.state.limit && (*/}
                          <div
                            className={`w-full flex flex-row flex-nowrap items-center space-x-1`}
                          >
                            <div
                              className={`h-8 text-white font-semibold text-center text-sm py-1 flex flex-row items-center justify-center`}
                              style={{
                                width: `${
                                  (this.state.donated * 100) /
                                  Number(this.state?.current_fund.limit || 1)
                                }%`,
                                minWidth: '50%',
                                background: `${
                                  this.state.donated > (this.state?.current_fund.limit || 1)
                                    ? '#B02A2A'
                                    : '#00529C'
                                }`,
                              }}
                            >
                              <div>Сумма поступивших средств за период</div>
                              <div
                                className={`ml-2 bg-white text-[#00529C] rounded-full px-3 py-1`}
                              >
                                <AppNumberWithSpaces value={this.state.donated} />
                                &nbsp;₽
                              </div>
                            </div>
                            <div
                              className={`h-8 bg-[#E8ECF4] flex-1`}
                              style={{
                                display: `${
                                  this.state.donated >= (this.state?.current_fund.limit || 1)
                                    ? 'none'
                                    : 'flex'
                                }`,
                              }}
                            ></div>
                          </div>
                          {/*)}*/}
                        </div>

                        {/*{this.state.donaters.length > 0 ? (*/}
                        {/*  <div className={`mt-4`}>*/}
                        {/*    <AppTable*/}
                        {/*      headers={[*/}
                        {/*        {*/}
                        {/*          name: 'organization_name',*/}
                        {/*          title: 'Наименование жертвователя',*/}
                        {/*          align: 'left',*/}
                        {/*        },*/}
                        {/*        {*/}
                        {/*          name: 'total_donated',*/}
                        {/*          title: 'Сумма поступлений',*/}
                        {/*          align: 'center',*/}
                        {/*        },*/}
                        {/*      ]}*/}
                        {/*      data={this.state.donaters}*/}
                        {/*    />*/}
                        {/*  </div>*/}
                        {/*) : (*/}
                        {/*  <div className="flex p-4 items-center justify-center text-center h-full text-gray-500 text-sm font-semibold">*/}
                        {/*    На данный момент поступления отсутствуют*/}
                        {/*  </div>*/}
                        {/*)}*/}
                        {this.state.loading ? (
                          <div className="flex p-4 items-center justify-center text-center h-full text-gray-500 text-sm font-semibold">
                            Загрузка данных...
                          </div>
                        ) : this.state.donaters.length > 0 ? (
                          <div className={`mt-4`}>
                            <AppTable
                              headers={[
                                {
                                  name: 'organization_name',
                                  title: 'Наименование жертвователя',
                                  align: 'left',
                                },
                                {
                                  name: 'total_donated',
                                  title: 'Сумма поступлений',
                                  align: 'center',
                                },
                              ]}
                              data={this.state.donaters}
                            />
                          </div>
                        ) : (
                          <div className="flex p-4 items-center justify-center text-center h-full text-gray-500 text-sm font-semibold">
                            На данный момент поступления отсутствуют
                          </div>
                        )}
                      </div>
                    </div>
                  </Transition>
                )}
              </div>
            </div>
          </div>
        }
      />
    );
  }
}

export default LimitPage;
