import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../../../store/user';

const BNT_BASE_CLASS = `flex flex-row items-center rounded-md text-white h-20 py-1 px-6 flex-1 uppercase`;

class AppCompanyHeader extends Component<any, any> {
  static contextType = UserContext;
  context!: React.ContextType<typeof UserContext>;

  getUserDisplayName = (): string => {
    const full_name = this.context?.full_name;
    if (!full_name) return 'Имя не указано';

    const names = full_name.split(' ');
    const initials = names.map((name) => name.charAt(0).toUpperCase());

    if (initials.length === 3) return `${names[0]} ${initials[1]}.${initials[2]}.`;

    return names[0];
  };

  render() {
    return (
      <div
        className={`bg-slate-100 w-full rounded-lg flex flex-row items-center justify-between flex-wrap py-6 px-8 gap-y-2`}
      >
        <div className="w-full xl:w-1/2 flex flex-row items-center gap-x-6">
          <div
            className={`hidden xl:flex h-16 w-16 rounded-full bg-primaryGradient  items-center justify-center text-white`}
          >
            <i className={`far fa-user`} style={{ transform: 'scale(1.5)' }}></i>
          </div>
          <div className={`flex flex-col`}>
            <span className={`text-zinc-800 text-3xl font-semibold`}>
              {this.getUserDisplayName()}
            </span>
            <span className={`text-zinc-800 text-base font-medium`}>
              {this.context?.organization?.short_name || this.context?.organization?.full_name}
            </span>
          </div>
        </div>
        <div className="w-full xl:w-1/2">
          <div
            className={`flex flex-row items-center justify-center flex-wrap gap-x-6 w-full gap-y-1`}
          >
            <Link to={`/new_application`} className={`bg-primaryGradient ${BNT_BASE_CLASS}`}>
              <div className={`text-left leading-4 text-base font-medium`}>
                Оставить заявку
                <br />
                на пожертвование
              </div>
              <i className={`far fa-chevron-right ml-auto`}></i>
            </Link>

            <Link to={`/new_application?fund=true`} className={`bg-redGradient ${BNT_BASE_CLASS}`}>
              <div className={`text-left leading-4 text-xs font-medium`}>
                оставить заявку
                <br />
                от фонда регионального
                <br />
                сотрудничества и развития
              </div>
              <i className={`far fa-chevron-right ml-auto`}></i>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default AppCompanyHeader;
