import React, { Component } from 'react';
import styles from './AppLeftFilterBtn.module.scss';

interface AppLeftFilterBtnProps {
  title: string;
  icon?: React.ReactNode;
  classStr?: string;
  onClickHandler: (status: string) => void;
  status: string;
  isActive?: boolean;
}
class AppLeftFilterBtn extends Component<AppLeftFilterBtnProps, any> {
  render() {
    const { title, icon, classStr, status = '', isActive, onClickHandler } = this.props;

    return (
      <button
        className={`${styles.default} ${isActive ? styles.active : ''} ${classStr}`}
        onClick={() => onClickHandler(status)}
      >
        <div>
          {!icon && <i className="fas fa-th"></i>}
          {icon}
        </div>
        <div>{title}</div>
      </button>
    );
  }
}

export default AppLeftFilterBtn;
