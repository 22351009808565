import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import AppFileInput from '../AppFileInput';

interface AppUploadsDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (files: { agreement: File; letter: File }) => void;
}

interface AppUploadsDialogState {
  agreementFile: File | null;
  letterFile: File | null;
}

class AppUploadsDialog extends Component<AppUploadsDialogProps, AppUploadsDialogState> {
  handleAgreementFilesOnChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    const files = event.target.files;
    if (files) {
      this.setState({ agreementFile: files[0] });
    }
  };

  handleLetterFilesOnChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const files = event.target.files;
    if (files) {
      this.setState({ letterFile: files[0] });
    }
  };

  handleSubmit = (): void => {
    if (this.state.agreementFile && this.state.letterFile) {
      this.props.onSubmit({ agreement: this.state.agreementFile, letter: this.state.letterFile });
    }
  };

  onClose = (): void => {
    this.setState({ agreementFile: null, letterFile: null });
    this.props.onClose();
  };

  render() {
    const { isOpen } = this.props;

    return (
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={this.onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    Загрузка подписанных документов
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500 mb-5">
                      Выберите подписанные документы
                      <br />
                      (доступный формат: .doc, .docx, .pdf)
                    </p>
                    <AppFileInput
                      accept={`.doc, .docx, .pdf`}
                      onChange={this.handleAgreementFilesOnChange}
                      label="Договор"
                    />
                    <div className={`my-4`}></div>
                    <AppFileInput
                      accept={`.doc, .docx, .pdf`}
                      onChange={this.handleLetterFilesOnChange}
                      label="Письмо о пожертвовании"
                    />
                  </div>

                  <div className="mt-4 w-full flex flex-row flex-wrap justify-between">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-[#C9C8D6] px-4 py-2 text-sm font-medium text-[#8C89AC] focus:outline-none"
                      onClick={this.onClose}
                    >
                      Закрыть
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center bg-primary rounded-md px-4 py-2 text-sm font-medium text-white focus:outline-none"
                      onClick={this.handleSubmit}
                    >
                      Загрузить файлы
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  }
}

export default AppUploadsDialog;
