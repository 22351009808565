import React, { Component, Fragment } from 'react';
import { read, utils } from 'xlsx';
import { Dialog, Transition } from '@headlessui/react';
import AppFileInput from '../../shared/AppFileInput';
import { Organization } from '../../../types/Organization';
import dayjs from 'dayjs';

interface AppAgentDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (organization_data: Organization) => void;
}

interface XlsxAgentDataRow {
  'КАРТА ЮРИДИЧЕСКОГО ЛИЦА': string;
  __EMPTY: string;
  __EMPTY_1: string | number;
}

interface XlsxAgentData {
  [index: number]: XlsxAgentDataRow;
}

interface AppAgentDialogState {
  organization_data: Organization;
}

class AppAgentDialog extends Component<AppAgentDialogProps, AppAgentDialogState> {
  handleSubmit = (): void => {
    this.props.onSubmit(this.state.organization_data);
  };

  handleOnChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    try {
      const files = event.target.files;

      if (files) {
        // read first file
        const wb = read(await files[0].arrayBuffer());
        const data: XlsxAgentData = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]) || [];

        const organizationData: Organization = {
          legal_form: '',
          full_name: String(data[0]['__EMPTY_1']) || '',
          short_name: String(data[1]['__EMPTY_1']) || '',
          inn: String(data[25]['__EMPTY_1']) || '',
          kpp: String(data[26]['__EMPTY_1']) || '',
          ogrn: String(data[23]['__EMPTY_1']) || '',
          legal_address: Array.from({ length: 6 }, (_, i) => String(data[i + 3]['__EMPTY_1'])).join(
            ' ',
          ),
          actual_address: Array.from({ length: 6 }, (_, i) =>
            String(data[i + 10]['__EMPTY_1']),
          ).join(' '),
          authorized_person: String(data[36]['__EMPTY_1']) || '',
          document: String(data[37]['__EMPTY_1']) || '',
          registration_date: dayjs(data[24]['__EMPTY_1']).format('yyyy-MM-DD') || '',
          phone: String(data[40]['__EMPTY_1']) || '',
          email: String(data[39]['__EMPTY_1']) || '',
        };

        this.setState({ organization_data: organizationData });
      }
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    const { isOpen, onClose } = this.props;

    return (
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    Загрузка файла
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500 mb-5">
                      Выберите файл с данными организации
                    </p>
                    <AppFileInput onChange={this.handleOnChange} />
                  </div>

                  <div className="mt-10">
                    <div
                      className={`p-2 rounded-lg text-center w-2/3 mx-auto`}
                      style={{ background: 'rgba(15, 9, 79, 0.19)' }}
                    >
                      <a
                        href={process.env.PUBLIC_URL + `/Карта_агента_юр_лица.xlsx`}
                        target="_blank"
                        rel="noreferrer"
                        className={`uppercase cursor-pointer text-primary font-semibold`}
                      >
                        <i className="far fa-file-excel mr-2"></i>
                        Скачать пример файла
                      </a>
                    </div>
                  </div>

                  <div className="mt-10 w-full flex flex-row flex-wrap justify-between">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-[#C9C8D6] px-4 py-2 text-sm font-medium text-[#8C89AC] focus:outline-none"
                      onClick={onClose}
                    >
                      Закрыть
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center bg-primary rounded-md px-4 py-2 text-sm font-medium text-white focus:outline-none"
                      onClick={this.handleSubmit}
                    >
                      Загрузить файл с данными организации
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  }
}

export default AppAgentDialog;
