export const PARTY_INNS = [
  '7702330228',
  '2221049061',
  '2801082746',
  '2901104440',
  '3015058150',
  '3123079461',
  '3234045588',
  '3327703166',
  '3444099445',
  '3525118449',
  '3664048164',
  '9303032054',
  '7901022676',
  '7536090922',
  '9001010921',
  '3702021078',
  '3810043583',
  '0721000134',
  '3906096064',
  '4029026961',
  '4101113706',
  '0901051603',
  '4205031448',
  '4345039694',
  '4401027085',
  '2309078800',
  '2466101142',
  '4501096967',
  '4632022197',
  '7825473931',
  '4825031046',
  '9403025300',
  '4909082449',
  '7729416497',
  '7701297345',
  '5190106234',
  '2983000230',
  '5260105371',
  '5321086792',
  '5410142350',
  '5503066198',
  '5610071001',
  '5753030074',
  '5837017915',
  '5903010070',
  '2538071958',
  '6027070648',
  '0105036702',
  '0411105916',
  '0274077206',
  '0326009707',
  '0562052529',
  '0606009153',
  '0814148581',
  '1001044884',
  '1101478317',
  '9102000990',
  '1215079348',
  '1326184210',
  '1435128343',
  '1502025547',
  '1655048580',
  '1701033479',
  '1901054067',
  '6163063819',
  '6231051902',
  '6316076159',
  '7811122394',
  '6452077539',
  '6501116127',
  '6671117061',
  '9201000230',
  '6731039734',
  '2636038116',
  '6831023230',
  '6901004361',
  '7017048290',
  '7107065532',
  '7202106475',
  '1831082685',
  '7325035746',
  '2721094136',
  '8601017959',
  '9500006906',
  '7453084467',
  '2020003846',
  '2127319514',
  '8709009047',
  '8901012870',
  '7606042308',
];
