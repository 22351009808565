import { Component } from 'react';
import Dashboard from '../../../containers/admin/Dashboard';

class TransactionsMapPage extends Component<any, any> {
  render() {
    return (
      <Dashboard
        content={
          <div className={`account-view pt-10 pb-4 px-4 bg-slate-200 min-h-screen`}>
            <p className="text-center text-2xl font-semibold text-red-800 mb-4">
              Видео демонстрация дизайн-прототипа: требует согласования до разработки
            </p>
            <video src={process.env.PUBLIC_URL + `/tr_map.mp4`} autoPlay={true} controls={true} />
          </div>
        }
      />
    );
  }
}

export default TransactionsMapPage;
