import React, { Component } from 'react';
import { Organization, OrganizationRegistration } from '../../../types/Organization';
import AppInput from '../../shared/AppInput';
import { withRouter, WithRouterProps } from '../../../utils/withNavigation';

import { apiWrapper } from '../../../utils/api';
import AppLegalFormSelect from '../AppLegalFormSelect';
import { LEGAL_FORMS } from '../../../const/LegalForms';
import { validateInput } from '../../../utils/validations';
import { INN_MASK, KPP_MASK, OGRN_MASK, PHONE_MASK } from '../../../const/InputMasks';
import { INN_REGEX, KPP_REGEX, OGRN_REGEX, PHONE_REGEx } from '../../../const/RegexValidations';
import AppInnDialog from '../AppInnDialog';
import { getDataByInnSbis } from '../../../utils/getDataByInnEgrul';
import dayjs from 'dayjs';
import { findLegalForm } from '../../../utils/findLegalForm';
import AppTextarea from '../../shared/AppTextarea';

class AppCompanyRegistration extends Component<WithRouterProps, OrganizationRegistration> {
  constructor(props: {}) {
    super(props);
    this.state = {
      legal_form: LEGAL_FORMS[0].name,
      full_name: '',
      short_name: '',
      inn: '',
      kpp: '',
      ogrn: '',
      legal_address: '',
      actual_address: '',
      authorized_person: '',
      document: '',
      registration_date: '',
      phone: '',
      email: '',
      errors: {},
      password: '',
      showPassword: false,
      agreement: false,
      isInnModalShown: false,
      pd: false,
      user_full_name: '',
    };
  }

  handleInputChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ): void => {
    const { name, value } = event.target;
    this.setState({ [name]: value } as unknown as Pick<Organization, keyof Organization>);

    if (name === 'full_name') {
      const res = findLegalForm(value);
      if (res !== '') {
        this.setState((prevState: OrganizationRegistration) => ({
          ...prevState,
          legal_form: res,
        }));
      }
    }
  };

  handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    // @ts-ignore
    this.setState({ [name]: value });
  };

  handleTogglePassword = (): void => {
    this.setState((prevState: OrganizationRegistration) => ({
      ...prevState,
      showPassword: !prevState.showPassword,
    }));
  };

  handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    const {
      legal_form,
      full_name,
      short_name,
      inn,
      kpp,
      ogrn,
      legal_address,
      actual_address,
      authorized_person,
      document,
      registration_date,
      phone,
      email,
      password,
      user_full_name,
    } = this.state;

    let errors: { [key: string]: string } = {};
    const fields = {
      legal_form,
      full_name,
      short_name,
      inn,
      kpp,
      ogrn,
      legal_address,
      actual_address,
      authorized_person,
      document,
      registration_date,
      phone,
      email,
      password,
      user_full_name,
    };

    const regexps = {
      inn: INN_REGEX,
      kpp: KPP_REGEX,
      ogrn: OGRN_REGEX,
      phone: PHONE_REGEx,
    };

    for (let field in fields) {
      // @ts-ignore
      const error = validateInput(field, fields[field], regexps[field]);
      if (error) {
        errors[field] = error;
      }
    }

    this.setState({ errors });

    if (Object.keys(errors).length > 0) {
      return;
    }

    await apiWrapper
      .post('/users', {
        user: {
          email,
          password,
          phone,
          full_name: user_full_name,
          organization_attributes: {
            legal_form,
            full_name,
            short_name,
            inn,
            kpp,
            ogrn,
            legal_address,
            actual_address,
            authorized_person,
            document,
            registration_date,
          },
        },
      })
      .then(async () => {
        window.location.replace('/');
      })
      .catch((error) => {
        console.log('registration', error);
      });
  };

  handleOpenInnModal = (): void => {
    this.setState({ isInnModalShown: true });
  };

  handleCloseInnModal = (): void => {
    this.setState({ isInnModalShown: false });
  };

  findDataByInnSbis = async (searchInn: string): Promise<boolean> => {
    // let me = this;
    return new Promise(async (_resolve, _reject) => {
      const res = await getDataByInnSbis(searchInn);
      if (res) {
        const data = res[0];

        this.setState(
          (prevState) => {
            return {
              ...prevState,
              inn: data?.inn,
              kpp: data?.kpp,
              ogrn: data?.ogrn,
              full_name: data?.company_full_name,
              short_name: data?.company_short_name,
              legal_address: data?.address_legal,
              actual_address: data?.address_actual || data?.address_legal,
              authorized_person: `${data?.director_surname} ${data?.director_name} ${data?.director_patronymic}`,
              registration_date: dayjs(data?.registration_date).format('YYYY-MM-DD'),
            };
          },
          () => {
            const res = findLegalForm(this.state.full_name);
            if (res !== '') {
              this.setState((prevState: OrganizationRegistration) => ({
                ...prevState,
                legal_form: res,
              }));
            }
            _resolve(true);
          },
        );
      } else {
        _reject(false);
      }
    });
  };

  render() {
    const {
      legal_form,
      full_name,
      short_name = '',
      inn,
      kpp,
      ogrn,
      legal_address,
      actual_address,
      authorized_person,
      document,
      registration_date = '',
      phone = '',
      email = '',
      errors,
      password,
      showPassword,
      user_full_name = '',
    } = this.state;

    return (
      <div className={`w-full`}>
        <form className={`w-full flex flex-col gap-y-1.5`} onSubmit={this.handleSubmit}>
          <div className="flex flex-row items-center uppercase text-base text-blue-gray-400 w-full mb-5 font-semibold">
            <div>Заполните Данные</div>
            <button
              className={`ml-auto bg-[#B02A2A] text-white font-semibold text-base rounded-lg uppercase py-1 px-3 border-2 border-[#B02A2A]`}
              aria-label={`Автозаполнение по ИНН`}
              onClick={this.handleOpenInnModal}
              type={'button'}
            >
              <i className={`far fa-file-alt mr-3`}></i>
              Автозаполнение по ИНН
            </button>
          </div>
          <AppLegalFormSelect
            selectedForm={legal_form}
            onFormChange={this.handleInputChange}
            name="legal_form"
          />
          {errors.legalForm && <p className="text-red-500 text-sm">{errors.legal_form}</p>}
          <AppTextarea
            id="full_name"
            name="full_name"
            label="Полное наименование"
            value={full_name}
            onChange={this.handleInputChange}
          />
          {/*<AppInput*/}
          {/*  id="full_name"*/}
          {/*  name="full_name"*/}
          {/*  label="Полное наименование"*/}
          {/*  type="text"*/}
          {/*  value={full_name}*/}
          {/*  onChange={this.handleInputChange}*/}
          {/*/>*/}
          {errors.full_name && <p className="text-red-500 text-sm">{errors.full_name}</p>}
          <AppTextarea
            id="short_name"
            name="short_name"
            label="Краткое наименование"
            value={short_name}
            onChange={this.handleInputChange}
          />
          {/*<AppInput*/}
          {/*  id="short_name"*/}
          {/*  name="short_name"*/}
          {/*  label="Краткое наименование"*/}
          {/*  type="text"*/}
          {/*  value={short_name}*/}
          {/*  onChange={this.handleInputChange}*/}
          {/*/>*/}
          {errors.short_name && <p className="text-red-500 text-sm">{errors.short_name}</p>}
          <AppInput
            id="inn"
            name="inn"
            label="ИНН"
            type="text"
            value={inn}
            onChange={this.handleInputChange}
            mask={INN_MASK}
          />
          {errors.inn && <p className="text-red-500 text-sm">{errors.inn}</p>}

          <AppInput
            id="kpp"
            name="kpp"
            label="КПП"
            type="text"
            value={kpp}
            onChange={this.handleInputChange}
            mask={KPP_MASK}
          />
          {errors.kpp && <p className="text-red-500 text-sm">{errors.kpp}</p>}
          <AppInput
            id="ogrn"
            name="ogrn"
            label="ОГРН"
            type="text"
            value={ogrn}
            onChange={this.handleInputChange}
            mask={OGRN_MASK}
          />
          {errors.ogrn && <p className="text-red-500 text-sm">{errors.ogrn}</p>}
          <AppTextarea
            id="legal_address"
            name="legal_address"
            label="Юридический адрес"
            value={legal_address}
            onChange={this.handleInputChange}
          />
          {/*<AppInput*/}
          {/*  id="legal_address"*/}
          {/*  name="legal_address"*/}
          {/*  label="Юридический адрес"*/}
          {/*  type="text"*/}
          {/*  value={legal_address}*/}
          {/*  onChange={this.handleInputChange}*/}
          {/*/>*/}
          {errors.legal_address && <p className="text-red-500 text-sm">{errors.legal_address}</p>}
          <AppTextarea
            id="actual_address"
            name="actual_address"
            label="Фактический адрес"
            value={actual_address}
            onChange={this.handleInputChange}
          />
          {/*<AppInput*/}
          {/*  id="actual_address"*/}
          {/*  name="actual_address"*/}
          {/*  label="Фактический адрес"*/}
          {/*  type="text"*/}
          {/*  value={actual_address}*/}
          {/*  onChange={this.handleInputChange}*/}
          {/*/>*/}
          {errors.actual_address && <p className="text-red-500 text-sm">{errors.actual_address}</p>}
          <AppInput
            id="authorized_person"
            name="authorized_person"
            label="Руководитель / уполномоченное лицо"
            type="text"
            value={authorized_person}
            onChange={this.handleInputChange}
          />
          {errors.authorized_person && (
            <p className="text-red-500 text-sm">{errors.authorized_person}</p>
          )}
          <AppInput
            id="registration_date"
            name="registration_date"
            label="Дата регистрации"
            type="date"
            value={registration_date}
            onChange={this.handleInputChange}
          />
          <AppInput
            id="document"
            name="document"
            label="Документ на основании, которого действует Руководитель / уполномоченное лицо"
            type="text"
            value={document}
            onChange={this.handleInputChange}
          />
          {errors.document && <p className="text-red-500 text-sm">{errors.document}</p>}
          <div className="uppercase text-base text-blue-gray-400 w-full text-left my-5 font-semibold">
            КОНТАКТНЫЕ ДАННЫЕ
          </div>
          <AppInput
            id="user_full_name"
            name="user_full_name"
            label="ФИО"
            type="text"
            value={user_full_name}
            onChange={this.handleInputChange}
          />
          {errors.user_full_name && <p className="text-red-500 text-sm">{errors.user_full_name}</p>}
          <AppInput
            id="phone"
            name="phone"
            label="Телефон"
            type="text"
            value={phone}
            onChange={this.handleInputChange}
            mask={PHONE_MASK}
          />
          {errors.phone && <p className="text-red-500 text-sm">{errors.phone}</p>}
          <AppInput
            id="email"
            name="email"
            label="Электронная почта"
            type="email"
            value={email}
            onChange={this.handleInputChange}
          />
          {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
          <div className={`relative`}>
            <AppInput
              id="password"
              name="password"
              label="Пароль"
              value={password}
              type={showPassword ? 'text' : 'password'}
              onChange={this.handleInputChange}
            />

            <i
              onClick={this.handleTogglePassword}
              className={`
                  cursor-pointer
                  text-lg
                  text-black
                  absolute
                  right-8
                  top-4
                  ${showPassword ? 'far fa-eye' : 'far fa-eye-slash'}
              `}
            />
          </div>

          {errors.password && <p className="text-red-500 text-sm">{errors.password}</p>}
          <button
            type="submit"
            className="mt-5 text-white bg-primaryGradient rounded-lg px-5 py-4 w-full font-semibold text-xl shadow-md uppercase"
            disabled={!this.state.agreement || !this.state.pd}
          >
            пройти регистрацию
            <i className="fas fa-chevron-right ml-5"></i>
          </button>

          <div className="relative flex items-start mt-5">
            <div className="flex items-center">
              <input
                id="agreement"
                name="agreement"
                type="checkbox"
                className="focus:text-primary h-8 w-8 text-primary border-2 border-gray-300 rounded"
                onChange={this.handleCheckboxChange}
              />
            </div>
            <div className="ml-3 text-sm">
              <span
                id="comments-description"
                className="text-gray-950 uppercase font-semibold text-lg leading-tight"
              >
                Организация соответсвует всем условиям, указанным в Федеральном законе от 11.07.2001
                N 95-ФЗ (ред. от 30.04.2021) "О политических партиях"
              </span>
            </div>
          </div>
          <div className="relative flex items-center mt-2">
            <div className="flex items-center">
              <input
                id="pd"
                name="pd"
                type="checkbox"
                className="focus:text-primary h-8 w-8 text-primary border-2 border-gray-300 rounded"
                onChange={this.handleCheckboxChange}
              />
            </div>
            <div className="ml-3 text-sm">
              <span
                id="comments-description"
                className="text-gray-950 uppercase font-semibold text-lg leading-tight"
              >
                Даю согласие на обработку&nbsp;
                <a
                  href={process.env.PUBLIC_URL + `/personal-data-processing.pdf`}
                  className={`underline`}
                  target="_blank"
                  rel="noreferrer"
                >
                  персональных данных
                </a>
              </span>
            </div>
          </div>

          <AppInnDialog
            isOpen={this.state.isInnModalShown}
            onClose={this.handleCloseInnModal}
            onSubmit={(_inn) => {
              this.findDataByInnSbis(_inn)
                .then(() => {
                  this.setState({ isInnModalShown: false });
                })
                .catch(() => {
                  alert('Данные по указанному ИНН не обнаружены');
                });
            }}
          />
        </form>
      </div>
    );
  }
}

const AppCompanyRegistrationWithRouter = withRouter(AppCompanyRegistration);

export default AppCompanyRegistrationWithRouter;
