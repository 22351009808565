import React from 'react';

interface AppTableDocumentBtnProps {
  className?: string;
  onClick?: () => void;
  icon?: React.ReactNode;
  title: string;
  loading?: boolean;
}

const AppTableDocumentBtn: React.FC<AppTableDocumentBtnProps> = ({
  className = '',
  onClick,
  icon,
  title,
  loading = false,
}) => {
  return (
    <button
      className={`w-full text-center border-none flex flex-1 flex-col justify-start items-center self-stretch space-y-1 ${className}`}
      onClick={onClick}
      disabled={loading}
    >
      <div className={`text-center text-primary opacity-40`}>
        {loading ? (
          <i className="fas fa-spinner fa-spin"></i>
        ) : (
          <>
            {!icon && <i className="far fa-file"></i>}
            {icon}
          </>
        )}
      </div>
      <div
        className={`text-center text-primary text-[10px] leading-tight font-semibold max-w-[100px]`}
      >
        {loading ? 'Загрузка...' : title}
      </div>
    </button>
  );
};

export default AppTableDocumentBtn;
