export default function extractTags(_data: any[]): string[] {
  let detectedTags = [] as string[];

  for (let _transaction of _data) {
    if (_transaction.tag_cloud) {
      let tags = _transaction.tag_cloud.split(';');
      for (let _dT of tags) {
        if (_dT.length > 0) detectedTags.push(_dT);
      }
    }
  }

  detectedTags = detectedTags.filter((value, index, array) => array.indexOf(value) === index);
  return detectedTags;
}
