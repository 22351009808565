import React, { Component } from 'react';
import { UserRegistration } from '../../../types/UserRegistration';
import AppInput from '../../shared/AppInput';

class AppUserRegistration extends Component<any, UserRegistration> {
  constructor(props: {}) {
    super(props);
    this.state = {
      lastName: '',
      firstName: '',
      middleName: '',
      birthDate: '',
      inn: '',
      position: '',
      placeOfWork: '',
      registrationAddress: '',
      passportSeries: '',
      passportNumber: '',
      passportIssuer: '',
      passportDepartmentCode: '',
      phone: '',
      email: '',
      errors: {},
    };
  }

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    this.setState({ [name]: value } as unknown as Pick<UserRegistration, keyof UserRegistration>);
  };

  render() {
    const {
      lastName,
      firstName,
      middleName,
      birthDate,
      inn,
      position,
      placeOfWork,
      registrationAddress,
      passportSeries,
      passportNumber,
      passportIssuer,
      passportDepartmentCode,
      phone,
      email,
      errors,
    } = this.state;

    return (
      <div className={`w-full`}>
        <form className={`w-full flex flex-col gap-y-1.5`}>
          <div className="uppercase text-base text-blue-gray-400 w-full text-left mb-5 font-semibold">
            Заполните Данные
          </div>

          <AppInput
            id="lastName"
            name="lastName"
            label="фамилия"
            type="text"
            value={lastName}
            onChange={this.handleInputChange}
          />
          {errors.lastName && <p className="text-red-500 text-sm">{errors.lastName}</p>}
          <AppInput
            id="firstName"
            name="firstName"
            label="имя"
            type="text"
            value={firstName}
            onChange={this.handleInputChange}
          />
          {errors.firstName && <p className="text-red-500 text-sm">{errors.firstName}</p>}
          <AppInput
            id="middleName"
            name="middleName"
            label="отчество"
            type="text"
            value={middleName}
            onChange={this.handleInputChange}
          />
          {errors.middleName && <p className="text-red-500 text-sm">{errors.middleName}</p>}
          <AppInput
            id="birthDate"
            name="birthDate"
            label="Дата рождения"
            type="date"
            value={birthDate}
            onChange={this.handleInputChange}
          />
          {errors.birthDate && <p className="text-red-500 text-sm">{errors.birthDate}</p>}
          <AppInput
            id="inn"
            name="inn"
            label="ИНН"
            type="text"
            value={inn}
            onChange={this.handleInputChange}
            mask={'999999999999'}
          />
          {errors.inn && <p className="text-red-500 text-sm">{errors.inn}</p>}
          <AppInput
            id="position"
            name="position"
            label="должность"
            type="text"
            value={position}
            onChange={this.handleInputChange}
          />
          {errors.position && <p className="text-red-500 text-sm">{errors.position}</p>}
          <AppInput
            id="placeOfWork"
            name="placeOfWork"
            label="Место работы"
            type="text"
            value={placeOfWork}
            onChange={this.handleInputChange}
          />
          {errors.placeOfWork && <p className="text-red-500 text-sm">{errors.placeOfWork}</p>}
          <AppInput
            id="registrationAddress"
            name="registrationAddress"
            label="Адрес регистрации"
            type="text"
            value={registrationAddress}
            onChange={this.handleInputChange}
          />
          {errors.registrationAddress && (
            <p className="text-red-500 text-sm">{errors.registrationAddress}</p>
          )}

          <div className="uppercase text-base text-blue-gray-400 w-full text-left my-5 font-semibold">
            ПАСПОРТНЫЕ ДАННЫЕ
          </div>

          <AppInput
            id="passportSeries"
            name="passportSeries"
            label="СЕРИЯ"
            type="text"
            value={passportSeries}
            onChange={this.handleInputChange}
            mask={'99 99'}
          />
          {errors.passportSeries && <p className="text-red-500 text-sm">{errors.passportSeries}</p>}
          <AppInput
            id="passportNumber"
            name="passportNumber"
            label="НОМЕР"
            type="text"
            value={passportNumber}
            onChange={this.handleInputChange}
            mask={'999999'}
          />
          {errors.passportNumber && <p className="text-red-500 text-sm">{errors.passportNumber}</p>}
          <AppInput
            id="passportIssuer"
            name="passportIssuer"
            label="кем и когда выдан"
            type="text"
            value={passportIssuer}
            onChange={this.handleInputChange}
          />
          {errors.passportIssuer && <p className="text-red-500 text-sm">{errors.passportIssuer}</p>}
          <AppInput
            id="passportDepartmentCode"
            name="passportDepartmentCode"
            label="код подразделения"
            type="text"
            value={passportDepartmentCode}
            onChange={this.handleInputChange}
            mask={'999'}
          />
          {errors.passportDepartmentCode && (
            <p className="text-red-500 text-sm">{errors.passportDepartmentCode}</p>
          )}

          <div className="uppercase text-base text-blue-gray-400 w-full text-left my-5 font-semibold">
            КОНТАКТНЫЕ ДАННЫЕ
          </div>

          <AppInput
            id="phone"
            name="phone"
            label="телефон"
            type=""
            value={phone}
            onChange={this.handleInputChange}
            mask={'+7 (999) 999-99-99'}
          />
          {errors.phone && <p className="text-red-500 text-sm">{errors.phone}</p>}
          <AppInput
            id="email"
            name="email"
            label="электронная почта"
            type="email"
            value={email}
            onChange={this.handleInputChange}
          />
          {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}

          <button
            type="submit"
            className="mt-5 text-white bg-primaryGradient rounded-lg px-5 py-4 w-full font-semibold text-xl shadow-md uppercase"
          >
            пройти регистрацию
            <i className="fas fa-chevron-right ml-5"></i>
          </button>

          <div className="relative flex items-center mt-5">
            <div className="flex items-center">
              <input
                id="agreement"
                name="agreement"
                type="checkbox"
                className="focus:text-primary h-8 w-8 text-primary border-2 border-gray-300 rounded"
              />
            </div>
            <div className="ml-3">
              <span
                id="comments-description"
                className="text-gray-950 uppercase font-semibold text-lg leading-tight"
              >
                Даю согласие на обработку персональных данных
              </span>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default AppUserRegistration;
