import React, { Component, Fragment } from 'react';
import { Combobox, Transition } from '@headlessui/react';

interface State {
  selected: any;
  query: string;
}

interface Props {
  items: any[];
  searchKey: string;
  onUpdateValue?: (val: any) => void;
  placeholder?: string;
}

class AppCombobox extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selected: null,
      query: '',
    };
  }

  setSelected = (selected: any) => {
    this.setState({ selected });
    if (this.props.onUpdateValue) this.props.onUpdateValue(selected);
  };

  setQuery = (query: string) => {
    this.setState({ query });
  };

  render() {
    const filteredItems =
      this.state.query === ''
        ? this.props.items
        : this.props.items.filter((item) =>
            item[this.props.searchKey]
              .toLowerCase()
              .replace(/\s+/g, '')
              .includes(this.state.query.toLowerCase().replace(/\s+/g, '')),
          );

    return (
      <div>
        <Combobox value={this.state.selected} onChange={this.setSelected}>
          <div className="relative">
            <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 border border-gray-300 sm:text-sm">
              <Combobox.Button className={`w-full`}>
                <Combobox.Input
                  className="h-[60px] w-full border-none pl-3 pr-10 text-lg leading-5 text-gray-900 focus:ring-0"
                  displayValue={(item: any) => {
                    return item ? item[this.props.searchKey] : '';
                  }}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    this.setQuery(event.target.value)
                  }
                  placeholder={this.props.placeholder}
                />
              </Combobox.Button>
              {/*<Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">*/}
              {/*  <i className="far fa-chevron-down w-5 text-gray-600"></i>*/}
              {/*</Combobox.Button>*/}
            </div>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => this.setQuery('')}
            >
              <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {filteredItems.length === 0 && this.state.query !== '' ? (
                  <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                    Ничего не найдено
                  </div>
                ) : (
                  filteredItems.map((item) => (
                    <Combobox.Option
                      key={item.id}
                      className={({ active }: { active: boolean }) =>
                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                          active ? 'bg-primary text-white' : 'text-black'
                        }`
                      }
                      value={item}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}
                          >
                            {item[this.props.searchKey]}
                          </span>
                          {selected ? (
                            <span
                              className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                active ? 'text-white' : 'text-primary'
                              }`}
                            >
                              <i className="far fa-check w-5"></i>
                            </span>
                          ) : null}
                        </>
                      )}
                    </Combobox.Option>
                  ))
                )}
              </Combobox.Options>
            </Transition>
          </div>
        </Combobox>
      </div>
    );
  }
}

export default AppCombobox;
