import { Component } from 'react';

class AppSignOutBtn extends Component<any, any> {
  handleSignOutBtnClick() {
    localStorage.removeItem('auth_token');
    window.location.assign('/login');
  }

  render() {
    return (
      <button
        data-testid={`signout-btn`}
        type={'button'}
        className={`text-center text-white bg-transparent border border-white rounded-full py-1 px-4 text-sm uppercase font-semibold`}
        onClick={() => this.handleSignOutBtnClick()}
      >
        Выход
      </button>
    );
  }
}

export default AppSignOutBtn;
