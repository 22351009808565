import { Component } from 'react';
import { Tab } from '@headlessui/react';
import { AppTab } from '../../../types/Tab';
import { classNames } from '../../../utils/classNames';

interface Props {
  tabListClassName?: string;
  tabs?: AppTab[];
}

class AppTabGroup extends Component<Props, any> {
  render() {
    const { tabListClassName, tabs } = this.props;

    return (
      <Tab.Group>
        <Tab.List className={`flex space-x-2 font-semibold ${tabListClassName}`}>
          {tabs &&
            tabs.map((tab: AppTab) => (
              <Tab
                key={tab.title}
                className={({ selected }) =>
                  classNames(
                    'w-full text-zinc-800 py-1 uppercase rounded-t-lg border border-slate-300 focus:outline-none text-xs',
                    selected ? 'bg-white' : 'bg-gray-100',
                    `${tab.className}`,
                  )
                }
                onClick={tab.onClick}
              >
                {tab.title}
              </Tab>
            ))}
        </Tab.List>
        <Tab.Panels>
          {tabs &&
            tabs.map((tab: AppTab) => (
              <Tab.Panel key={`${tab.title}-panel`}>{tab.content}</Tab.Panel>
            ))}
        </Tab.Panels>
      </Tab.Group>
    );
  }
}

export default AppTabGroup;
