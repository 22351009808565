import React, { Component } from 'react';
import AuthContainer from '../../../containers/shared/Auth';
import AppBackBtn from '../../../components/shared/AppBackBtn';
import AppRegistrationSwitch from '../../../components/shared/AppRegistrationSwitch/inedx';
import { RegistrationType, RegistrationTypeState } from '../../../types/RegistrationType';
import AppCompanyRegistration from '../../../components/company/AppCompanyRegistration';
import AppUserRegistration from '../../../components/user/AppUserRegistration';

class CompanyRegistrationPage extends Component<any, RegistrationTypeState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      registrationType: RegistrationType.NONE,
    };

    this.handleRegistrationTypeChange = this.handleRegistrationTypeChange.bind(this);
    this.resetRegistrationType = this.resetRegistrationType.bind(this);
  }

  handleRegistrationTypeChange(registrationType: RegistrationType) {
    this.setState({ registrationType });
  }

  resetRegistrationType() {
    this.setState({ registrationType: RegistrationType.NONE });
  }

  render() {
    const { registrationType } = this.state;

    let form;
    if (registrationType === RegistrationType.COMPANY) {
      form = <AppCompanyRegistration />;
    } else if (registrationType === RegistrationType.USER) {
      form = <AppUserRegistration />;
    }
    return (
      <AuthContainer
        content={
          <div className={`flex flex-col w-full items-center`}>
            <div className={`text-center font-semibold text-xl uppercase`}>Регистрация</div>

            <div
              className={`
                mt-7
                bg-container
                rounded-2xl
                p-5
                w-96
                xs:w-96
                sm:w-96
                md:w-1/2
                lg:w-1/2
                xl:w-1/2
                flex
                flex-col
                justify-center 
                items-center   
                gap-y-5
            `}
            >
              {registrationType === RegistrationType.NONE && (
                  <AppBackBtn className={`w-full`} />
                ) && (
                  <div className="uppercase text-base text-blue-gray-400 w-full text-left mb-5 font-semibold">
                    Выберите
                  </div>
                )}

              {registrationType === RegistrationType.NONE && (
                <AppRegistrationSwitch
                  onRegistrationTypeChange={this.handleRegistrationTypeChange}
                />
              )}

              {registrationType !== RegistrationType.NONE && (
                <div className={`flex flex-row w-full gap-2`}>
                  <div>
                    <button
                      data-testid="back-btn"
                      className={`
                        bg-transparent
                        text-blue-gray-400
                        text-sm
                        py-3
                        px-5
                        text-center
                        rounded-full
                        border-blue-gray-400
                        border-2
                        font-semibold
                      `}
                      onClick={this.resetRegistrationType}
                    >
                      <i className="fas fa-chevron-left mr-3"></i>
                      Назад
                    </button>
                  </div>
                  <div
                    className={`
                        bg-transparent
                        text-primary
                        text-sm
                        py-3
                        px-5
                        text-center
                        rounded-full
                        border-blue-gray-400
                        border-2
                        font-semibold
                        grow 
                        uppercase`}
                  >
                    {registrationType === RegistrationType.COMPANY
                      ? 'юридическое лицо'
                      : 'физическое лицо'}
                  </div>
                </div>
              )}

              {form}
            </div>
          </div>
        }
      />
    );
  }
}

export default CompanyRegistrationPage;
