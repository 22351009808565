import { Component, Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { notificationsState } from '../../../store/notifications';
import { apiWrapper } from '../../../utils/api';

class AppNotifications extends Component<any, any> {
  constructor(_props: any) {
    super(_props);

    this.state = {
      notifications: [],
    };

    this.getData = this.getData.bind(this);
  }

  handleBtnClick() {
    window.location.replace(`/admin/summary_registry`);
  }

  componentDidMount() {
    let me = this;
    me.getData();
  }

  getData() {
    let me = this;

    apiWrapper.get(`/users/notifications`).then((responseData: any) => {
      me.setState({
        notifications: responseData.notifications,
      });
    });
  }

  render() {
    const { unread } = notificationsState;
    let me = this;

    return (
      <div className={`w-fit`}>
        <div
          className={`py-1 px-3 text-sm rounded-xl h-full grid items-center bg-white text-primary relative`}
        >
          {unread && (
            <span className="bg-green-600 rounded-full h-4 w-4 absolute -top-1 -right-1"></span>
          )}

          <Popover className="relative">
            {() => (
              <>
                <Popover.Button className={`border-none bg-transparent w-full h-full`}>
                  <i className="far fa-bell text-lg"></i>
                </Popover.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel
                    className="fixed z-50 mt-3 -translate-x-full transform px-4 sm:px-0"
                    style={{
                      width: '500px',
                      maxHeight: '600px',
                      overflowY: 'scroll',
                      zIndex: 10,
                      position: 'absolute',
                    }}
                  >
                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 relative">
                      <div className="bg-slate-200 py-4 px-5">
                        <div className={`text-zinc-800 uppercase text-base font-bold mb-2`}>
                          Уведомления
                        </div>
                        <div className={`flex flex-col space-y-1`}>
                          {me.state.notifications.map((item: any, _itemIndex: number) => (
                            <div
                              className={`bg-white rounded-lg p-4 grid grid-cols-12 gap-x-2`}
                              key={`notification-${_itemIndex}`}
                            >
                              <div className={`col-span-11`}>
                                <div
                                  className={`text-gray-500 font-semibold mb-1`}
                                  style={{ fontSize: '9px', lineHeight: '11px' }}
                                >
                                  {item.date}
                                </div>
                                <div
                                  className={`text-base text-zinc-800 font-semibold leading-tight mb-3`}
                                >
                                  {item.content}
                                </div>
                                <div className={`text-base text-gray-500 font-semibold`}>
                                  {item.title}
                                </div>
                              </div>
                              <div
                                className="grid col-span-1 bg-primaryGradient rounded-lg cursor-pointer text-center items-center"
                                onClick={() => this.handleBtnClick()}
                              >
                                <i className={`far fa-chevron-right text-white`}></i>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>
        </div>
      </div>
    );
  }
}

export default AppNotifications;
