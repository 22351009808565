import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';

interface AccountModalDialogViewProps {
  accountTransaction: any;
  open: boolean;
  onClose: () => void;
}

export default class AccountModalDialogView extends React.Component<
  AccountModalDialogViewProps,
  any
> {
  constructor(_props: AccountModalDialogViewProps) {
    super(_props);

    this.onClose = this.onClose.bind(this);
  }

  onClose() {
    this.props.onClose();
  }

  render() {
    let me = this;

    let fields = [
      {
        title: 'Сумма',
        var: 'amount',
        abs: true,
        needs_localization: true,
        is_money: 'Руб',
      },
      {
        title: 'Назначение',
        var: 'payment_purpose',
      },
      {
        title: 'Дата',
        var: 'date_str',
      },
      // {
      //   title: "Счет",
      //   var: "account"
      // }
    ];

    let payerFields = [
      {
        title: 'ИНН или КИО',
        var: 'payer_inn',
      },
      {
        title: 'Банк',
        var: 'payer_bank_name',
        remove_commas: true,
      },
      {
        title: 'БИК Банка',
        var: 'payer_bank_bic',
      },
      {
        title: 'Счет',
        var: 'payer_account',
      },
    ];

    let payeeFields = [
      {
        title: 'ИНН или КИО',
        var: 'payee_inn',
      },
      {
        title: 'Банк',
        var: 'payee_bank_name',
      },
      {
        title: 'БИК Банка',
        var: 'payee_bank_bic',
      },
      {
        title: 'Счет',
        var: 'payee_account',
      },
    ];

    return (
      <Transition appear show={me.props.open && !!me.props.accountTransaction} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={me.onClose}>
          <div className="fixed inset-0 bg-black bg-opacity-25" onClick={me.onClose} />
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Dialog.Panel className="w-full max-w-screen-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h3" className="text-[28px] font-semibold leading-6 text-gray-900">
                  Транзакция
                </Dialog.Title>
                <div className="mt-10">
                  <table style={{ width: '100%' }} className={'w-100'}>
                    {me.props.accountTransaction &&
                      fields.map((_x: any, _xIndex: number) => {
                        return (
                          <tr>
                            <td className={'font-mediu font-mono'}>{_x.title}</td>
                            <td>
                              <span className={'flex m-4'}></span>
                            </td>
                            <td
                              style={{
                                width: '100%',
                                color:
                                  _x.var === 'amount'
                                    ? me.props.accountTransaction.amount < 0
                                      ? '#B02A2A'
                                      : '#007A22'
                                    : undefined,
                              }}
                            >
                              {_x.needs_localization
                                ? (_x.abs
                                    ? Math.abs(me.props.accountTransaction[_x.var])
                                    : me.props.accountTransaction[_x.var]
                                  ).toLocaleString()
                                : me.props.accountTransaction[_x.var]}
                              &nbsp;{_x.is_money}
                            </td>
                          </tr>
                        );
                      })}
                    <tbody>
                      <tr>
                        <td colSpan={3}>
                          <br />
                          <hr />
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td
                          valign={'top'}
                          style={{ fontWeight: me.props.accountTransaction.amount < 0 ? 900 : 600 }}
                        >
                          Плательщик
                        </td>
                        <td />
                        <td>
                          <table>
                            {me.props.accountTransaction &&
                              payerFields.map((_x: any, _xIndex: number) => {
                                return (
                                  <tr>
                                    <td
                                      className={
                                        'font-medium font-mono whitespace-nowrap opacity-60'
                                      }
                                    >
                                      {_x.title}
                                    </td>
                                    <td>
                                      <span className={'flex m-2'}></span>
                                    </td>
                                    <td style={{ width: '100%' }}>
                                      {_x.needs_localization
                                        ? me.props.accountTransaction[_x.var].toLocaleString()
                                        : me.props.accountTransaction[_x.var]}
                                    </td>
                                  </tr>
                                );
                              })}
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={3}>
                          <br />
                          <hr />
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td
                          valign={'top'}
                          style={{ fontWeight: me.props.accountTransaction.amount > 0 ? 900 : 600 }}
                        >
                          Получатель
                        </td>
                        <td />
                        <td>
                          <table>
                            {me.props.accountTransaction &&
                              payeeFields.map((_x: any, _xIndex: number) => {
                                return (
                                  <tr>
                                    <td
                                      className={
                                        'font-medium font-mono whitespace-nowrap opacity-60'
                                      }
                                    >
                                      {_x.title}
                                    </td>
                                    <td>
                                      <span className={'flex m-2'}></span>
                                    </td>
                                    <td style={{ width: '100%' }}>
                                      {_x.needs_localization
                                        ? me.props.accountTransaction[_x.var].toLocaleString()
                                        : me.props.accountTransaction[_x.var]}
                                    </td>
                                  </tr>
                                );
                              })}
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  {/*{JSON.stringify(me.props.accountTransaction)}*/}
                </div>
              </Dialog.Panel>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  }
}
