import { Component } from 'react';

interface Props {
  iconClass?: string;
  btnText?: string;
  disabled?: boolean;
  onClick?: () => void;
  loading?: boolean;
}

class AppDownloadPrimaryBtn extends Component<Props, any> {
  render() {
    const { iconClass, btnText, disabled, onClick, loading = false } = this.props;
    const faClass = iconClass ? iconClass : 'fas fa-arrow-to-bottom';
    const caption = btnText ? btnText : 'Скачать';

    return (
      <button
        className={`w-full bg-primaryGradient text-white flex items-center justify-center rounded-lg py-3 uppercase text-xs px-2 h-auto min-h-[40px]`}
        disabled={disabled || loading}
        onClick={onClick}
        data-testid={`download-btn`}
      >
        {!loading && <i className={faClass}></i>}
        {loading && <i className={`fas fa-spinner fa-spin`}></i>}
        <span className={`ml-1.5 font-semibold text-[12px]`}>{caption}</span>
      </button>
    );
  }
}

export default AppDownloadPrimaryBtn;
