import React, { Component } from 'react';
import styles from '../AppInput/AppInput.module.scss';

interface AppTextareaProps {
  id: string;
  label: string;
  name: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  readonly?: boolean;
}

interface AppTextareaState {
  isFocused: boolean;
}

class AppTextarea extends Component<AppTextareaProps, AppTextareaState> {
  private textAreaRef: React.RefObject<HTMLTextAreaElement>;

  constructor(props: AppTextareaProps) {
    super(props);
    this.state = {
      isFocused: false,
    };

    // Create a ref to the textarea
    this.textAreaRef = React.createRef();
  }

  componentDidUpdate(prevProps: AppTextareaProps) {
    if (prevProps.value !== this.props.value) {
      this.resizeTextarea();
    }
  }

  onFocus = () => {
    this.setState({ isFocused: true });
  };

  onBlur = () => {
    this.setState({ isFocused: false });
  };

  resizeTextarea = () => {
    if (this.textAreaRef.current) {
      this.textAreaRef.current.style.height = 'auto'; // Reset the height
      this.textAreaRef.current.style.height = this.textAreaRef.current.scrollHeight + 'px'; // Set the height based on content
    }
  };

  render() {
    const { id, label, value, name, onChange, readonly } = this.props;
    const { isFocused } = this.state;

    let active = isFocused || value?.length > 0;

    return (
      <div className="relative w-full overflow-hidden">
        <textarea
          ref={this.textAreaRef} // Attach the ref here
          id={id}
          name={name}
          value={value}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onChange={onChange}
          className={`block text-black w-full px-4 pt-3 pb-2 border border-gray-300 
            focus:outline-none focus:border-primary rounded-lg bg-white
            text-lg font-semibold focus:ring-0
            ${styles.minHeight60}
            ${active ? '' : ''}`}
          readOnly={readonly}
          style={{ resize: 'none', overflow: 'hidden' }} // Added styles to prevent manual resizing and hide overflow
        ></textarea>
        <label
          htmlFor={id}
          className={`bg-white w-auto text-ellipsis overflow-hidden whitespace-nowrap absolute left-0 px-1 mx-3 my-1 text-label transition-all duration-200 uppercase font-semibold ${
            active ? 'top-0 text-xs' : 'top-1/4 text-base'
          }`}
        >
          {label}
        </label>
      </div>
    );
  }
}

export default AppTextarea;
