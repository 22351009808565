import React, { Fragment, useEffect, useState } from 'react';
import Dashboard from '../../../containers/admin/Dashboard';
import { apiWrapper } from '../../../utils/api';
import { SberTransaction } from '../../../types/SberTransaction';
import { Transition } from '@headlessui/react';
import AppNumberWithSpaces from '../../../components/shared/AppNumberWithSpaces';
import { PARTY_ACCOUNTS } from '../../../const/PartyAccounts';
import { PARTY_INNS } from '../../../const/PartyInns';
import { toast } from 'react-toastify';

const LimitPayeePage = () => {
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  // eslint-disable-next-line
  const [loading, setLoading] = useState<boolean>(false);
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [filteredPayers, setFilteredPayers] = useState<any[]>([]);
  const [filteredPayees, setFilteredPayees] = useState<any[]>([]);
  const [uniquePayers, setUniquePayers] = useState<any[]>([]);
  const [uniquePayees, setUniquePayees] = useState<any[]>([]);
  const [selectedType, setSelectedType] = useState<string>('all'); // Type filter state
  // eslint-disable-next-line
  const [donations, setDonations] = useState<SberTransaction[]>([]);
  const [selectedEntity, setSelectedEntity] = useState<any | null>(null);
  const [isPayeeView, setIsPayeeView] = useState<boolean>(false); // Toggle for payer/payee view

  useEffect(() => {
    fetchUniquePayers(year).then(() => {});
    fetchUniquePayees(year).then(() => {});
  }, [year]);

  useEffect(() => {
    applyTypeFilter();
    // eslint-disable-next-line
  }, [uniquePayers, uniquePayees, selectedType]);

  const applyTypeFilter = () => {
    const filterByType = (entity: any) => {
      if (selectedType === 'fund') return entity.is_party_fund;
      if (selectedType === 'branch') return entity.is_party_branch;
      if (selectedType === 'federal') return entity.is_federal_fund;
      if (selectedType === 'other')
        return !entity.is_party_fund && !entity.is_party_branch && !entity.is_federal_fund;
      return true; // Show all if 'all' is selected
    };

    if (isPayeeView) {
      setFilteredPayees(uniquePayees.filter(filterByType));
    } else {
      setFilteredPayers(uniquePayers.filter(filterByType));
    }
  };

  const fetchUniquePayers = async (year: number) => {
    setLoading(true);
    try {
      const response = await apiWrapper.get(`/sber_transactions/unique_payers?year=${year}`);
      // @ts-ignore
      setUniquePayers(response);
      // @ts-ignore
      setFilteredPayers(response);
    } catch (error) {
      console.error('Failed to fetch unique payers:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUniquePayees = async (year: number) => {
    setLoading(true);
    try {
      const response = await apiWrapper.get(`/sber_transactions/unique_payees?year=${year}`);
      // @ts-ignore
      setUniquePayees(response);
      // @ts-ignore
      setFilteredPayees(response);
    } catch (error) {
      console.error('Failed to fetch unique payees:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDonationsByPayer = async (
    payerInn: string | null = null,
    payerAccount: string | null = null,
  ) => {
    setLoading(true);
    try {
      const queryParams = new URLSearchParams({ year: year.toString() });

      if (payerInn) {
        queryParams.append('inn', payerInn);
      }
      if (payerAccount) {
        queryParams.append('payer_account', payerAccount);
      }

      const query = `/sber_transactions/donations_by_payer?${queryParams.toString()}`;
      const response = await apiWrapper.get(query);
      // @ts-ignore
      setDonations(response);
    } catch (error) {
      console.error('Failed to fetch donations by payer:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDonationsByPayee = async (
    payeeInn: string | null = null,
    payeeAccount: string | null = null,
  ) => {
    setLoading(true);
    try {
      const queryParams = new URLSearchParams({ year: year.toString() });

      if (payeeInn) {
        queryParams.append('inn', payeeInn);
      }
      if (payeeAccount) {
        queryParams.append('payee_account', payeeAccount);
      }

      const query = `/sber_transactions/donations_by_payee?${queryParams.toString()}`;
      const response = await apiWrapper.get(query);
      // @ts-ignore
      setDonations(response);
    } catch (error) {
      console.error('Failed to fetch donations by payee:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dashboard
      content={
        <div className={`pt-10 pb-4 px-4 bg-slate-200 overflow-y-scroll`}>
          <div
            className="grid grid-cols-12 gap-2 overflow-y-scroll"
            style={{ height: 'calc(100vh - 180px)' }}
          >
            <div className="col-span-3 flex flex-col" style={{ height: 'calc(100vh - 180px)' }}>
              {/* Sticky header and filters */}
              <div className="sticky top-0 z-10 bg-white">
                <div className="w-full text-white text-sm text-center p-2 font-semibold bg-primaryGradient rounded-t-lg uppercase">
                  Последние {isPayeeView ? 'получатели' : 'жертвователи'}
                </div>

                <div className="p-2">
                  <div className="mb-4">
                    <select
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
                      value={year}
                      onChange={(e) => setYear(Number(e.target.value))}
                    >
                      {Array.from(
                        { length: new Date().getFullYear() - 2023 + 1 },
                        (_, i) => 2023 + i,
                      ).map((_, i) => {
                        const optionYear = new Date().getFullYear() - i;
                        return (
                          <option key={optionYear} value={optionYear}>
                            {optionYear}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="mb-4">
                    <select
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
                      value={selectedType}
                      onChange={(e) => setSelectedType(e.target.value)}
                    >
                      <option value="all">Все типы организаций</option>
                      <option value="federal">Федеральные фонды</option>
                      <option value="fund">
                        Фонды поддержки регионального сотрудничества и развития субъектов РФ
                      </option>
                      <option value="branch">Региональные отделения Партии</option>
                      <option value="other">Прочие организации</option>
                    </select>
                  </div>

                  <div className="mb-4">
                    <input
                      type="text"
                      placeholder="Поиск по названию или ИНН"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
                      value={searchKeyword}
                      onChange={(e) => {
                        const keyword = e.target.value.toLowerCase();
                        setSearchKeyword(e.target.value);

                        if (keyword === '') {
                          applyTypeFilter(); // Reset filters
                        } else {
                          if (isPayeeView) {
                            const filtered = uniquePayees.filter(
                              (payee) =>
                                payee.payee_name.toLowerCase().includes(keyword) ||
                                payee.payee_inn.includes(keyword),
                            );
                            setFilteredPayees(filtered);
                          } else {
                            const filtered = uniquePayers.filter(
                              (payer) =>
                                payer.payer_name.toLowerCase().includes(keyword) ||
                                payer.payer_inn.includes(keyword),
                            );
                            setFilteredPayers(filtered);
                          }
                        }
                      }}
                    />
                    <button
                      className="mt-2 px-4 py-2 bg-gray-300 text-black rounded-lg text-sm hover:bg-gray-400 w-full"
                      onClick={() => {
                        setSearchKeyword('');
                        applyTypeFilter(); // Reset filtered data
                      }}
                    >
                      Очистить фильтр
                    </button>
                  </div>

                  <button
                    className="px-4 py-2 bg-primary w-full text-white rounded-lg text-sm"
                    onClick={() => setIsPayeeView(!isPayeeView)}
                  >
                    {isPayeeView ? 'Отобразить по жертвователям' : 'Отобразить по получателям'}
                    <i className="fas fa-sliders-h ml-2"></i>
                  </button>
                </div>
              </div>

              {/* Scrollable list */}
              <div className="overflow-y-auto flex-grow bg-white">
                {(isPayeeView ? filteredPayees : filteredPayers).length > 0 ? (
                  (isPayeeView ? filteredPayees : filteredPayers).map((entity, index) => (
                    <div
                      key={index}
                      className={`py-2 px-4 border-b cursor-pointer ${
                        (!isPayeeView && selectedEntity?.payer_inn === entity.payer_inn) ||
                        (isPayeeView && selectedEntity?.payee_inn === entity.payee_inn)
                          ? 'bg-[#EDEEF5] font-semibold'
                          : 'hover:bg-[#EDEEF5]'
                      }`}
                      onClick={() => {
                        if (!isPayeeView && entity.total_amount > 43300000) {
                          toast.error('Лимит превышен для данного жертвователя!', {
                            position: toast.POSITION.BOTTOM_CENTER,
                            autoClose: 3000,
                          });
                        }

                        setSelectedEntity(entity);
                        isPayeeView
                          ? fetchDonationsByPayee(entity.payee_inn, entity?.payee_account)
                          : fetchDonationsByPayer(entity.payer_inn, entity?.payer_account);
                      }}
                    >
                      <div className="font-bold">
                        {entity.payer_name || entity.payee_name || 'Неизвестно'}
                      </div>
                      <div className="text-sm text-gray-500">
                        ИНН: {entity.payer_inn || entity.payee_inn || 'Неизвестно'}
                      </div>
                      <div className="text-sm text-[#2C2C2C]">
                        {isPayeeView && (
                          <>
                            Общая сумма поступлений:
                            <br />
                            <span className="text-lg font-semibold">
                              {entity.total_amount.toLocaleString()} ₽
                            </span>
                          </>
                        )}
                        {!isPayeeView && (
                          <>
                            Общая сумма пожертвований:
                            <br />
                            <span className="text-lg font-semibold">
                              {entity.total_amount.toLocaleString()} ₽
                            </span>
                            <br />
                            <div
                              className={
                                entity.total_amount <= 43300000 ? 'text-green-600' : 'text-red-800'
                              }
                            >
                              <div className="flex flex-row items-center font-semibold gap-x-2">
                                {entity.total_amount <= 43300000 ? (
                                  <>
                                    <i className="fas fa-check text-green-600"></i>
                                    <div>В РАМКАХ ЛИМИТОВ</div>
                                  </>
                                ) : (
                                  <>
                                    <i className="fas fa-times text-red-800"></i>
                                    <div>ЛИМИТ ПРЕВЫШЕН</div>
                                  </>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-center text-gray-500">Нет данных</div>
                )}
              </div>
            </div>

            <div className="col-span-9">
              <Transition
                as={Fragment}
                show={!loading}
                enter="transform transition duration-[400ms]"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transform duration-200 transition ease-in-out"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className={`h-full`}>
                  <div
                    className={`w-full text-white text-sm text-center p-2 font-semibold bg-primaryGradient rounded-t-lg uppercase`}
                  >
                    {selectedEntity ? (
                      <>
                        {selectedEntity.payer_name || selectedEntity.payee_name} (ИНН:{' '}
                        {selectedEntity.payer_inn || selectedEntity.payee_inn})
                      </>
                    ) : (
                      'Выберите жертвователя или получателя'
                    )}
                  </div>
                  <div className={`bg-white h-full p-4`}>
                    {donations.length > 0 ? (
                      <div className="overflow-auto h-full">
                        {!isPayeeView && (
                          <div className={`w-full border-2 border-[#C9C8D6] rounded-xl py-2 px-4`}>
                            <div
                              className={`h-8 w-full bg-primary text-white font-semibold text-center text-sm py-1 flex flex-row items-center justify-center mb-2`}
                            >
                              <div>Общий лимит пожертвований</div>
                              <div className={`ml-2 bg-white text-primary rounded-full px-3 py-1`}>
                                <AppNumberWithSpaces value={43_300_000} />
                                &nbsp;₽
                              </div>
                            </div>
                            <div
                              className={`w-full flex flex-row flex-nowrap items-center space-x-1`}
                            >
                              <div
                                className={`h-8 text-white font-semibold text-center text-xs py-1 flex flex-row items-center justify-center`}
                                style={{
                                  width: `${(selectedEntity.total_amount * 100) / 43_300_000}%`,
                                  minWidth: '50%',
                                  background: `${
                                    selectedEntity.total_amount > 43_300_000 ? '#B02A2A' : '#00529C'
                                  }`,
                                }}
                              >
                                <div>Сумма пожертвований за {year} год в адрес РО и ЦИК Партии</div>
                                <div
                                  className={`ml-2 bg-white text-[#00529C] rounded-full px-3 py-1`}
                                >
                                  <AppNumberWithSpaces value={selectedEntity.total_amount} />
                                  &nbsp;₽
                                </div>
                              </div>
                              <div
                                className={`h-8 bg-[#E8ECF4] flex-1`}
                                style={{
                                  display: `${
                                    selectedEntity.total_amount >= 43_300_000 ? 'none' : 'flex'
                                  }`,
                                }}
                              ></div>
                            </div>
                          </div>
                        )}
                        <div
                          className="overflow-auto h-full"
                          style={{ maxHeight: 'calc(100vh - 200px)' }}
                        >
                          <table className="mt-4 min-w-full table-auto border-collapse">
                            <thead className="bg-gray-100 sticky top-0 z-10">
                              <tr className="text-center">
                                <th className="px-4 py-2 border text-sm font-semibold">№</th>
                                <th className="px-4 py-2 border text-sm font-semibold">
                                  ИНН жертвователя
                                </th>
                                <th className="px-4 py-2 border text-sm font-semibold">
                                  ИНН получателя
                                </th>
                                <th className="px-4 py-2 border text-sm font-semibold">
                                  Жертвователь
                                </th>
                                <th className="px-4 py-2 border text-sm font-semibold">
                                  Получатель
                                </th>
                                <th className="px-4 py-2 border text-sm font-semibold">
                                  Сумма, руб.
                                </th>
                                <th className="px-4 py-2 border text-sm font-semibold">
                                  Назначение платежа
                                </th>
                                <th className="px-4 py-2 border text-sm font-semibold">
                                  Дата исполнения
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {donations.map((donation: SberTransaction, index) => {
                                const isPartyAccount = isPayeeView
                                  ? PARTY_ACCOUNTS.includes(String(donation?.payer_account)) ||
                                    PARTY_INNS.includes(String(donation?.payer_inn))
                                  : PARTY_ACCOUNTS.includes(String(donation?.payee_account)) ||
                                    PARTY_INNS.includes(String(donation?.payee_inn));

                                return (
                                  <tr
                                    key={index}
                                    className={`${
                                      isPartyAccount
                                        ? 'bg-[#E8ECF4]'
                                        : index % 2 === 0
                                        ? 'bg-gray-50'
                                        : ''
                                    } hover:bg-gray-100 text-center`}
                                  >
                                    <td className="px-4 py-2 border">{index + 1}</td>
                                    <td className="px-4 py-2 border text-left">
                                      {donation.payer_inn}
                                    </td>
                                    <td className="px-4 py-2 border text-left">
                                      {donation.payee_inn}
                                    </td>
                                    <td className="px-4 py-2 border text-left">
                                      {donation.payer_name}
                                    </td>
                                    <td className="px-4 py-2 border text-left">
                                      {donation.payee_name}
                                    </td>
                                    <td className="px-4 py-2 border text-right">
                                      <span
                                        className={
                                          isPayeeView && donation.amount > 43_300_000
                                            ? 'text-red-800'
                                            : ''
                                        }
                                      >
                                        {Math.abs(Number(donation.amount)).toLocaleString()}
                                      </span>
                                    </td>
                                    <td className="px-4 py-2 border text-left text-sm">
                                      {donation.payment_purpose}
                                    </td>
                                    <td className="px-4 py-2 border">{donation.date_str}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ) : (
                      <div className="text-center text-gray-500">Нет данных</div>
                    )}
                  </div>
                </div>
              </Transition>
            </div>
          </div>
        </div>
      }
    ></Dashboard>
  );
};

export default LimitPayeePage;
