import React, { Component } from 'react';

interface Props {
  title?: string;
  content?: React.ReactNode;
  noPadding?: boolean;
}

class AppCard extends Component<Props, any> {
  render() {
    const { title, content } = this.props;

    return (
      <div className={`w-full`}>
        {title && (
          <div
            className={`bg-[#EDEEF5] text-center uppercase text-zinc-800 rounded-t-lg border border-[#C5C4D7] py-1 font-bold text-sm`}
          >
            {title}
          </div>
        )}
        <div
          className={`bg-white ${
            !this.props.noPadding ? 'p-2 lg:p-4' : ''
          } border border-[#C5C4D7] border-t-0 rounded-b-lg`}
        >
          {content}
        </div>
      </div>
    );
  }
}

export default AppCard;
