import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import AppFileInput from '../../shared/AppFileInput';
import { read, utils } from 'xlsx';
import { DonationAccountInfo } from '../../../types/DonationRequest';
import { errorToast } from '../../../utils/errorToast';

interface AppBankDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (bank_data: DonationAccountInfo) => void;
}

interface XlsxBankDataRow {
  'Банковские реквизиты юридического лица': string;
  __EMPTY: string;
  __EMPTY_1: string | number;
}

interface XlsxBankData {
  [index: number]: XlsxBankDataRow;
}

interface AppBankDialogState {
  bank_data: DonationAccountInfo;
}

class AppBankDialog extends Component<AppBankDialogProps, AppBankDialogState> {
  handleSubmit = (): void => {
    this.props.onSubmit(this.state.bank_data);
  };

  handleOnChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    try {
      const files = event.target.files;

      if (files) {
        // read first file
        const wb = read(await files[0].arrayBuffer());

        const data: XlsxBankData = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]) || [];

        const bankData: DonationAccountInfo = {
          payment_account: String(data[1]['__EMPTY_1']) || '',
          corr_account: String(data[6]['__EMPTY_1']) || '',
          bik: String(data[5]['__EMPTY_1']) || '',
          bank: String(data[4]['__EMPTY_1']) || '',
        };

        this.setState({ bank_data: bankData });
      }
    } catch (e) {
      console.log(e);
      errorToast('Возникла ошибка. Что-то пошло не так');
    }
  };

  render() {
    const { isOpen, onClose } = this.props;

    return (
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    Загрузка файла
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500 mb-5">
                      Выберите файл с банковскими реквизитами
                    </p>
                    <AppFileInput onChange={this.handleOnChange} />
                  </div>

                  <div className="mt-10">
                    <div
                      className={`p-2 rounded-lg text-center w-2/3 mx-auto`}
                      style={{ background: 'rgba(15, 9, 79, 0.19)' }}
                    >
                      <a
                        href={
                          process.env.PUBLIC_URL + `/Банковские_реквизиты_юридического_лица.xlsx`
                        }
                        target="_blank"
                        rel="noreferrer"
                        className={`uppercase cursor-pointer text-primary font-semibold`}
                      >
                        <i className="far fa-file-excel mr-2"></i>
                        Скачать пример файла
                      </a>
                    </div>
                  </div>

                  <div className="mt-10 w-full flex flex-row flex-wrap justify-between">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-[#C9C8D6] px-4 py-2 text-sm font-medium text-[#8C89AC] focus:outline-none"
                      onClick={onClose}
                    >
                      Закрыть
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center bg-primary rounded-md px-4 py-2 text-sm font-medium text-white focus:outline-none"
                      onClick={this.handleSubmit}
                    >
                      Загрузить файл с банковскими реквизитами
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  }
}

export default AppBankDialog;
