import React, { Component } from 'react';
import { UserContext } from '../../../store/user';
import AppInput from '../../shared/AppInput';
import { apiWrapper } from '../../../utils/api';

interface AppUserEditFormState {
  email: string;
  full_name?: string;
  phone?: string;
  password: string;
  password_confirmation: string;
  errors: { [key: string]: string };
  showPassword: boolean;
}

class AppUserEditForm extends Component<any, AppUserEditFormState> {
  static contextType = UserContext;
  context!: React.ContextType<typeof UserContext>;

  constructor(props: {}) {
    super(props);
    this.state = {
      email: '',
      full_name: '',
      phone: '',
      password: '',
      password_confirmation: '',
      errors: {},
      showPassword: false,
    };
  }

  handleTogglePassword = (): void => {
    this.setState((prevState: AppUserEditFormState) => ({
      ...prevState,
      showPassword: !prevState.showPassword,
    }));
  };

  handleSubmit = async (): Promise<void> => {
    const userForm: {
      full_name: string;
      phone: string;
      password?: string;
      password_confirmation?: string;
    } = {
      full_name: this.state.full_name || '',
      phone: this.state.phone || '',
    };

    if (this.state.password && this.state.password_confirmation) {
      userForm['password'] = this.state.password;
      userForm['password_confirmation'] = this.state.password_confirmation;
    }

    await apiWrapper.post('/users/update', {
      user: { ...userForm },
    });
  };

  componentDidMount() {
    if (this.context?.is_authed) {
      const { email, full_name = '', phone = '' } = this.context;

      this.setState({
        email: email,
        full_name: full_name || '',
        phone: phone || '',
      });
    }
  }

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    this.setState({ [name]: value } as unknown as Pick<
      AppUserEditFormState,
      keyof AppUserEditFormState
    >);
  };

  render() {
    const {
      email,
      full_name = '',
      phone = '',
      password,
      password_confirmation,
      errors,
      showPassword,
    } = this.state;

    return (
      <div className="grid grid-cols-12">
        <div className="col-span-12 xl:col-span-8">
          <span className={`uppercase text-gray-400 text-base font-semibold`}>
            Данные заявителя
          </span>
          <div className="grid grid-cols-12 mt-5">
            <div className="col-span-12 xl:col-span-6 space-y-1.5 mb-1.5 lg:mb-0">
              <AppInput
                id="email"
                name="email"
                label="ЭЛЕКТРОННАЯ ПОЧТА"
                type="email"
                value={email}
                onChange={this.handleInputChange}
                readonly={true}
              />
              {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}

              <AppInput
                id="full_name"
                name="full_name"
                label="ФИО"
                type="text"
                value={full_name}
                onChange={this.handleInputChange}
              />
              {errors.full_name && <p className="text-red-500 text-sm">{errors.full_name}</p>}

              <AppInput
                id="phone"
                name="phone"
                label="телефон"
                type="text"
                value={phone}
                onChange={this.handleInputChange}
                mask={'+7 (999) 999-99-99'}
              />
              {errors.phone && <p className="text-red-500 text-sm">{errors.phone}</p>}
            </div>

            <div className="col-span-12 xl:col-span-6 space-y-1.5 xl:ml-5">
              <div className={`relative`}>
                <AppInput
                  id="password"
                  name="password"
                  label="Пароль"
                  value={password}
                  type={showPassword ? 'text' : 'password'}
                  onChange={this.handleInputChange}
                />

                <i
                  onClick={this.handleTogglePassword}
                  className={`
                  cursor-pointer
                  text-lg
                  text-black
                  absolute
                  right-8
                  top-4
                  ${showPassword ? 'far fa-eye' : 'far fa-eye-slash'}
              `}
                />
              </div>

              <div className={`relative`}>
                <AppInput
                  id="password_confirmation"
                  name="password_confirmation"
                  label="Подтверждение пароля"
                  value={password_confirmation}
                  type={showPassword ? 'text' : 'password'}
                  onChange={this.handleInputChange}
                />

                <i
                  onClick={this.handleTogglePassword}
                  className={`
                  cursor-pointer
                  text-lg
                  text-black
                  absolute
                  right-8
                  top-4
                  ${showPassword ? 'far fa-eye' : 'far fa-eye-slash'}
              `}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-12 mt-10">
          <button
            onClick={() => this.handleSubmit()}
            className={`text-[#828193] border-2 border-[#C9C8D6] font-semibold rounded-lg py-3 px-10 cursor-pointer`}
          >
            Изменить данные
          </button>
        </div>
      </div>
    );
  }
}

export default AppUserEditForm;
