import React, { Component, Fragment } from 'react';
import { DonationRequest } from '../../../types/DonationRequest';
import { Dialog, Transition } from '@headlessui/react';
import AppCompanyInfoPanel from '../AppCompanyInfoPanel';
import AppPurposeDialog from '../AppPurposeDialog';
import AppCompanyFounderInfoPanel from '../AppCompanyFounderInfoPanel';
import AppTabGroup from '../../shared/AppTabGroup';
import { Organization } from '../../../types/Organization';
import dayjs from 'dayjs';
import AppDownloadPrimaryBtn from '../../shared/AppDownloadPrimaryBtn';
import { downloadExport } from '../../../utils/downloadExport';
import { downloadSbisReport } from '../../../utils/downloadSbisReport';
import { downloadEgrulPdf } from '../../../utils/getDataByInnEgrul';
import AppNumberWithSpaces from '../../shared/AppNumberWithSpaces';

interface AppDonationRequestDialogProps {
  isOpen: boolean;
  current_donation_request: DonationRequest | null;
  onClose: () => void;
}

interface AppDonationRequestDialogState {
  exportLoading: boolean;
  sbisLoading: boolean;
  egrulLoading: boolean;
  purposeModal: boolean;
  current_donation_request: DonationRequest | null;
}

class AppDonationRequestDialog extends Component<
  AppDonationRequestDialogProps,
  AppDonationRequestDialogState
> {
  constructor(props: AppDonationRequestDialogProps) {
    super(props);

    this.state = {
      exportLoading: false,
      sbisLoading: false,
      egrulLoading: false,
      purposeModal: false,
      current_donation_request: null,
    };
  }

  componentDidMount() {
    this.setState({
      current_donation_request: JSON.parse(JSON.stringify(this.props.current_donation_request)),
    });
  }

  onOpenPurposeModal = (): void => {
    this.setState({ purposeModal: true });
  };

  onClosePurposeModal = (): void => {
    this.setState({ purposeModal: false });
  };

  onCloseHandler = (): void => {
    this.props.onClose();
  };

  onUpdateHandler = (updatedData: DonationRequest) => {
    this.setState({ current_donation_request: updatedData });
  };

  downloadExportHandler = async (): Promise<void> => {
    if (this.state.current_donation_request && this.state.current_donation_request.id) {
      this.setState({ exportLoading: true });
      await downloadExport(this.state.current_donation_request.id);
      this.setState({ exportLoading: false });
    }
  };

  sbisDownloadHandler = async (): Promise<void> => {
    if (this.state.current_donation_request) {
      this.setState({ sbisLoading: true });
      await downloadSbisReport(this.state.current_donation_request?.donation_organization_info.inn);
      this.setState({ sbisLoading: false });
    }
  };

  egrulDownloadHandler = async (): Promise<void> => {
    if (this.state.current_donation_request) {
      this.setState({ egrulLoading: true });
      await downloadEgrulPdf(this.state.current_donation_request?.donation_organization_info.inn);
      this.setState({ egrulLoading: false });
    }
  };

  handleOrganizationUpdate = (newOrganization: Organization) => {
    this.setState((prevState: any) => {
      return {
        current_donation_request: {
          ...prevState.current_donation_request,
          donation_organization_info: newOrganization,
        },
      };
    });
  };

  render() {
    const { isOpen } = this.props;
    const { current_donation_request } = this.state;

    return (
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={this.onCloseHandler}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-screen-xl transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-[28px] font-semibold leading-6 text-primary p-6"
                  >
                    Данные пожертвования
                  </Dialog.Title>

                  <div className="grid grid-cols-12 gap-x-5 bg-[#E8ECF4] p-6">
                    <div className="col-span-12 lg:col-span-6">
                      <AppTabGroup
                        tabListClassName={`flex space-x-2 font-semibold`}
                        tabs={[
                          {
                            title: 'данные об организации',
                            content: (
                              <div className={`bg-white w-full border border-slate-300`}>
                                {current_donation_request?.donation_organization_info && (
                                  <AppCompanyInfoPanel
                                    organization={
                                      current_donation_request.donation_organization_info
                                    }
                                    onOrganizationUpdate={this.handleOrganizationUpdate}
                                  />
                                )}

                                <div
                                  className={`my-2 mx-6 py-2 px-2 border-[#C9C8D6] bg-[#E8ECF4] rounded-lg grid grid-cols-12 items-center`}
                                >
                                  <div className="col-span-11">
                                    <div
                                      className={`uppercase text-xs text-[#0F094F99] font-semibold`}
                                    >
                                      Целевое назначение пожертвования
                                    </div>
                                    <div>{current_donation_request?.purpose}</div>

                                    {current_donation_request && (
                                      <AppPurposeDialog
                                        isOpen={this.state.purposeModal}
                                        onClose={this.onClosePurposeModal}
                                        onSubmit={this.onUpdateHandler}
                                        id={Number(current_donation_request?.id)}
                                      />
                                    )}
                                  </div>
                                  <div
                                    className="col-span-1 text-right cursor-pointer"
                                    onClick={() => this.onOpenPurposeModal()}
                                  >
                                    <i className="far fa-edit text-[20px] text-[#2C2C2C]"></i>
                                  </div>
                                </div>

                                <div
                                  className={`w-full flex flex-row gap-2 py-2 px-6 items-stretch`}
                                >
                                  <AppDownloadPrimaryBtn
                                    btnText={`Скачать отчет по заявке`}
                                    onClick={this.downloadExportHandler}
                                    loading={this.state.exportLoading}
                                  />
                                  <AppDownloadPrimaryBtn
                                    btnText={`Скачать отчет СБИС`}
                                    onClick={this.sbisDownloadHandler}
                                    loading={this.state.sbisLoading}
                                  />
                                  <AppDownloadPrimaryBtn
                                    btnText={`Скачать выписку ЕГРЮЛ`}
                                    onClick={this.egrulDownloadHandler}
                                    loading={this.state.egrulLoading}
                                  />
                                </div>
                              </div>
                            ),
                          },
                          {
                            title: 'Сведения об участниках / учредителях',
                            onClick: () => {
                              return;
                            },
                            content: (
                              <div className={`bg-white w-full border border-slate-300`}>
                                <AppCompanyFounderInfoPanel
                                  inn={
                                    this.state.current_donation_request?.donation_organization_info
                                      .inn || ''
                                  }
                                />
                              </div>
                            ),
                          },
                        ]}
                      />
                    </div>

                    <div className="col-span-12 lg:col-span-6">
                      <AppTabGroup
                        tabListClassName={`flex space-x-2 font-semibold`}
                        tabs={[
                          {
                            title: 'Данные платежа',
                            content: (
                              <div className={`flex flex-col w-full bg-white`}>
                                <div className={`flex flex-col border-b border-b-zinc-300`}>
                                  <div className={`py-2 px-6`}>
                                    <div
                                      className={`text-slate-500 uppercase text-xs font-semibold`}
                                    >
                                      Дата поступления
                                    </div>
                                    <div className="flex flex-row items-center justify-between gap-x-3 mt-2">
                                      <div
                                        className={`text-zinc-800 uppercase text-base font-semibold`}
                                      >
                                        {current_donation_request?.status === 'paid' &&
                                        current_donation_request?.status_updated_at ? (
                                          <span>
                                            {dayjs(
                                              current_donation_request?.status_updated_at,
                                            ).format('DD.MM.YYYY')}
                                          </span>
                                        ) : (
                                          <span>-</span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className={`flex flex-col border-b border-b-zinc-300`}>
                                  <div className={`py-2 px-6`}>
                                    <div
                                      className={`text-slate-500 uppercase text-xs font-semibold`}
                                    >
                                      Дата регистрации заявки
                                    </div>
                                    <div className="flex flex-row items-center justify-between gap-x-3 mt-2">
                                      <div
                                        className={`text-zinc-800 uppercase text-base font-semibold`}
                                      >
                                        {dayjs(current_donation_request?.created_at).format(
                                          'DD.MM.YYYY',
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className={`flex flex-col border-b border-b-zinc-300`}>
                                  <div className={`py-2 px-6`}>
                                    <div
                                      className={`text-slate-500 uppercase text-xs font-semibold`}
                                    >
                                      сумма
                                    </div>
                                    <div className="flex flex-row items-center justify-between gap-x-3 mt-2">
                                      <div
                                        className={`text-zinc-800 uppercase text-base font-semibold`}
                                      >
                                        <AppNumberWithSpaces
                                          value={Number(current_donation_request?.amount)}
                                        />
                                        ₽
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ),
                          },
                        ]}
                      />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  }
}

export default AppDonationRequestDialog;
