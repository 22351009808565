export const LEGAL_FORMS: { id: number; name: string; regex: RegExp }[] = [
  {
    id: 1,
    name: 'Акционерное общество (публичное/непубличное)',
    regex: /акционерн[оы][е] обществ[ао]/i,
  },
  {
    id: 2,
    name: 'Общество с ограниченной ответственностью',
    regex: /обществ[ао] с ограниченной ответственностью/i,
  },
  { id: 3, name: 'Общественная организация', regex: /общественная организация/i },
  { id: 4, name: 'Общественное движение', regex: /общественное движение/i },
  { id: 5, name: 'Общественный фонд', regex: /общественный фонд/i },
  {
    id: 6,
    name: 'Автономная некоммерческая организация',
    regex: /автономн[аы][яе] некоммерческ[аи][яе] организаци[яи]/i,
  },
];
