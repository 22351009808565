import React from 'react';

interface FilterBarProps {
  startDate: string;
  endDate: string;
  bank: string;
  innFilter: string;
  banks: string[];
  onFilterChange: (name: string, value: string) => void;
  onClearFilters: () => void;
  searchHandler: () => void;
}

const FilterBar: React.FC<FilterBarProps> = ({
  startDate,
  endDate,
  bank,
  innFilter,
  banks,
  onFilterChange,
  onClearFilters,
  searchHandler,
}) => {
  return (
    <div className="flex flex-wrap bg-primary rounded-lg py-2 px-3 lg:px-5 gap-2 items-center">
      <div className="text-white uppercase font-semibold text-sm">Фильтрация:</div>

      {/* Bank Selector */}
      <div className="w-full sm:w-auto">
        <select
          id="bank"
          name="bank"
          className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
          value={bank}
          onChange={(e) => onFilterChange(e.target.name, e.target.value)}
        >
          <option value="all">Все банки</option>
          {banks.map((b, index) => (
            <option value={b} key={index}>
              {b}
            </option>
          ))}
        </select>
      </div>

      {/* INN Filter */}
      <div className="w-full sm:w-auto">
        <input
          type="text"
          name="innFilter"
          placeholder="Поиск по ИНН"
          value={innFilter}
          onChange={(e) => onFilterChange(e.target.name, e.target.value)}
          className="rounded-lg focus:outline-none px-3 py-2 text-gray-800 text-sm"
        />
      </div>

      {/* Date Range Picker */}
      <div className="flex items-center text-sm">
        <span className="mr-2 text-white font-semibold">В период с</span>
        <input
          type="date"
          name="startDate"
          value={startDate}
          onChange={(e) => onFilterChange(e.target.name, e.target.value)}
          className="rounded-lg text-gray-800 px-2 py-1"
        />
      </div>
      <div className="flex items-center text-sm">
        <span className="mr-2 text-white font-semibold">по</span>
        <input
          type="date"
          name="endDate"
          value={endDate}
          onChange={(e) => onFilterChange(e.target.name, e.target.value)}
          className="rounded-lg text-gray-800 px-2 py-1"
        />
      </div>

      {/* Search and Clear Buttons */}
      <div className="flex space-x-2 w-full sm:w-auto">
        <button
          className="bg-white text-primary rounded-lg font-semibold px-6 py-2 w-full"
          onClick={searchHandler} // Use searchHandler here
        >
          Поиск
        </button>
        <button
          className="bg-gray-200 text-primary rounded-lg font-semibold px-6 py-2 w-full"
          onClick={onClearFilters} // Use onClearFilters here
        >
          Сбросить
        </button>
      </div>
    </div>
  );
};

export default FilterBar;
