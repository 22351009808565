import React, { Component } from 'react';
import Dashboard from '../../../containers/admin/Dashboard';
import AppLeftFilterBtn from '../../../components/admin/AppLeftFilterBtn';
import AppDonationRequestMiniCard from '../../../components/admin/AppDonationRequestMiniCard';
import AppDonationRequestHeaderInfo from '../../../components/admin/AppDonationRequestHeaderInfo';
import AppCompanyInfoPanel from '../../../components/admin/AppCompanyInfoPanel';
import AppDownloadPrimaryBtn from '../../../components/shared/AppDownloadPrimaryBtn';
import AppCompanyFounderInfoPanel from '../../../components/admin/AppCompanyFounderInfoPanel';
import AppDonationRequestCheckingItem from '../../../components/admin/AppDonationRequestCheckingItem';
import AppDonationRequestApprovement from '../../../components/admin/AppDonationRequestApprovement';
import AppTabGroup from '../../../components/shared/AppTabGroup';
import { apiWrapper } from '../../../utils/api';
import { DonationRequest } from '../../../types/DonationRequest';
import dayjs from 'dayjs';
import { convertStatusStringToEnum } from '../../../types/DonationRequestTranslations';
import { DONATION_REQUEST_VALIDATIONS } from '../../../const/DonationRequestValidations';
import AppValidationDialog from '../../../components/admin/AppValidationDialog';
import AppPurposeDialog from '../../../components/admin/AppPurposeDialog';
import { Popover, Portal } from '@headlessui/react';
import AppInput from '../../../components/shared/AppInput';
import { OGRN_MASK } from '../../../const/InputMasks';
import { downloadEgrulPdf } from '../../../utils/getDataByInnEgrul';
import { downloadAgreement } from '../../../utils/downloadAgreement';
import { prepareDate } from '../../../utils/prepareDateParams';
import { downloadSbisReport } from '../../../utils/downloadSbisReport';
import { downloadLetter } from '../../../utils/downloadLetter';
import AppUploadsDialog from '../../../components/shared/AppUploadsDialog';
import { toast } from 'react-toastify';
import { downloadExport } from '../../../utils/downloadExport';
import { Organization } from '../../../types/Organization';
import DocumentList from '../SummaryRegistry/DocumentList';
import { uploadDonationRequestFile } from '../../../utils/donationRequestUploads';

interface DashboardState {
  donation_requests: DonationRequest[];
  current_donation_request: null | DonationRequest;
  loading: boolean;
  status: string;
  page: number;
  validation_dialog: boolean;
  current_validation: {
    title: string;
    name: string;
  };
  purposeModal: boolean;
  ogrnSearch: string;
  startDateSearch: Date | null;
  endDateSearch: Date | null;
  minAmountSearch: number;
  maxAmountSearch: number;
  nameSearch: string;
  fundSearch: string;
  selectedDocType: string;
  selectedTemplate: string;
  uploadDialog: boolean;
  exportLoading: boolean;
  sbisLoading: boolean;
  egrulLoading: boolean;
}

interface DashboardProps {}

class DashboardPage extends Component<DashboardProps, DashboardState> {
  private containerRef = React.createRef<HTMLDivElement>();

  constructor(props: DashboardProps) {
    super(props);

    this.state = {
      donation_requests: [],
      current_donation_request: null,
      loading: false,
      status: '',
      page: 0,
      validation_dialog: false,
      current_validation: {
        title: '',
        name: '',
      },
      purposeModal: false,
      ogrnSearch: '',
      startDateSearch: null,
      endDateSearch: new Date(),
      nameSearch: '',
      minAmountSearch: 0,
      maxAmountSearch: 0,
      fundSearch: '',
      selectedDocType: 'договор',
      selectedTemplate: 'ооо',
      uploadDialog: false,
      exportLoading: false,
      sbisLoading: false,
      egrulLoading: false,
    };
  }

  handleOpenValidationDialog = (validation: { title: string; name: string }): void => {
    this.setState({
      validation_dialog: true,
      current_validation: {
        title: validation.title,
        name: validation.name,
      },
    });
  };

  handleCloseValidationDialog = (): void => {
    this.setState({
      validation_dialog: false,
      current_validation: {
        title: '',
        name: '',
      },
    });
  };

  handleSubmitValidation = async (verification: {
    comment: string;
    parameter: string;
    approved: boolean;
  }): Promise<void> => {
    if (this.state.current_donation_request?.id) {
      await apiWrapper
        .post(`/donation_requests/${this.state.current_donation_request.id}/verification_item`, {
          verification_item: {
            comment: verification.comment,
            parameter: verification.parameter,
            approved: verification.approved,
          },
        })
        .then((responseData: any) => {
          this.setState({ current_donation_request: { ...responseData } });
          this.handleCloseValidationDialog();
        });
    }
  };

  handleScroll = (event: Event) => {
    const target = event.target as HTMLDivElement;
    const { scrollTop, clientHeight, scrollHeight } = target;

    if (scrollTop + clientHeight >= scrollHeight) {
      this.setState(
        (prevState: DashboardState) => ({
          ...prevState,
          page: prevState.page + 1,
        }),
        async () => {
          await this.getDonationRequests(this.state.page, this.state.status);
        },
      );
    }
  };

  async getDonationRequests(
    page: number = 0,
    status: string = 'all',
    start_date: string = '',
    end_date: string = '',
    ogrn: string = '',
    min_amount: string = '',
    max_amount: string = '',
    name: string = '',
    fundName: string = '',
  ) {
    return new Promise((resolve, reject) => {
      apiWrapper
        .get(
          `/donation_requests?page=${page}&status=${status}&start_date=${start_date}&end_date=${end_date}&ogrn=${ogrn}&min_amount=${min_amount}&max_amount=${max_amount}&name=${name}&fund=${fundName}`,
        )
        .then((responseData: any) => {
          const { data } = responseData;
          const firstItem: number | string = data.length > 0 ? data[0].id : null;
          this.setState((prevState) => {
            const newDonationRequests = [...prevState.donation_requests, ...data];

            const uniqueDonationRequests = newDonationRequests.reduce((unique, item) => {
              return unique.some((obj: any) => obj.id === item.id) ? unique : [...unique, item];
            }, []);

            return { donation_requests: uniqueDonationRequests };
          });
          resolve(firstItem);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getFirst(id: any) {
    await apiWrapper.get(`/donation_requests/${id}`).then((responseData: any) => {
      this.setState({ current_donation_request: { ...responseData } });
    });
  }

  async componentDidMount() {
    await this.getDonationRequests().then(async (id) => {
      if (id) {
        await this.getFirst(id);
      }
    });

    if (this.containerRef.current) {
      this.containerRef.current.addEventListener('scroll', this.handleScroll);
    }
  }

  componentWillUnmount() {
    if (this.containerRef.current) {
      this.containerRef.current.removeEventListener('scroll', this.handleScroll);
    }
  }

  getDonationRequest = async (id: number): Promise<void> => {
    this.setState({ loading: true });
    await apiWrapper.get(`/donation_requests/${id}`).then((responseData: any) => {
      this.setState({ current_donation_request: responseData });
    });

    setTimeout(() => {
      this.setState({ loading: false });
    }, 300);
  };

  changeStatusHandler = async (newStatus: string): Promise<void> => {
    this.setState({
      status: newStatus,
      loading: true,
      page: 0,
      current_donation_request: null,
      donation_requests: [],
    });

    await this.getDonationRequests(0, newStatus).then(async (id) => {
      if (id) {
        await this.getFirst(id);
      }
    });

    this.setState({ loading: false });
  };

  onOpenPurposeModal = (): void => {
    this.setState({ purposeModal: true });
  };

  onClosePurposeModal = (): void => {
    this.setState({ purposeModal: false });
  };

  onUpdateHandler = (updatedData: DonationRequest) => {
    this.setState({ current_donation_request: updatedData });

    const index = this.state.donation_requests.findIndex((d) => d.id === updatedData.id);

    if (index > -1) {
      const updatedDonationRequests = [...this.state.donation_requests];
      updatedDonationRequests[index] = updatedData;
      this.setState({ donation_requests: updatedDonationRequests });
    }
  };

  downloadExportHandler = async (): Promise<void> => {
    if (this.state.current_donation_request && this.state.current_donation_request.id) {
      this.setState({ exportLoading: true });
      await downloadExport(this.state.current_donation_request.id);
      this.setState({ exportLoading: false });
    }
  };

  sbisDownloadHandler = async (): Promise<void> => {
    if (this.state.current_donation_request) {
      this.setState({ sbisLoading: true });
      await downloadSbisReport(this.state.current_donation_request?.donation_organization_info.inn);
      this.setState({ sbisLoading: false });
    }
  };

  egrulDownloadHandler = async (): Promise<void> => {
    let me = this;
    if (this.state.current_donation_request) {
      this.setState({ egrulLoading: true });
      downloadEgrulPdf(this.state.current_donation_request?.donation_organization_info.inn)
        .then(() => {
          me.setState({ egrulLoading: false });
        })
        .catch(() => {
          me.setState({ egrulLoading: false });
        });
    }
  };

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    this.setState({ [name]: value } as unknown as Pick<DashboardState, keyof DashboardState>);
  };

  onSearch = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    const formattedDates = prepareDate(this.state.startDateSearch, this.state.endDateSearch);
    this.setState({ donation_requests: [] });
    await this.getDonationRequests(
      0,
      'all',
      formattedDates.start_date,
      formattedDates.end_date,
      this.state.ogrnSearch,
      this.state.minAmountSearch ? String(this.state.minAmountSearch) : '',
      this.state.maxAmountSearch ? String(this.state.maxAmountSearch) : '',
      this.state.nameSearch,
      this.state.fundSearch,
    );
  };

  onClearSearch = async (): Promise<void> => {
    this.setState({
      donation_requests: [],
      nameSearch: '',
      startDateSearch: null,
      endDateSearch: null,
      ogrnSearch: '',
      minAmountSearch: 0,
      maxAmountSearch: 0,
      fundSearch: '',
    });
    await this.getDonationRequests(0, 'all');
  };

  onChangeDocType = (docType: string): void => {
    this.setState({ selectedDocType: docType });
  };

  onChangeTemplate = (template: string): void => {
    this.setState({ selectedTemplate: template });
  };

  getGeneratedDocs = async (): Promise<void> => {
    await apiWrapper
      .get(`/donation_requests/${this.state?.current_donation_request?.id}/generated_documents`)
      .then((responseData: any) => {
        if (this.state.current_donation_request) {
          // @ts-ignore
          this.setState((prevState) => ({
            ...prevState,
            current_donation_request: {
              ...prevState.current_donation_request,
              donation_request_documents: [...responseData],
            },
          }));
        }
      });
  };

  generateDoc = async (): Promise<void> => {
    switch (this.state.selectedDocType) {
      case 'договор':
        await downloadAgreement(Number(this.state?.current_donation_request?.id));
        break;
      case 'письмо':
        await downloadLetter(Number(this.state?.current_donation_request?.id));
        break;
    }

    await this.getGeneratedDocs();

    // await this.getDonationRequest(Number(this.state?.current_donation_request?.id));
  };

  closeUploadDialog = (): void => {
    this.setState({ uploadDialog: false });
  };

  openUploadDialog = (): void => {
    this.setState({ uploadDialog: true });
  };

  onSubmitUpload = async (files: { agreement: File; letter: File }): Promise<void> => {
    if (this.state.current_donation_request) {
      await uploadDonationRequestFile(
        Number(this.state.current_donation_request.id),
        files.agreement,
        'agreement',
        'moderator',
      );
      await uploadDonationRequestFile(
        Number(this.state.current_donation_request.id),
        files.letter,
        'letter',
        'moderator',
      );

      await this.getDonationRequest(Number(this.state.current_donation_request.id));
    }
  };

  sendEmail = async (): Promise<void> => {
    if (this.state.current_donation_request) {
      await apiWrapper
        .post(`/donation_requests/${Number(this.state.current_donation_request.id)}/send_email`, {})
        .then((response: any) => {
          if (response.message)
            toast(response.message, {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 5000,
              type: toast.TYPE.INFO,
              pauseOnHover: true,
            });
        });
    }
  };

  handleOrganizationUpdate = (newOrganization: Organization) => {
    this.setState((prevState: any) => {
      return {
        current_donation_request: {
          ...prevState.current_donation_request,
          donation_organization_info: newOrganization,
        },
      };
    });
  };

  onDeleteDocHandler = async (id: Number) => {
    await apiWrapper.post(`/donation_request_documents/${id}/delete`, {}).then(() => {
      if (!this.state?.current_donation_request?.donation_request_documents) return;

      const index = this.state.current_donation_request.donation_request_documents.findIndex(
        (d) => d.id === id,
      );

      if (index > -1) {
        const newDonationRequestDocuments = [
          ...this.state.current_donation_request.donation_request_documents.slice(0, index),
          ...this.state.current_donation_request.donation_request_documents.slice(index + 1),
        ];

        this.setState({
          current_donation_request: {
            ...this.state.current_donation_request,
            donation_request_documents: newDonationRequestDocuments,
          },
        });
      }
    });
  };

  render() {
    let me = this;

    const { donation_requests, current_donation_request, loading, status } = this.state;

    return (
      <Dashboard
        content={
          <div className={`grid grid-cols-12 pb-4`} style={{ height: 'calc(100vh - 114px)' }}>
            {/* very left column with filter buttons */}
            <div className="col-span-3 sm:col-span-2 md:col-span-2 lg:col-span-1 bg-white border-r border-gray-300 h-full py-4 px-2 pb-0">
              <div className={`flex h-full flex-col flex-nowrap items-center gap-2`}>
                <AppLeftFilterBtn
                  title={`Все заявки`}
                  status={''}
                  onClickHandler={this.changeStatusHandler}
                  isActive={status === ''}
                />
                <AppLeftFilterBtn
                  title={`Заявки от рег. фондов`}
                  status={'fund'}
                  onClickHandler={this.changeStatusHandler}
                  isActive={status === 'fund'}
                />
                <AppLeftFilterBtn
                  title={`Заявки в обработке`}
                  status={'in_processing'}
                  onClickHandler={this.changeStatusHandler}
                  icon={<i className="fas fa-sync"></i>}
                  isActive={status === 'in_processing'}
                />
                {/*<AppLeftFilterBtn*/}
                {/*  title={`Проверенные заявки`}*/}
                {/*  status={'verified'}*/}
                {/*  onClickHandler={this.changeStatusHandler}*/}
                {/*  icon={<i className="fas fa-check-circle"></i>}*/}
                {/*  isActive={status === 'verified'}*/}
                {/*/>*/}
                <AppLeftFilterBtn
                  title={`Заявки на доп. контроле`}
                  status={'not_verified'}
                  onClickHandler={this.changeStatusHandler}
                  icon={<i className="fas fa-times-circle"></i>}
                  isActive={status === 'not_verified'}
                />

                <hr className={`w-full border-stone-300`} />

                <AppLeftFilterBtn
                  title={`Утвержденные заявки`}
                  status={'approved'}
                  onClickHandler={this.changeStatusHandler}
                  icon={<i className="fas fa-shield-check"></i>}
                  classStr={`w-full border-stone-300`}
                  isActive={status === 'approved'}
                />

                <AppLeftFilterBtn
                  title={`Оплаченные заявки`}
                  status={'paid'}
                  onClickHandler={this.changeStatusHandler}
                  icon={<i className="fas fa-check-circle"></i>}
                  classStr={`approvedLeftFilterBtn ${status === 'paid' ? 'active' : ''}`}
                />

                <AppLeftFilterBtn
                  title={`Отклоненные заявки`}
                  status={'rejected'}
                  onClickHandler={this.changeStatusHandler}
                  icon={<i className="fas fa-times-circle"></i>}
                  classStr={`rejectedLeftFilterBtn ${status === 'rejected' ? 'active' : ''}`}
                />

                {/*<AppDonationRequestTypeSwitcher />*/}
              </div>
            </div>

            {/* column with mini cards of application */}
            <div
              className="col-span-3 sm:col-span-2 md:col-span-2 lg:col-span-3 overflow-y-scroll relative"
              ref={this.containerRef}
            >
              <form
                onSubmit={this.onSearch}
                className={`flex flex-row justify-between gap-2 py-2 px-3 sticky top-0 left-0 w-full bg-white z-10 shadow`}
              >
                <div className="flex w-full border border-[#C9C8D6] px-2 py-1.5 rounded-lg">
                  <input
                    onChange={this.handleInputChange}
                    name="fundSearch"
                    id="fundSearch"
                    value={this.state.fundSearch}
                    type="text"
                    className="outline-none focus:border-transparent focus:ring-0 flex-grow border-none focus:outline-0 focus:border-none"
                    placeholder="Поиск по фонду"
                  />
                  <button type={'submit'} className="text-[#C9C8D6] ml-2">
                    <i className="far fa-search"></i>
                  </button>
                </div>
                <div className={`flex items-center`}>
                  <Popover className="relative w-full">
                    <Popover.Button className="h-[54px] text-white uppercase bg-primary px-2 py-2 rounded-xl text-sm">
                      <div className="flex flex-row items-center font-semibold">
                        <i className="fas fa-sliders-h"></i>
                        <div className="hidden ml-2 2xl:inline-block">фильтр</div>
                      </div>
                    </Popover.Button>

                    <Portal>
                      <Popover.Panel className="absolute z-50 bg-white shadow-md p-4 left-0 top-0 w-11/12 sm:w-96">
                        <div className="grid grid-cols-12 gap-4">
                          <div className="col-span-12">
                            <AppInput
                              id="ogrnSearch"
                              name="ogrnSearch"
                              label="ОГРН"
                              type="text"
                              value={this.state.ogrnSearch}
                              onChange={this.handleInputChange}
                              mask={OGRN_MASK}
                            />
                          </div>
                          <div className="col-span-12 md:col-span-6">
                            <label
                              htmlFor="startDateSearch"
                              className="text-[#2C2C2C] font-semibold opacity-80"
                            >
                              Дата от
                            </label>
                            <input
                              type="date"
                              name="startDateSearch"
                              max={dayjs(this.state.endDateSearch).format('YYYY-MM-DD')}
                              onChange={this.handleInputChange}
                              className="border-[#C5C4D7] rounded-lg h-[60px] w-full"
                            />
                          </div>
                          <div className="col-span-12 md:col-span-6">
                            <label
                              htmlFor="endDateSearch"
                              className="text-[#2C2C2C] font-semibold opacity-80"
                            >
                              Дата до
                            </label>
                            <input
                              type="date"
                              name="endDateSearch"
                              min={dayjs(this.state.startDateSearch).format('YYYY-MM-DD')}
                              value={dayjs(this.state.endDateSearch).format('YYYY-MM-DD')}
                              max={dayjs(this.state.endDateSearch).format('YYYY-MM-DD')}
                              onChange={this.handleInputChange}
                              placeholder="Дата до"
                              className="border-[#C5C4D7] rounded-lg h-[60px] w-full"
                            />
                          </div>
                          <div className="col-span-12 md:col-span-6">
                            <AppInput
                              id="minAmountSearch"
                              name="minAmountSearch"
                              label="Сумма от"
                              min={0}
                              max={this.state.maxAmountSearch}
                              type="number"
                              value={this.state.minAmountSearch}
                              onChange={this.handleInputChange}
                            />
                          </div>
                          <div className="col-span-12 md:col-span-6">
                            <AppInput
                              id="maxAmountSearch"
                              name="maxAmountSearch"
                              label="Сумма до"
                              type="number"
                              min={this.state.minAmountSearch}
                              value={this.state.maxAmountSearch}
                              onChange={this.handleInputChange}
                            />
                          </div>
                          <div className="col-span-12">
                            <AppInput
                              id="nameSearch"
                              name="nameSearch"
                              label="Наименование организации"
                              type="text"
                              value={this.state.nameSearch}
                              onChange={this.handleInputChange}
                            />
                          </div>
                          <div className="col-span-12 md:col-span-6">
                            <button
                              className="text-xs w-full bg-primary text-white uppercase font-semibold rounded-lg h-10"
                              onClick={this.onSearch}
                              type="button"
                            >
                              Применить
                            </button>
                          </div>
                          <div className="col-span-12 md:col-span-6">
                            <button
                              className="text-xs w-full bg-[#C9C8D6] text-primary uppercase font-semibold rounded-lg h-10"
                              onClick={this.onClearSearch}
                            >
                              Сбросить фильтр
                            </button>
                          </div>
                        </div>
                      </Popover.Panel>
                    </Portal>
                  </Popover>
                </div>
              </form>
              {donation_requests && donation_requests.length > 0 ? (
                donation_requests.map((donation_request: DonationRequest, index: number) => (
                  <AppDonationRequestMiniCard
                    key={`donation_request_${index}`}
                    status={convertStatusStringToEnum(donation_request.status)}
                    fund_name={donation_request?.fund?.name || ''}
                    id={Number(donation_request.id)}
                    amount={Number(donation_request.amount)}
                    organization={donation_request.donation_organization_info.short_name}
                    date={dayjs(donation_request.created_at).format('DD.MM.YYYY')}
                    onClick={() => this.getDonationRequest(Number(donation_request.id))}
                    isActive={current_donation_request?.id === donation_request.id}
                    code={donation_request?.code}
                    debet={donation_request?.debet}
                  />
                ))
              ) : (
                <div className="flex p-4 items-center justify-center text-center h-full text-gray-500 text-sm font-semibold">
                  На данный момент заявки по данной категории отсутствуют
                </div>
              )}
            </div>

            <div className="col-span-6 sm:col-span-8 md:col-span-8 lg:col-span-8 bg-slate-200 p-2">
              {loading && (
                <div className={`flex flex-col content-center justify-center items-center h-full`}>
                  <i className="far fa-sync fa-spin text-primary fa-5x"></i>
                </div>
              )}

              {!loading && current_donation_request && (
                <div>
                  <AppDonationRequestHeaderInfo
                    fund_name={current_donation_request?.fund?.name || ''}
                    amount={Number(current_donation_request?.amount)}
                    code={current_donation_request?.code}
                  />

                  <div className={`w-full grid grid-cols-12 gap-x-2 mt-2`}>
                    <div className="col-span-12 lg:col-span-8">
                      <AppTabGroup
                        tabListClassName={`flex space-x-2 font-semibold`}
                        tabs={[
                          {
                            title: 'данные об организации',
                            content: (
                              <div className={`bg-white w-full border border-slate-300`}>
                                {current_donation_request?.donation_organization_info && (
                                  <AppCompanyInfoPanel
                                    organization={
                                      current_donation_request.donation_organization_info
                                    }
                                    onOrganizationUpdate={this.handleOrganizationUpdate}
                                  />
                                )}

                                <div
                                  className={`my-2 mx-6 py-2 px-2 border-[#C9C8D6] bg-[#E8ECF4] rounded-lg grid grid-cols-12 items-center`}
                                >
                                  <div className="col-span-11">
                                    <div
                                      className={`uppercase text-xs text-[#0F094F99] font-semibold`}
                                    >
                                      Целевое назначение пожертвования
                                    </div>
                                    <div>{current_donation_request.purpose}</div>

                                    <AppPurposeDialog
                                      isOpen={this.state.purposeModal}
                                      onClose={this.onClosePurposeModal}
                                      onSubmit={this.onUpdateHandler}
                                      id={Number(current_donation_request.id)}
                                    />
                                  </div>
                                  <div
                                    className="col-span-1 text-right cursor-pointer"
                                    onClick={() => this.onOpenPurposeModal()}
                                  >
                                    <i className="far fa-edit text-[20px] text-[#2C2C2C]"></i>
                                  </div>
                                </div>

                                <div
                                  className={`w-full flex flex-row gap-2 py-2 px-6 items-stretch`}
                                >
                                  <AppDownloadPrimaryBtn
                                    btnText={`Скачать отчет по заявке`}
                                    onClick={this.downloadExportHandler}
                                    loading={this.state.exportLoading}
                                  />
                                  <AppDownloadPrimaryBtn
                                    btnText={`Скачать отчет СБИС`}
                                    onClick={this.sbisDownloadHandler}
                                    loading={this.state.sbisLoading}
                                  />
                                  <AppDownloadPrimaryBtn
                                    btnText={`Скачать выписку ЕГРЮЛ`}
                                    onClick={this.egrulDownloadHandler}
                                    loading={this.state.egrulLoading}
                                  />
                                </div>
                              </div>
                            ),
                          },
                          {
                            title: 'Сведения об участниках / учредителях',
                            onClick: () => {
                              return;
                            },
                            content: (
                              <div className={`bg-white w-full border border-slate-300`}>
                                <AppCompanyFounderInfoPanel
                                  inn={
                                    this.state.current_donation_request?.donation_organization_info
                                      .inn || ''
                                  }
                                />
                              </div>
                            ),
                          },
                        ]}
                      />
                    </div>
                    <div className="col-span-12 lg:col-span-4">
                      <AppTabGroup
                        tabs={[
                          {
                            title: 'Проверка данных',
                            content: (
                              <div className={`bg-white w-full border border-slate-300`}>
                                <div className={`p-2`}>
                                  <AppDonationRequestApprovement
                                    status={convertStatusStringToEnum(
                                      current_donation_request.status,
                                    )}
                                    id={Number(current_donation_request.id)}
                                    donationRequest={current_donation_request}
                                    onUpdateHandler={this.onUpdateHandler}
                                  />
                                </div>

                                <hr className={`border border-zinc-300`} />

                                <div className={`p-2 flex flex-col gap-y-1`}>
                                  {DONATION_REQUEST_VALIDATIONS.map((v, index) => (
                                    <AppDonationRequestCheckingItem
                                      key={`checking_item_${index}`}
                                      title={v.display_name}
                                      name={v.name}
                                      verificationItems={
                                        current_donation_request.verification_items
                                          ? current_donation_request.verification_items
                                          : []
                                      }
                                      onRemove={() => {
                                        me.getDonationRequests().then(async (id) => {
                                          if (id) {
                                            await this.getFirst(id);
                                          }
                                        });
                                      }}
                                      // @ts-ignore
                                      status={current_donation_request[v.name]}
                                      donationStatus={current_donation_request.status}
                                      id={Number(current_donation_request.id)}
                                      onClickManualVerification={this.handleOpenValidationDialog}
                                    />
                                  ))}

                                  <AppValidationDialog
                                    title={this.state.current_validation.title}
                                    name={this.state.current_validation.name}
                                    isOpen={this.state.validation_dialog}
                                    onClose={this.handleCloseValidationDialog}
                                    onSubmit={this.handleSubmitValidation}
                                  />
                                </div>
                              </div>
                            ),
                          },
                          {
                            title: 'Формирование документов',
                            content: (
                              <div className={`bg-white w-full border border-slate-300`}>
                                <div className={`p-2`}>
                                  <div className="flex flex-col border border-primary rounded-lg bg-primary overflow-hidden">
                                    <div className="text-white font-semibold px-4 py-1">
                                      Формирование документа
                                    </div>
                                    <div className="bg-[#E8ECF4] p-2">
                                      <p className="font-semibold text-[#2C2C2C] mb-2">
                                        Тип документа
                                      </p>
                                      <div className="w-full flex flex-row gap-2 flex-nowrap overflow-x-scroll font-semibold">
                                        <div className={`flex-1`}>
                                          <button
                                            className={`leading-tight rounded-lg border-2 hover:border-primary hover:bg-primary hover:text-white text-left py-2 px-3
                                            ${
                                              this.state.selectedDocType === 'договор'
                                                ? 'border-primary bg-primary text-white'
                                                : ' border-[#C9C8D6] bg-white text-primary'
                                            }`}
                                            type="button"
                                            onClick={() => this.onChangeDocType('договор')}
                                            disabled={!this.state?.current_donation_request?.id}
                                          >
                                            Договор
                                            <br />
                                            пожертвования
                                          </button>
                                        </div>
                                        <div className={`flex-1`}>
                                          <button
                                            className={`leading-tight rounded-lg border-2 hover:border-primary hover:bg-primary hover:text-white text-left py-2 px-3
                                            ${
                                              this.state.selectedDocType === 'письмо'
                                                ? 'border-primary bg-primary text-white'
                                                : ' border-[#C9C8D6] bg-white text-primary'
                                            }`}
                                            type="button"
                                            onClick={() => this.onChangeDocType('письмо')}
                                            disabled={!this.state?.current_donation_request?.id}
                                          >
                                            Письмо о пожертвовании
                                          </button>
                                        </div>
                                      </div>

                                      <p className="font-semibold text-[#2C2C2C] my-2">
                                        Шаблон документа
                                      </p>
                                      <div className="w-full flex flex-row gap-2 flex-nowrap overflow-x-scroll font-semibold">
                                        <div className={`flex-1`}>
                                          <button
                                            className={`leading-tight rounded-lg border-2 hover:border-primary hover:bg-primary hover:text-white text-left py-2 px-3
                                            ${
                                              this.state.selectedTemplate === 'ооо'
                                                ? 'border-primary bg-primary text-white'
                                                : ' border-[#C9C8D6] bg-white text-primary'
                                            }`}
                                            type="button"
                                            onClick={() => this.onChangeTemplate('ооо')}
                                            disabled={!this.state?.current_donation_request?.id}
                                          >
                                            Документ
                                            <br />
                                            под ООО
                                          </button>
                                        </div>
                                        {/*<div className={`flex-1`}>*/}
                                        {/*  <button*/}
                                        {/*    className={`leading-tight rounded-lg border-2 hover:border-primary hover:bg-primary hover:text-white text-left py-2 px-3*/}
                                        {/*    ${*/}
                                        {/*      this.state.selectedTemplate === 'ип'*/}
                                        {/*        ? 'border-primary bg-primary text-white'*/}
                                        {/*        : ' border-[#C9C8D6] bg-white text-primary'*/}
                                        {/*    }`}*/}
                                        {/*    type="button"*/}
                                        {/*    onClick={() => this.onChangeTemplate('ип')}*/}
                                        {/*    disabled={!this.state?.current_donation_request?.id}*/}
                                        {/*  >*/}
                                        {/*    Документ*/}
                                        {/*    <br />*/}
                                        {/*    под ИП*/}
                                        {/*  </button>*/}
                                        {/*</div>*/}
                                        {/*<div className={`flex-1`}>*/}
                                        {/*  <button*/}
                                        {/*    className={`leading-tight rounded-lg border-2 hover:border-primary hover:bg-primary hover:text-white text-left py-2 px-3*/}
                                        {/*    ${*/}
                                        {/*      this.state.selectedTemplate === 'ано'*/}
                                        {/*        ? 'border-primary bg-primary text-white'*/}
                                        {/*        : ' border-[#C9C8D6] bg-white text-primary'*/}
                                        {/*    }`}*/}
                                        {/*    type="button"*/}
                                        {/*    onClick={() => this.onChangeTemplate('ано')}*/}
                                        {/*    disabled={!this.state?.current_donation_request?.id}*/}
                                        {/*  >*/}
                                        {/*    Документ*/}
                                        {/*    <br />*/}
                                        {/*    под АНО*/}
                                        {/*  </button>*/}
                                        {/*</div>*/}
                                      </div>

                                      <button
                                        className={`w-full uppercase rounded-lg border-2border-primary bg-primary text-white py-2 px-3 font-semibold my-2`}
                                        type="button"
                                        aria-label={'Сформировать документ'}
                                        onClick={this.generateDoc}
                                      >
                                        Сформировать документ
                                      </button>
                                    </div>
                                  </div>
                                </div>

                                <div className={`p-2`}>
                                  <div className="flex flex-col border border-primary rounded-lg bg-primary overflow-hidden">
                                    <div className="text-white font-semibold px-4 py-1">
                                      Сформированные документы:
                                    </div>
                                    <div className="bg-[#E8ECF4] p-2 flex flex-col space-y-2 max-h-[320px] overflow-y-scroll">
                                      {this.state.current_donation_request &&
                                        this.state.current_donation_request
                                          .donation_request_documents && (
                                          <DocumentList
                                            documents={this.state.current_donation_request.donation_request_documents.filter(
                                              (d) => d.uploaded_by !== 'org',
                                            )}
                                            current_donation_request={
                                              this.state.current_donation_request
                                            }
                                            onDeleteDocHandler={this.onDeleteDocHandler}
                                          />
                                        )}
                                    </div>
                                  </div>
                                </div>

                                <div className={`p-2`}>
                                  <div className="flex flex-col border border-primary rounded-lg bg-primary overflow-hidden">
                                    <div className="text-white font-semibold px-4 py-1">
                                      Подписанные документы:
                                    </div>
                                    <div className="bg-[#E8ECF4] p-2 flex flex-col space-y-2">
                                      {this.state.current_donation_request &&
                                        this.state.current_donation_request
                                          .donation_request_documents && (
                                          <DocumentList
                                            documents={this.state.current_donation_request.donation_request_documents.filter(
                                              (d) => d.uploaded_by === 'org',
                                            )}
                                            current_donation_request={
                                              this.state.current_donation_request
                                            }
                                            onDeleteDocHandler={this.onDeleteDocHandler}
                                          />
                                        )}
                                    </div>
                                  </div>
                                </div>

                                <div className={`p-2`}>
                                  <div className="flex flex-col border border-primary rounded-lg bg-primaryGradient overflow-hidden">
                                    <div className="text-white font-semibold px-4 py-1">
                                      Завершение формирования документов:
                                    </div>
                                    <div className="p-2 flex flex-col space-y-2">
                                      <div
                                        className={`cursor-pointer border-2 border-white rounded-lg p-2 bg-white`}
                                        onClick={() => this.openUploadDialog()}
                                      >
                                        <div className="grid grid-cols-12 items-center">
                                          <div className="col-span-10 leading-tight text-sm text-primary">
                                            <p className={`font-semibold`}>
                                              Загрузить пакет подписанных документов
                                            </p>
                                          </div>
                                          <div className="col-span-2 text-center">
                                            <i className="fas fa-upload fa-2x text-primary"></i>
                                          </div>
                                        </div>
                                      </div>

                                      <div
                                        className={`cursor-pointer border-2 border-white rounded-lg p-2 bg-white`}
                                        onClick={() => this.sendEmail()}
                                      >
                                        <div className="grid grid-cols-12 items-center">
                                          <div className="col-span-10 leading-tight text-sm text-primary">
                                            <p className={`font-semibold`}>
                                              Направить сформированные документы в адрес
                                              жертвователя
                                            </p>
                                          </div>
                                          <div className="col-span-2 text-center">
                                            <i className="fas fa-share fa-2x text-primary"></i>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <AppUploadsDialog
                                  isOpen={this.state.uploadDialog}
                                  onClose={this.closeUploadDialog}
                                  onSubmit={this.onSubmitUpload}
                                />
                              </div>
                            ),
                          },
                        ]}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        }
      />
    );
  }
}

export default DashboardPage;
