import React, { Component } from 'react';
import Dashboard from '../../../containers/admin/Dashboard';
import AppCompanyEditForm from '../../../components/company/AppCompanyEditForm';
import AppAuthorizedPersonEditForm from '../../../components/company/AppAuthorizedPersonEditForm';
import AppUserEditForm from '../../../components/company/AppUserEditForm';

interface FormTypeState {
  formType: FormType;
}

enum FormType {
  Company,
  Founders,
  User,
}

class AppAccount extends Component<any, FormTypeState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      formType: FormType.Company,
    };
  }

  handleFormTypeChange = (formTypeVal: FormType): void => {
    this.setState({ formType: formTypeVal });
  };

  switchBtnDefaultClass = `font-semibold text-sm py-3 w-52 rounded-full`;
  switchBtnActiveClass = `bg-primary text-white`;

  renderSwitch = () => {
    switch (this.state.formType) {
      case FormType.Company:
        return <AppCompanyEditForm />;
      case FormType.Founders:
        return <AppAuthorizedPersonEditForm />;
      case FormType.User:
        return <AppUserEditForm />;
    }
  };

  isActiveBtn = (formType: FormType): boolean => {
    return formType === this.state.formType;
  };

  render() {
    return (
      <Dashboard
        content={
          <div
            className={`bg-primary w-full overflow-y-scroll`}
            style={{ height: 'calc(100vh - 112px)' }}
          >
            <div className={`flex flex-col w-11/12 2xl:w-3/4 mx-auto gap-y-10 pt-10 h-full`}>
              <h1 className={`text-white uppercase text-2xl font-semibold`}>личный кабинет</h1>

              <div className="w-full bg-[#F0F2F7] rounded-lg py-3 xl:py-6 px-3 xl:px-6">
                <div className="flex flex-row w-full items-center justify-center">
                  <div className={`rounded-full border-2 border-[#C9C8D6]`}>
                    <button
                      className={`${this.switchBtnDefaultClass} ${
                        this.isActiveBtn(FormType.Company)
                          ? this.switchBtnActiveClass
                          : 'text-[#8C89AC]'
                      }`}
                      onClick={() => this.handleFormTypeChange(FormType.Company)}
                    >
                      Компания
                    </button>
                    <button
                      className={`${this.switchBtnDefaultClass} ${
                        this.isActiveBtn(FormType.Founders)
                          ? this.switchBtnActiveClass
                          : 'text-[#8C89AC]'
                      }`}
                      onClick={() => this.handleFormTypeChange(FormType.Founders)}
                    >
                      Руководитель
                    </button>
                    <button
                      className={`${this.switchBtnDefaultClass} ${
                        this.isActiveBtn(FormType.User)
                          ? this.switchBtnActiveClass
                          : 'text-[#8C89AC]'
                      }`}
                      onClick={() => this.handleFormTypeChange(FormType.User)}
                    >
                      Заявитель
                    </button>
                  </div>
                </div>
                <div className={`mt-4`}>{this.renderSwitch()}</div>
              </div>
            </div>
          </div>
        }
      ></Dashboard>
    );
  }
}

export default AppAccount;
