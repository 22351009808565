import React from 'react';
import DateTimeInput from '../../DateTimeInput';
import FloatingBarForTransactions from '../FloatingBarForTransactions';

interface ShownPeriodPickerProps {
  parentFloatingBarForTransactions: FloatingBarForTransactions;
}

export default class ShownPeriodPicker extends React.Component<ShownPeriodPickerProps, any> {
  render() {
    let me = this;

    return (
      <div
        className={
          'bg-[#EDEEF5] m-0 w-100 flex flex-row justify-center items-center content-center'
        }
      >
        <p className={'m-0 mr-4'}>В период с</p>
        <DateTimeInput
          placeholder={'От'}
          onChange={(_val: Date) => {
            // alert(_val);
            me.props.parentFloatingBarForTransactions.setState(
              {
                dateStart: _val,
              },
              me.props.parentFloatingBarForTransactions.refreshData,
            );
            // alert(_val);
          }}
          value={me.props.parentFloatingBarForTransactions.state.dateStart}
        />
        <span className={'m-4'}>-</span>
        <DateTimeInput
          placeholder={'До'}
          onChange={(_val: Date) => {
            me.props.parentFloatingBarForTransactions.setState(
              {
                dateEnd: _val,
              },
              me.props.parentFloatingBarForTransactions.refreshData,
            );
          }}
          value={me.props.parentFloatingBarForTransactions.state.dateEnd}
        />
        {(me.props.parentFloatingBarForTransactions.state.dateEnd.getTime() -
          me.props.parentFloatingBarForTransactions.state.dateStart.getTime()) /
          1000 /
          24 /
          60 /
          60 <
          363 && (
          <button
            onClick={() => {
              me.props.parentFloatingBarForTransactions.resetYear();
            }}
            style={{ fontSize: '14px' }}
            className={'ml-3 py-1 px-2 py-1 rounded-xl border bg-primary text-white'}
          >
            Весь год
          </button>
        )}
      </div>
    );
  }
}
