import React, { Component } from 'react';
import AppInput from '../AppInput';
import { Link } from 'react-router-dom';
import { UserContext } from '../../../store/user';
import { withRouter, WithRouterProps } from '../../../utils/withNavigation';
import { api, apiWrapper } from '../../../utils/api';

interface LoginFormProps extends WithRouterProps {
  showSignupBtn?: boolean;
}

interface LoginFormState {
  email: string;
  password: string;
  showPassword: boolean;
  errors: { [key: string]: string };
}

class AppLogin extends Component<LoginFormProps, LoginFormState> {
  static contextType = UserContext;
  context!: React.ContextType<typeof UserContext>;

  constructor(props: LoginFormProps) {
    super(props);

    this.state = {
      email: '',
      password: '',
      showPassword: false,
      errors: {},
    };
  }

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    this.setState({ [name]: value } as unknown as Pick<LoginFormState, keyof LoginFormState>);
  };

  handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    const { email, password } = this.state;
    const errors = {
      password: '',
      email: '',
    };

    if (!email) {
      errors.email = 'Email - обязательное поле';
    }

    if (!password) {
      errors.password = 'Пароль - обязательное поле';
    }

    this.setState({ email, password, showPassword: false, errors });

    if (!errors.email && !errors.password) {
      await apiWrapper
        .post('/users/sign_in', {
          user: {
            email,
            password,
          },
        })
        .then((responseData: any) => {
          this.context.setUser({
            id: parseInt(responseData?.user?.id),
            is_authed: true,
            is_moderator: responseData?.user?.is_moderator,
            full_name: responseData?.user?.full_name,
            phone: responseData?.user?.phone,
            email: responseData.user?.email,
            organization: { ...responseData?.organization },
            loading: false,
          });

          localStorage.setItem('auth_token', responseData.token);
          api.defaults.headers['Authorization'] = `${responseData.token}`;

          if (this.props.navigate) {
            if (this.props.showSignupBtn) {
              this.props.navigate('/');
            } else {
              this.props.navigate('/admin');
            }
          }
        });
    }
  };

  handleTogglePassword = (): void => {
    this.setState((prevState: LoginFormState) => ({
      ...prevState,
      showPassword: !prevState.showPassword,
    }));
  };

  render() {
    const { email, password, showPassword, errors } = this.state;
    const { showSignupBtn } = this.props;

    return (
      <div
        className={`
                mt-7
                bg-container
                rounded-2xl
                p-5
                xs:w-full
                sm:w-96
                md:w-1/2
                lg:w-1/3
                xl:w-1/3
                flex
                flex-col
                justify-center 
                items-center
            `}
      >
        <div className="uppercase text-base text-blue-gray-400 w-full text-left mb-5 font-semibold">
          Заполните Данные
        </div>
        <form className={`w-full flex flex-col gap-y-1.5`} onSubmit={this.handleSubmit}>
          <AppInput
            id="email"
            name="email"
            label="Email"
            type="email"
            value={email}
            onChange={this.handleInputChange}
          />
          {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}

          <div className={`relative`}>
            <AppInput
              id="password"
              name="password"
              label="Пароль"
              value={password}
              type={showPassword ? 'text' : 'password'}
              onChange={this.handleInputChange}
            />

            <i
              onClick={this.handleTogglePassword}
              className={`
                  cursor-pointer
                  text-lg
                  text-black
                  absolute
                  right-8
                  top-4
                  ${showPassword ? 'far fa-eye' : 'far fa-eye-slash'}
              `}
            />
          </div>

          {errors.password && <p className="text-red-500 text-sm">{errors.password}</p>}

          <button
            type="submit"
            className="mt-5 text-white bg-primaryGradient rounded-lg px-5 py-4 w-full font-semibold text-xl shadow-md"
          >
            Вход
            <i className="fas fa-chevron-right ml-5"></i>
          </button>
        </form>
        {showSignupBtn && (
          <Link to="/registration" className="w-full">
            <button
              type="button"
              className="mt-5 text-black bg-white rounded-lg px-5 py-4 w-full font-semibold text-xl shadow-md"
            >
              Регистрация
              <i className="fas fa-chevron-right ml-5"></i>
            </button>
          </Link>
        )}
      </div>
    );
  }
}

const AppLoginWithRouter = withRouter(AppLogin);

export default AppLoginWithRouter;
