import React from 'react';
import { TagsInput } from 'react-tag-input-component';

interface TagsContainerProps {
  fieldName: string;
  item: any;
  header: any;
  myIndex: number;
  onRemoved: (_msg: string) => void;
  onChange: (_msg: string[]) => void;
}

interface TagsContainerLoaded {
  loaded: boolean;
}

export default class TagsContainer extends React.Component<
  TagsContainerProps,
  TagsContainerLoaded
> {
  constructor(_props: TagsContainerProps) {
    super(_props);

    this.state = {
      loaded: false,
    };
  }

  componentDidMount() {
    this.setState({
      loaded: true,
    });
  }

  render() {
    let me = this;

    return (
      <>
        {/*<p>{me.props.item[me.props.header.name]}</p>*/}
        <TagsInput
          value={
            me.props.item[me.props.header.name]
              ? me.props.item[me.props.header.name].toString().split(';')
              : []
          }
          onKeyUp={(_e) => {
            if (_e.key === 'Enter') {
              _e.stopPropagation();
              // if (me.props.onChange) me.props.onChange([]);
            }
          }}
          onRemoved={(_v) => {
            me.props.onRemoved(_v);
          }}
          isEditOnRemove={true}
          onChange={(_v: string[]) => {
            if (!me.state.loaded) {
              me.setState({ loaded: true });
            } else {
              me.props.onChange(_v);
            }
          }}
          name=""
          placeHolder=""
          classNames={{ input: `w-full` }}
        />
      </>
    );
  }
}
