import React, { Component } from 'react';
import AppInput from '../../shared/AppInput';
import { UserContext } from '../../../store/user';
import { apiWrapper } from '../../../utils/api';
import { OrganizationLeader } from '../../../types/OrganizationLeader';
import { toast } from 'react-toastify';

interface AuthorizedPersonFormState {
  last_name: string;
  first_name: string;
  middle_name: string;
  birth_date: string;
  inn: string;
  position: string;
  place_of_work: string;
  registration_address: string;
  phone: string;
  email: string;
  passport_series: string;
  passport_number: string;
  passport_issuer: string;
  passport_department_code: string;
  errors: { [key: string]: string };
  organization_leader: OrganizationLeader | null;
}

class AppAuthorizedPersonEditForm extends Component<any, AuthorizedPersonFormState> {
  static contextType = UserContext;
  context!: React.ContextType<typeof UserContext>;

  constructor(props: {}) {
    super(props);
    this.state = {
      last_name: '',
      first_name: '',
      middle_name: '',
      birth_date: '',
      inn: '',
      position: '',
      place_of_work: '',
      registration_address: '',
      phone: '',
      email: '',
      passport_series: '',
      passport_number: '',
      passport_issuer: '',
      passport_department_code: '',
      errors: {},
      organization_leader: null,
    };
  }

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    // fix code below
    // @ts-ignore
    this.setState((prevState: AuthorizedPersonFormState) => ({
      ...prevState,
      organization_leader: {
        ...prevState.organization_leader,
        [name]: value,
      },
    }));
  };

  async componentDidMount() {
    if (this.context?.is_authed && this.context?.organization) {
      const { authorized_person } = this.context?.organization;

      if (authorized_person) {
        this.setState({
          last_name: authorized_person.split(' ')[0] || '',
          first_name: authorized_person.split(' ')[1] || '',
          middle_name: authorized_person.split(' ')[2] || '',
        });
      }

      await apiWrapper.get(`/organization_leaders/me`).then((responseData: any) => {
        this.setState((prevState: AuthorizedPersonFormState) => ({
          ...prevState,
          organization_leader: { ...responseData },
        }));
      });
    }
  }

  handleSubmit = async () => {
    // TODO: check error

    if (!this.state?.organization_leader?.id) return;

    await apiWrapper
      .patch(`/organization_leaders/${this.state.organization_leader.id}`, {
        organization_leader: {
          last_name: this.state.organization_leader.last_name,
          first_name: this.state.organization_leader.first_name,
          middle_name: this.state.organization_leader.middle_name,
          phone: this.state.organization_leader.phone,
          email: this.state.organization_leader.email,
          date_of_birth: this.state.organization_leader.date_of_birth,
          workplace: this.state.organization_leader.workplace,
          position: this.state.organization_leader.position,
          registration_address: this.state.organization_leader.registration_address,
          inn: this.state.organization_leader.inn,
          passport_series: this.state.organization_leader.passport_series,
          passport_number: this.state.organization_leader.passport_number,
          passport_issued_by: this.state.organization_leader.passport_issued_by,
          passport_subdivision_code: this.state.organization_leader.passport_subdivision_code,
        },
      })
      .then(() => {
        toast('Данные успешно обновлены', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          pauseOnHover: true,
        });
      });
  };

  render() {
    const { errors } = this.state;

    const { organization_leader } = this.state;
    const {
      last_name = '',
      first_name = '',
      middle_name = '',
      date_of_birth = '',
      inn = '',
      phone = '',
      email = '',
      position = '',
      workplace = '',
      registration_address = '',
      passport_series = '',
      passport_number = '',
      passport_issued_by = '',
      passport_subdivision_code = '',
    } = organization_leader || {};

    return (
      <div className="grid grid-cols-12">
        <div className="col-span-12 xl:col-span-8">
          <span className={`uppercase text-gray-400 text-base font-semibold`}>
            Данные руководителя
          </span>
          <div className="grid grid-cols-12 space-x-0 xl:space-x-5 mt-5">
            <div className="col-span-12 xl:col-span-6 space-y-1.5">
              <AppInput
                id="last_name"
                name="last_name"
                label="фамилия"
                type="text"
                value={last_name}
                onChange={this.handleInputChange}
              />
              {errors.last_name && <p className="text-red-500 text-sm">{errors.last_name}</p>}
              <AppInput
                id="first_name"
                name="first_name"
                label="имя"
                type="text"
                value={first_name}
                onChange={this.handleInputChange}
              />
              {errors.first_name && <p className="text-red-500 text-sm">{errors.first_name}</p>}
              <AppInput
                id="middle_name"
                name="middle_name"
                label="отчество"
                type="text"
                value={middle_name}
                onChange={this.handleInputChange}
              />
              {errors.middle_name && <p className="text-red-500 text-sm">{errors.middle_name}</p>}
              <AppInput
                id="date_of_birth"
                name="date_of_birth"
                label="Дата рождения"
                type="date"
                value={date_of_birth}
                onChange={this.handleInputChange}
              />
              {errors.birth_date && <p className="text-red-500 text-sm">{errors.birth_date}</p>}
              <AppInput
                id="inn"
                name="inn"
                label="ИНН"
                type="text"
                value={inn}
                onChange={this.handleInputChange}
                mask={'999999999999'}
              />
              {errors.inn && <p className="text-red-500 text-sm">{errors.inn}</p>}
            </div>

            <div className="col-span-12 xl:col-span-6 space-y-1.5 mt-1.5 xl:mt-0">
              <AppInput
                id="position"
                name="position"
                label="должность"
                type="text"
                value={position}
                onChange={this.handleInputChange}
              />
              {errors.position && <p className="text-red-500 text-sm">{errors.position}</p>}
              <AppInput
                id="workplace"
                name="workplace"
                label="Место работы"
                type="text"
                value={workplace}
                onChange={this.handleInputChange}
              />
              {errors.place_of_work && (
                <p className="text-red-500 text-sm">{errors.place_of_work}</p>
              )}
              <AppInput
                id="registration_address"
                name="registration_address"
                label="Адрес регистрации"
                type="text"
                value={registration_address}
                onChange={this.handleInputChange}
              />
              {errors.registration_address && (
                <p className="text-red-500 text-sm">{errors.registration_address}</p>
              )}
            </div>
          </div>
        </div>
        <div className="col-span-12 xl:col-span-4 lg:ml-10 mt-5 lg:mt-0">
          <span className={`uppercase text-gray-400 text-base font-semibold`}>
            Паспортные данные
          </span>
          <div className="grid grid-cols-12 mt-5 mb-5 xl:mb-10">
            <div className="col-span-12 space-y-1.5">
              <AppInput
                id="passport_series"
                name="passport_series"
                label="СЕРИЯ"
                type="text"
                value={passport_series}
                onChange={this.handleInputChange}
                mask={'99 99'}
              />
              {errors.passport_series && (
                <p className="text-red-500 text-sm">{errors.passport_series}</p>
              )}
              <AppInput
                id="passport_number"
                name="passport_number"
                label="НОМЕР"
                type="text"
                value={passport_number}
                onChange={this.handleInputChange}
                mask={'999999'}
              />
              {errors.passport_number && (
                <p className="text-red-500 text-sm">{errors.passport_number}</p>
              )}
              <AppInput
                id="passport_issued_by"
                name="passport_issued_by"
                label="кем и когда выдан"
                type="text"
                value={passport_issued_by}
                onChange={this.handleInputChange}
              />
              {errors.passport_issued_by && (
                <p className="text-red-500 text-sm">{errors.passport_issued_by}</p>
              )}
              <AppInput
                id="passport_subdivision_code"
                name="passport_subdivision_code"
                label="код подразделения"
                type="text"
                value={passport_subdivision_code}
                onChange={this.handleInputChange}
                mask={'999'}
              />
              {errors.passport_subdivision_code && (
                <p className="text-red-500 text-sm">{errors.passport_subdivision_code}</p>
              )}
            </div>
          </div>

          <span className={`uppercase text-gray-400 text-base font-semibold`}>
            Контактные данные
          </span>
          <div className="grid grid-cols-12 mt-5">
            <div className="col-span-12 space-y-1.5">
              <AppInput
                id="phone"
                name="phone"
                label="телефон"
                type=""
                value={phone}
                onChange={this.handleInputChange}
                mask={'+7 (999) 999-99-99'}
              />
              {errors.phone && <p className="text-red-500 text-sm">{errors.phone}</p>}
              <AppInput
                id="email"
                name="email"
                label="электронная почта"
                type="email"
                value={email}
                onChange={this.handleInputChange}
              />
              {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
            </div>
          </div>
        </div>

        <div className="col-span-12 mt-10">
          <button
            onClick={() => this.handleSubmit()}
            className={`text-[#828193] border-2 border-[#C9C8D6] font-semibold rounded-lg py-3 px-10 cursor-pointer`}
          >
            Изменить данные
          </button>
        </div>
      </div>
    );
  }
}

export default AppAuthorizedPersonEditForm;
