import React, { Component } from 'react';
import dayjs from 'dayjs';
import { DonationRequest } from '../../../types/DonationRequest';

interface DocumentListProps {
  documents: any[];
  current_donation_request: DonationRequest;
  onDeleteDocHandler: (doc_id: number) => void;
  showDeleteBtn?: boolean;
}

export default class DocumentList extends Component<DocumentListProps, any> {
  render() {
    const {
      documents,
      current_donation_request,
      onDeleteDocHandler,
      showDeleteBtn = true,
    } = this.props;

    return (
      <>
        {documents.map((_doc: any, _docIndex: number) => (
          <div
            className={`border-2 border-[#C9C8D6] text-primary rounded-lg p-2 bg-white w-full`}
            key={`doc_${_docIndex}`}
          >
            <div key={`doc-${_doc.id}`} className="grid grid-cols-12 items-center">
              <div className="col-span-2 text-center">
                <a href={`${_doc.file_url}`} download={true}>
                  <i className="fas fa-file-alt fa-2x"></i>
                </a>
              </div>
              <div className="col-span-8 leading-tight text-xs">
                <p className={`text-[#757575] font-semibold`}>
                  №{_doc.id} {dayjs(_doc.created_at).format('DD.MM.YYYY HH:mm')}
                </p>
                <p className={`font-bold`}>{_doc.title}:</p>
                <p className={`font-semibold`}>{current_donation_request.user.full_name}</p>
                <p className={`font-semibold`}>
                  {current_donation_request.donation_organization_info.short_name}
                </p>
                <p className={`font-semibold`}>
                  ИНН {current_donation_request.donation_organization_info.inn},{' '}
                  {dayjs(_doc.created_at).format('DD.MM.YYYY')}
                </p>
              </div>
              {showDeleteBtn && (
                <div
                  className="col-span-2 text-center cursor-pointer h-full flex flex-col justify-center items-center"
                  style={{ borderLeft: '2px solid #C9C8D6' }}
                  onClick={() => onDeleteDocHandler(_doc.id)}
                >
                  <i className="fas fa-trash fa-1.5x text-[#BA2E2E]"></i>
                </div>
              )}
            </div>
          </div>
        ))}
      </>
    );
  }
}
