import React, { Component } from 'react';
import AppHeader from '../../../components/shared/AppHeader';

interface DashboardProps {
  content: React.ReactNode;
}

class Dashboard extends Component<DashboardProps> {
  render() {
    const { content } = this.props;

    return (
      <div className="max-h-screen flex flex-col">
        <AppHeader />
        <main className="flex-1">{content}</main>
      </div>
    );
  }
}

export default Dashboard;
