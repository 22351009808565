import React from 'react';
import FloatingBarChart from '../../FloatingBarChart';
import dayjs, { ManipulateType } from 'dayjs';
// import DateTimeInput from '../DateTimeInput';
import colors from '../../../../style/colors.json';
import binaryColors from '../../../../style/binary_colors.json';
import months from '../../../../dictionaries/monthNames.json';
import cyrillicStringToDayJS from './helpers/datetime/cyrillicStringToDayJS';
import ShownPeriodPicker from './parts/ShownPeriodPicker';
import extractParticipants from './helpers/dataset_processing/extractParticipants';
import extractTags from './helpers/dataset_processing/extractTags';

interface FloatingBarForTransactionsProps {
  data: any[];
}

interface FloatingBarForTransactionsState {
  data: any[] | null;
  highLevelData: any[] | null;
  labels: string[] | null;
  tags: string[] | null;
  activeTags: string[];
  mode: string;
  showMode: string; //"Общий уровень" | "По компаниям";
  timeMode: string;
  dateStart: Date;
  dateEnd: Date;
  renderGeneration: number;
}

const showMode = (_mode: string, _transaction: any) => {
  let res = _transaction.realAmount < 0;

  if (_mode === 'Поступления') {
    res = _transaction.realAmount >= 0;
  }

  return res;
};

export default class FloatingBarForTransactions extends React.Component<
  FloatingBarForTransactionsProps,
  FloatingBarForTransactionsState
> {
  constructor(_props: FloatingBarForTransactionsProps) {
    super(_props);

    this.state = {
      data: null,
      highLevelData: null,
      labels: null,
      tags: null,
      mode: 'Поступления',
      timeMode: 'Месяц',
      activeTags: ['any'],
      dateStart: dayjs('2023-01-01').startOf('year').toDate(),
      dateEnd: dayjs(new Date()).endOf('year').toDate(),
      showMode: 'Общий уровень' as string,
      renderGeneration: 0,
    };

    this.detectTags = this.detectTags.bind(this);
    this.refreshData = this.refreshData.bind(this);
    this.resetYear = this.resetYear.bind(this);
  }

  componentDidMount() {
    let me = this;
    me.refreshData().then(() => {
      me.detectTags();
    });
  }

  resetYear() {
    let me = this;
    me.setState(
      {
        dateStart: dayjs('2023-01-01').startOf('year').toDate(),
        dateEnd: dayjs(new Date()).endOf('year').toDate(),
      },
      () => {
        me.refreshData();
      },
    );
  }

  refreshData() {
    let me = this;
    return new Promise((_resolve) => {
      let dataCopy = [...me.props.data] as any[];
      let minDate = me.state.dateStart;
      let maxDate = me.state.dateEnd;
      let timeMode =
        (maxDate.getTime() - minDate.getTime()) / 1000 / 24 / 60 / 60 < 32 ? 'День' : 'Месяц';

      let timeModeDict = {
        День: 'day',
        Месяц: 'month',
      } as { [key: string]: ManipulateType };

      let dt = timeModeDict[timeMode];

      dataCopy = dataCopy.map((_x) => {
        let _xNew = { ..._x };
        _xNew['date'] = cyrillicStringToDayJS(_xNew['date']);

        return _xNew;
      });
      if (minDate && maxDate) {
        let donaters = extractParticipants(dataCopy);

        let labels = [] as string[];
        let start = dayjs(minDate)
          .startOf(timeMode === 'Месяц' ? 'month' : 'week')
          .subtract(timeMode === 'Месяц' ? 1 : 0, 'month');
        let end = dayjs(maxDate)
          .endOf(timeMode === 'Месяц' ? 'month' : 'week')
          .add(timeMode === 'Месяц' ? 1 : 0, 'month');

        if (me.state.dateStart) {
          start = dayjs(me.state.dateStart);
        }

        if (me.state.dateEnd) {
          end = dayjs(me.state.dateEnd);
        }

        let currentDate = start.add(0, 'days');

        let highLevelDataSetsIncome = [] as any[];
        let highLevelDataSetsOutcome = [] as any[];

        while (currentDate <= end) {
          let textToAdd = currentDate.format('DD.MM.YYYY');
          if (timeMode === 'Месяц') {
            textToAdd = months[currentDate.month()];
          }

          labels.push(textToAdd);

          let incomeOnThatPeriod = 0;
          let outcomeOnThatPeriod = 0;
          for (let _donation of me.props.data) {
            let date = dayjs(cyrillicStringToDayJS(_donation['date']));
            if (date >= currentDate && date < currentDate.add(1, dt)) {
              if (_donation.realAmount > 0) incomeOnThatPeriod += _donation.realAmount;
              else outcomeOnThatPeriod += -_donation.realAmount;
            }
          }
          currentDate = currentDate.add(1, dt);
          highLevelDataSetsIncome.push(incomeOnThatPeriod);
          highLevelDataSetsOutcome.push(outcomeOnThatPeriod);
        }

        let dataSets = [] as any[];

        if (me.state.showMode === 'Общий уровень') {
          dataSets.push({
            label: 'Поступления',
            data: highLevelDataSetsIncome,
            backgroundColor: binaryColors[0],
          });
          dataSets.push({
            label: 'Расходы',
            data: highLevelDataSetsOutcome,
            backgroundColor: binaryColors[1],
          });
        } else {
          for (let _donater of donaters) {
            let data = [];
            let currentDate = start.add(0, dt);

            while (currentDate <= end) {
              const transactionsOnThatDay = dataCopy
                // eslint-disable-next-line no-loop-func
                .filter((_x) => {
                  let date = dayjs(_x['date']);
                  let tagsOk = false;

                  for (let _tag of me.state.activeTags) {
                    if (_x.tag_cloud && _x.tag_cloud.indexOf(_tag) > -1) {
                      tagsOk = true;
                      break;
                    }
                  }

                  if (me.state.activeTags.indexOf('any') > -1) {
                    tagsOk = true;
                  }

                  let payerDonaterFilter =
                    _x.realAmount < 0 ? _x.payee === _donater : _x.payer === _donater;

                  let dateOk = true;

                  if (me.state.dateStart) {
                    dateOk = dateOk && currentDate >= dayjs(me.state.dateStart);
                  }
                  if (me.state.dateEnd) {
                    dateOk = dateOk && currentDate <= dayjs(me.state.dateEnd);
                  }

                  return (
                    tagsOk &&
                    dateOk &&
                    date >= currentDate &&
                    date < currentDate.add(1, dt) &&
                    payerDonaterFilter &&
                    // _x.payer === _donater &&
                    showMode(me.state.mode, _x)
                  );
                })
                .map((_x) => _x.realAmount) as number[];

              let min = 0;
              let max = 0;

              if (transactionsOnThatDay.length > 0) {
                min = transactionsOnThatDay.reduce((partialSum, a) => partialSum + a, 0);
              }

              if (min > 0) {
                max = min;
                min = 0;
              }
              if (min < 0) {
                max = 0;
              }

              if (me.state.mode === 'Расходы') {
                data.push(-Math.min(min, max));
              } else {
                data.push(Math.max(min, max));
              }

              currentDate = currentDate.add(1, dt);
            }

            if (
              data.filter((_x) => {
                return _x !== 0;
              }).length > 0
            ) {
              dataSets.push({
                label: _donater,
                data: data,
                backgroundColor: colors[donaters.indexOf(_donater)],
              });
            }
          }
        }
        me.setState(
          {
            dateStart: start.toDate(),
            dateEnd: end.toDate(),
            labels: labels,
            data: dataSets,
          },
          () => {
            _resolve(true);
          },
        );
      }
    });
  }

  detectTags() {
    let me = this;
    let detectedTags = extractTags(me.props.data);

    me.setState({
      tags: detectedTags,
      activeTags: [...detectedTags, 'any'],
    });
  }

  render() {
    let me = this;
    if (!me.state.labels || !me.state.data) {
      return <p>Подсчет данных</p>;
    }

    return (
      <>
        <ShownPeriodPicker
          key={`gen-${me.state.renderGeneration}`}
          parentFloatingBarForTransactions={me}
        />

        <div
          className={
            'mt-2 relative flex flex-row content-center ml-auto items-center justify-between'
          }
        >
          {me.state.tags && me.state.activeTags && (
            <div
              className={'flex h-100 flex-row content-center items-center ml-4'}
              style={{ fontSize: '10px' }}
            >
              <p className={'mr-2'}>Теги:</p>

              {me.state.activeTags && (
                <button
                  onClick={() => {
                    if (!me.state.activeTags) return <></>;

                    let activeTags = [...me.state.activeTags];
                    let indexOfTag = activeTags.indexOf('any');
                    if (indexOfTag === -1) activeTags.push('any');
                    else activeTags.splice(indexOfTag, 1);
                    me.setState(
                      {
                        activeTags: activeTags,
                      },
                      () => {
                        me.refreshData();
                      },
                    );
                  }}
                  className={`mr-1 py-1 px-2 py-1 rounded-xl border ${
                    me.state.activeTags.indexOf('any') > -1
                      ? 'bg-primary text-white'
                      : 'bg-secondary border-primary '
                  }`}
                >
                  Все
                </button>
              )}

              {me.state.tags.map((_tag: string, _tagIndex: number) => {
                if (null === me.state.activeTags) {
                  return <></>;
                }

                return (
                  <button
                    key={`button-${_tagIndex}`}
                    onClick={() => {
                      if (!me.state.activeTags) return;

                      let activeTags = [...me.state.activeTags];
                      let indexOfTag = activeTags.indexOf(_tag);
                      if (indexOfTag === -1) activeTags.push(_tag);
                      else activeTags.splice(indexOfTag, 1);
                      me.setState(
                        {
                          activeTags: activeTags,
                        },
                        () => {
                          me.refreshData();
                        },
                      );
                    }}
                    className={`m-0 mr-1 px-2 py-1 rounded-xl border ${
                      me.state.activeTags.indexOf(_tag) > -1
                        ? 'bg-primary text-white'
                        : 'bg-secondary border-primary '
                    }`}
                  >
                    {_tag}
                  </button>
                );
              })}
            </div>
          )}
          <div>
            <button
              className={`mr-4 p-2 rounded-xl ${
                me.state.showMode === ('По компаниям' as string)
                  ? 'bg-primary text-white'
                  : 'text-primary bg-[#EDEEF5]'
              }`}
              style={{ fontSize: '10px', top: '10px', right: '10px', zIndex: 3 }}
              onClick={() => {
                let newShowMode =
                  me.state.showMode === 'Общий уровень' ? 'По компаниям' : 'Общий уровень';
                me.setState({ showMode: newShowMode }, () => {
                  me.refreshData();
                });
              }}
            >
              Детализация по организациям
            </button>
            {me.state.showMode === 'По компаниям' && (
              <>
                <button
                  className={`p-2 rounded-xl ${
                    me.state.mode === 'Поступления' ? 'bg-green-600 text-white' : 'text-primary'
                  }`}
                  style={{ fontSize: '10px' }}
                  onClick={() => {
                    me.setState({ mode: 'Поступления' }, () => {
                      me.refreshData();
                    });
                  }}
                >
                  Поступления
                </button>
                <button
                  className={`p-2 mr-4 rounded-xl ${
                    me.state.mode === 'Расходы' ? 'bg-red-800 text-white' : 'text-primary'
                  }`}
                  style={{ fontSize: '10px' }}
                  onClick={() => {
                    me.setState({ mode: 'Расходы' }, () => {
                      me.refreshData();
                    });
                  }}
                >
                  Расходы
                </button>
              </>
            )}
          </div>
        </div>
        {/*  <div className={'flex flex-row ml-auto items-center content-center  justify-end'}>*/}
        {/*    <i*/}
        {/*      onClick={() => {*/}
        {/*        me.setState(*/}
        {/*          {*/}
        {/*            showMode: 'Общий уровень',*/}
        {/*          },*/}
        {/*          () => {*/}
        {/*            me.refreshData();*/}
        {/*          },*/}
        {/*        );*/}
        {/*      }}*/}
        {/*      className={'fas fa-arrow-alt-left'}*/}
        {/*    />*/}
        {/*    <span className={'mr-2'}>Тип транзакций:</span>*/}
        {/*    <button*/}
        {/*      className={`p-2 rounded-xl ${*/}
        {/*        me.state.mode === 'Поступления' ? 'bg-primary text-white' : 'text-primary'*/}
        {/*      }`}*/}
        {/*      onClick={() => {*/}
        {/*        me.setState({ mode: 'Поступления' }, () => {*/}
        {/*          me.refreshData();*/}
        {/*        });*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      Поступления*/}
        {/*    </button>*/}
        {/*    <button*/}
        {/*      className={`p-2 rounded-xl ${*/}
        {/*        me.state.mode === 'Расходы' ? 'bg-primary text-white' : 'text-primary'*/}
        {/*      }`}*/}
        {/*      onClick={() => {*/}
        {/*        me.setState({ mode: 'Расходы' }, () => {*/}
        {/*          me.refreshData();*/}
        {/*        });*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      Расходы*/}
        {/*    </button>*/}
        {/*  </div>*/}
        {/*)}*/}

        {me.state.data.length > 0 ? (
          <>
            <div style={{ display: 'block' }} className={'pt-0 pb-4 pl-4 pr-4'}>
              <FloatingBarChart
                data={me.state.data}
                labels={me.state.labels}
                title={'Динамика платежей'}
                triggerIndexOfLabel={(_index) => {
                  if (me.state.labels) {
                    let label = me.state.labels[_index];
                    if (months.indexOf(label) > -1) {
                      let dateStart = dayjs().month(months.indexOf(label)).startOf('month');
                      let dateEnd = dayjs().month(months.indexOf(label)).endOf('month');
                      me.setState(
                        {
                          dateStart: dateStart.toDate(),
                          dateEnd: dateEnd.toDate(),
                        },
                        () => {
                          me.setState(
                            {
                              renderGeneration: me.state.renderGeneration + 1,
                            },
                            me.refreshData,
                          );
                        },
                      );
                    }
                  }
                }}
              />
            </div>

            {me.state.data.map((_participant: any, _participantIndex: number) => {
              return (
                <div
                  className={'ml-4 flex flex-row items-center content-center'}
                  key={`participantIndex_${_participantIndex}`}
                >
                  <span
                    style={{
                      width: '30px',
                      height: '15px',
                      display: 'flex',
                      background: _participant.backgroundColor,
                    }}
                  />
                  <p className={'m-0 ml-2'}>{_participant.label}</p>
                </div>
              );
            })}
          </>
        ) : (
          <p>Нет данных</p>
        )}
        <br />
      </>
    );
  }
}
