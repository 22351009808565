import React, { Component } from 'react';
import AppHeader from '../../../components/shared/AppHeader';

interface AuthContainerProps {
  content: React.ReactNode;
}

class AuthContainer extends Component<AuthContainerProps> {
  render() {
    const { content } = this.props;

    return (
      <div className="flex flex-col min-h-screen">
        <AppHeader />
        <div
          className={`flex flex-col bg-primary h-full text-white items-center justify-center py-10 flex-1`}
        >
          {content}
        </div>
      </div>
    );
  }
}

export default AuthContainer;
