import React from 'react';
import { BankAccount } from '../../../../types/BankAccount';

interface BankAccountViewerProps {
  bankAccount: BankAccount;
}

interface BankAccountViewerState {
  newValue: number | null;
  updating: boolean;
  forceBalance: number | null;
}

export default class BankAccountFastViewer extends React.Component<
  BankAccountViewerProps,
  BankAccountViewerState
> {
  constructor(_props: BankAccountViewerProps) {
    super(_props);
    this.state = {
      forceBalance: null,
      updating: false,
      newValue: null,
    };
    this.updateBalance = this.updateBalance.bind(this);
  }

  updateBalance() {
    let me = this;
    me.setState({ updating: true }, () => {
      let toSend = JSON.stringify({
        new_value: me.state.newValue,
      });
      // alert(toSend);
      let url = `${process.env.REACT_APP_API_BASE_URL || '/api'}/bank_accounts/${
        me.props.bankAccount.id
      }/update_balance`;
      // alert(url);
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${localStorage.getItem('auth_token')}`,
        },
        body: toSend,
      })
        .then((_e) => _e.json())
        .then((_res) => {
          me.setState({
            forceBalance: _res.balance,
          });
        });
    });
  }

  render() {
    let me = this;
    if (me.state.forceBalance) {
      return <>{me.state.forceBalance}</>;
    }
    if (!this.props.bankAccount.bank_account_balances) return <></>;

    if (this.props.bankAccount.bank_account_balances.length === 0) {
      return (
        <div
          style={me.state.updating ? { opacity: 0.6, pointerEvents: 'none' } : {}}
          className={'flex flex-col'}
        >
          <p
            style={{
              margin: 0,
              fontSize: '0.8em',
              lineHeight: '1.01em',
              marginBottom: '10px',
            }}
          >
            Загрузите банковскую выписку или установите связь с вашим банком с использованием API,
            чтобы получить данные о балансе
          </p>
          {/*<div className={"flex flex-row items-center content-center"}>*/}
          {/*  <input onChange={(_e: React.ChangeEvent<HTMLInputElement>) => {*/}
          {/*      me.setState({newValue: parseFloat(_e.target.value)})*/}
          {/*     }}*/}
          {/*     className={"mr-1"}*/}
          {/*     type={"number"}*/}
          {/*     placeholder={"Баланс, руб"}*/}
          {/*  />*/}
          {/*  { me.state.newValue && <i onClick={me.updateBalance}*/}
          {/*                            className={"cursor-pointer fal fa-save fa-2x"} /> }*/}
          {/*</div>*/}
        </div>
      );
    }

    return (
      <>
        {
          this.props.bankAccount.bank_account_balances[
            this.props.bankAccount.bank_account_balances.length - 1
          ].balance
        }
      </>
    );
  }
}
