import React, { useEffect, useState } from 'react';
import Dashboard from '../../../containers/admin/Dashboard';
import dayjs from 'dayjs';
import AppAccountBreadcrumb from '../../../components/admin/AppAccountBreadcrumb';
import AppImportTransactionsDialog from '../../../components/admin/transactions_flow/AppImportTransactionsDialog';
import { apiWrapper } from '../../../utils/api';
import { BankAccount } from '../../../types/BankAccount';
import { Tab } from '@headlessui/react';
import AppTable from '../../../components/shared/AppTable';
import { REGISTRY_TABLE_HEADERS_WITH_TAGS } from '../../../const/TableHeaders';
import { classNames } from '../../../utils/classNames';
import AccountModalDialogView from '../../../components/company/account_balance/modal_dialog_view/AccountModalDialogView';
import AppTransactionUploadsLogDialog from '../../../components/admin/AppTransactionUploadsLogDialog';
import FilterBar from '../../../components/admin/Transactions/FilterBar';
import { CategoryType, TransactionCategory } from '../../../types/TransactionCategory';

const AdminBalancePage: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [bank, setBank] = useState<string>('all');
  const [isImportTransactionDialogOpen, setIsImportTransactionDialogOpen] =
    useState<boolean>(false);
  const [bankAccounts, setBankAccounts] = useState<BankAccount[]>([]);
  const [bankNames, setBankNames] = useState<string[]>([]);
  const [selectedAccounts, setSelectedAccounts] = useState<BankAccount[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentAmount, setCurrentAmount] = useState<number | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [transactionsList, setTransactionsList] = useState<any[] | null>(null);
  const [incomeTransactions, setIncomeTransactions] = useState<any[] | null>(null);
  const [outcomeTransactions, setOutcomeTransactions] = useState<any[] | null>(null);
  const [income, setIncome] = useState<number | null>(null);
  const [outcome, setOutcome] = useState<number | null>(null);
  const [activeTags, setActiveTags] = useState<string[]>(['Все']);
  const [tags, setTags] = useState<string[]>(['Все']);
  const [activeTransaction, setActiveTransaction] = useState<any>(null);
  const [uploadsLogDialog, setUploadsLogDialog] = useState<boolean>(false);
  const [innFilter, setInnFilter] = useState<string>('');
  const [incomeCategories, setIncomeCategories] = useState<TransactionCategory[]>([]);
  const [expenseCategories, setExpenseCategories] = useState<TransactionCategory[]>([]);

  const getData = async (
    _signal = '',
    startDateParam = '',
    endDateParam = '',
    innFilterParam = '',
  ) => {
    try {
      const responseData = (await apiWrapper.get(
        `/bank_accounts/transactions?start_date=${startDateParam}&end_date=${endDateParam}&inn=${innFilterParam}`,
      )) as any;

      const incomeTrans = responseData.transactions_list
        ? responseData.transactions_list.filter((_x: any) => _x.amount >= 0)
        : [];
      const outcomeTrans = responseData.transactions_list
        ? responseData.transactions_list.filter((_x: any) => _x.amount < 0)
        : [];
      let detectedTags = ['Все'];

      if (responseData.transactions_list) {
        for (let transaction of responseData.transactions_list) {
          if (transaction.tag_cloud) {
            const transactionTags = transaction.tag_cloud.split(';');
            for (let tag of transactionTags) {
              if (tag.length > 0) detectedTags.push(tag);
            }
          }
        }
        detectedTags = detectedTags.filter((value, index, array) => array.indexOf(value) === index);
      }

      setTransactionsList([...responseData.transactions_list]);
      setIncomeTransactions([...incomeTrans]);
      setOutcomeTransactions(outcomeTrans);
      setIncome(responseData.income);
      setOutcome(responseData.outcome);
      setCurrentAmount(responseData.current_amount);
      setTags(detectedTags);
      setActiveTags(detectedTags);
    } catch (error) {
      console.error(error);
    }
  };

  const getBankAccounts = async (): Promise<void> => {
    try {
      const responseData = await apiWrapper.get('/bank_accounts');
      setBankAccounts([...(responseData as any)]);
      setSelectedAccounts([...(responseData as any)]);
    } catch (error) {
      console.error(error);
    }
  };

  const getBankNames = async (): Promise<void> => {
    try {
      const responseData = await apiWrapper.get('/bank_accounts/names');
      setBankNames([...(responseData as any)]);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getBankNames();
    getBankAccounts();
    getData('all', '', '', '');
    fetchTransactionCategories();
  }, []);

  const onFilterChange = (name: string, value: string) => {
    switch (name) {
      case 'startDate':
        setStartDate(value);
        break;
      case 'endDate':
        setEndDate(value);
        break;
      case 'bank':
        setBank(value);
        break;
      case 'innFilter':
        setInnFilter(value);
        break;
      default:
        break;
    }
  };

  const onClearFilters = () => {
    setStartDate('');
    setEndDate('');
    setBank('all');
    setInnFilter('');
  };

  const searchHandler = async () => {
    const formattedStartDate = startDate ? dayjs(startDate).format('YYYY-MM-DD') : '';
    const formattedEndDate = endDate ? dayjs(endDate).format('YYYY-MM-DD') : '';
    await getData('', formattedStartDate, formattedEndDate, innFilter);
  };

  const handleOpenImportDialog = () => {
    setIsImportTransactionDialogOpen(true);
  };

  const handleCloseImportDialog = () => {
    setIsImportTransactionDialogOpen(false);
  };

  const handleSubmitTransactionsImport = async () => {
    setIsImportTransactionDialogOpen(false);
  };

  const handleOpenUploadsLogDialog = () => {
    setUploadsLogDialog(true);
  };

  const handleCloseUploadsLogDialog = () => {
    setUploadsLogDialog(false);
  };

  const setCurrentAccount = (account: BankAccount) => {
    let updatedSelectedAccounts = [...selectedAccounts];
    const index = updatedSelectedAccounts.findIndex((acc) => acc.id === account.id);
    if (index > -1) {
      updatedSelectedAccounts.splice(index, 1);
    } else {
      updatedSelectedAccounts.push(account);
    }
    setSelectedAccounts(updatedSelectedAccounts);
  };

  const fetchTransactionCategories = async () => {
    const income: TransactionCategory[] = [];
    const expense: TransactionCategory[] = [];

    try {
      await apiWrapper
        .get('/transaction_categories')
        // @ts-ignore
        .then((responseData: TransactionCategory[]) => {
          responseData.forEach((category: TransactionCategory) => {
            if (category.category_type === CategoryType.Income) {
              income.push(category);
            } else if (category.category_type === CategoryType.Expense) {
              expense.push(category);
            }
          });

          setIncomeCategories(income);
          setExpenseCategories(expense);
        });
    } catch (error) {
      console.error('Error fetching transaction categories:', error);
    }
  };

  const handleCategoryChange = async (transactionId: number, categoryIds: number[]) => {
    try {
      await apiWrapper.post('/transaction_categories/update_for_transaction', {
        id: transactionId,
        transaction_category_ids: categoryIds,
      });
    } catch (error) {
      console.error('Error updating transaction categories:', error);
    }
  };

  return (
    <>
      <Dashboard
        content={
          <div className="pt-10 pb-4 px-4 bg-slate-200" style={{ height: 'calc(100vh - 112px)' }}>
            <div className="flex flex-row items-center mb-3">
              <div>
                <AppAccountBreadcrumb />
              </div>
              <div className="ml-1">
                <h1 className="text-[18px] text-[#0F094F]">
                  / Баланс общих расходов и поступлений
                </h1>
              </div>
            </div>

            {/* FilterBar Component */}
            <FilterBar
              startDate={startDate}
              endDate={endDate}
              bank={bank}
              innFilter={innFilter}
              banks={bankNames}
              onFilterChange={onFilterChange}
              onClearFilters={onClearFilters}
              searchHandler={searchHandler}
            />

            <div className="mt-2 flex flex-wrap gap-2">
              <button
                className="bg-[#007A22] rounded-lg text-white uppercase font-semibold px-6 py-2 leading-tight"
                onClick={handleOpenImportDialog}
              >
                Загрузить файл
                <i className="fas fa-file-plus ml-2"></i>
              </button>

              <AppImportTransactionsDialog
                isOpen={isImportTransactionDialogOpen}
                onClose={handleCloseImportDialog}
                onSubmit={handleSubmitTransactionsImport}
                accounts={bankAccounts}
              />

              <button
                className="bg-white rounded-lg text-primary uppercase font-semibold px-6 py-2 leading-tight"
                onClick={handleOpenUploadsLogDialog}
              >
                История загрузки
                <i className="fas fa-history ml-2"></i>
              </button>
            </div>

            <AppTransactionUploadsLogDialog
              isOpen={uploadsLogDialog}
              onClose={handleCloseUploadsLogDialog}
            />

            <div className="mt-4 h-4/5 overflow-scroll bg-white rounded-lg w-full px-2">
              <div>
                <p className="m-0 uppercase font-semibold my-3">Выбранные счета</p>
                <div className="flex flex-row w-full overflow-x-scroll flex-nowrap gap-x-3">
                  {bankAccounts.map((account: BankAccount, index: number) => (
                    <div
                      key={`account-${index}`}
                      className={`text-white rounded-lg py-1.5 px-3 cursor-pointer ${
                        selectedAccounts.find((acc) => acc.id === account.id)
                          ? 'bg-primary'
                          : 'bg-[#9C9C9C]'
                      }`}
                      onClick={() => setCurrentAccount(account)}
                    >
                      {account.payment_account}
                    </div>
                  ))}
                </div>

                <p className="m-0 uppercase font-semibold my-3">Теги</p>
                <div
                  key={JSON.stringify(tags)}
                  className="flex flex-row w-full overflow-x-scroll flex-nowrap gap-x-3"
                >
                  {tags.map((tag: string, tagIndex: number) => (
                    <button
                      key={`btn-${tagIndex}`}
                      onClick={() => {
                        let updatedActiveTags = [...activeTags];
                        const indexOfTag = updatedActiveTags.indexOf(tag);
                        if (indexOfTag === -1) {
                          updatedActiveTags.push(tag);
                        } else {
                          updatedActiveTags.splice(indexOfTag, 1);
                        }

                        if (updatedActiveTags.length > 1 && activeTags.indexOf('Все') > -1) {
                          updatedActiveTags.splice(activeTags.indexOf('Все'), 1);
                        }

                        if (tag === 'Все') {
                          updatedActiveTags = [...tags];
                        }

                        setActiveTags(updatedActiveTags);
                      }}
                      className={`mr-0 ml-0 py-1 px-3 rounded-xl border ${
                        activeTags.indexOf(tag) > -1
                          ? 'bg-primary text-white'
                          : 'bg-secondary border-primary'
                      }`}
                    >
                      {tag}
                    </button>
                  ))}
                </div>
              </div>

              <div className="w-full py-2 sm:px-0">
                <Tab.Group>
                  <Tab.List className="flex space-x-1 rounded-xl bg-slate-200 p-1">
                    <Tab
                      className={({ selected }) =>
                        classNames(
                          'w-full rounded-lg py-2.5 text-sm font-medium leading-5',
                          'ring-white ring-opacity-60 ring-offset-2 ring-offset-primary focus:outline-none focus:ring-2',
                          selected ? 'bg-white shadow' : 'hover:bg-white/[0.12]',
                        )
                      }
                    >
                      <div className="flex flex-col items-center uppercase font-semibold">
                        Реестр поступлений
                        <span className="text-[#17A63F]">
                          {income !== null ? `+ ${income.toLocaleString()} руб` : 'Загрузка данных'}
                        </span>
                      </div>
                    </Tab>
                    <Tab
                      className={({ selected }) =>
                        classNames(
                          'w-full rounded-lg py-2.5 text-sm font-medium leading-5',
                          'ring-white ring-opacity-60 ring-offset-2 ring-offset-primary focus:outline-none focus:ring-2',
                          selected ? 'bg-white shadow' : 'hover:bg-white/[0.12]',
                        )
                      }
                    >
                      <div className="flex flex-col items-center uppercase font-semibold">
                        Реестр расходов
                        <span className="text-[#B02A2A]">
                          {outcome !== null
                            ? `- ${outcome.toLocaleString()} руб`
                            : 'Загрузка данных'}
                        </span>
                      </div>
                    </Tab>
                  </Tab.List>
                  <Tab.Panels className="mt-2">
                    <Tab.Panel>
                      <div
                        key={JSON.stringify(selectedAccounts) + JSON.stringify(activeTags)}
                        className="border border-[#CCC] rounded-xl mt-2"
                      >
                        {incomeTransactions ? (
                          <AppTable
                            tableClass={`overflow-table`}
                            headers={REGISTRY_TABLE_HEADERS_WITH_TAGS}
                            onChange={() => {}}
                            onClick={(_data: any, _dataIndex: number) => {
                              setActiveTransaction(_data);
                            }}
                            data={incomeTransactions.filter((_x: any) => {
                              let accountOk = false;
                              let tagsOk = false;

                              if (activeTags.indexOf('Все') > -1) {
                                tagsOk = true;
                              } else {
                                for (let tag of activeTags) {
                                  if (
                                    _x.other_data.tag_cloud &&
                                    _x.other_data.tag_cloud.includes(tag)
                                  ) {
                                    tagsOk = true;
                                    break;
                                  }
                                }
                              }

                              for (let acc of selectedAccounts) {
                                let payeeAccountOk =
                                  _x.other_data.payee_account === acc.payment_account;
                                let payerAccountOk =
                                  _x.other_data.payer_account === acc.payment_account;

                                if (payeeAccountOk || payerAccountOk) {
                                  accountOk = true;
                                  break;
                                }
                              }

                              return accountOk && tagsOk;
                            })}
                            selectable={false}
                            enableSort={true}
                            categories={incomeCategories}
                            onCategoryChange={handleCategoryChange}
                          />
                        ) : (
                          <p style={{ margin: '30px' }}>Загрузка данных</p>
                        )}
                      </div>
                    </Tab.Panel>
                    <Tab.Panel>
                      <div className="border border-[#CCC] rounded-xl mt-2">
                        {outcomeTransactions ? (
                          <AppTable
                            tableClass={`overflow-table`}
                            headers={REGISTRY_TABLE_HEADERS_WITH_TAGS}
                            onChange={() => {}}
                            onClick={(_data: any, _dataIndex: number) => {
                              setActiveTransaction(_data);
                            }}
                            data={outcomeTransactions.filter((_x: any) => {
                              let accountOk = false;
                              let tagsOk = false;

                              if (activeTags.indexOf('Все') > -1) {
                                tagsOk = true;
                              } else {
                                for (let tag of activeTags) {
                                  if (
                                    _x.other_data.tag_cloud &&
                                    _x.other_data.tag_cloud.includes(tag)
                                  ) {
                                    tagsOk = true;
                                    break;
                                  }
                                }
                              }

                              for (let acc of selectedAccounts) {
                                let payeeAccountOk =
                                  _x.other_data.payee_account === acc.payment_account;
                                let payerAccountOk =
                                  _x.other_data.payer_account === acc.payment_account;

                                if (payeeAccountOk || payerAccountOk) {
                                  accountOk = true;
                                  break;
                                }
                              }

                              return accountOk && tagsOk;
                            })}
                            selectable={false}
                            categories={expenseCategories}
                            onCategoryChange={handleCategoryChange}
                          />
                        ) : (
                          <p style={{ margin: '30px' }}>Загрузка данных</p>
                        )}
                      </div>
                    </Tab.Panel>
                  </Tab.Panels>
                </Tab.Group>
              </div>
            </div>
          </div>
        }
      />
      {activeTransaction && (
        <AccountModalDialogView
          accountTransaction={activeTransaction ? activeTransaction.other_data : null}
          open={!!activeTransaction}
          onClose={() => setActiveTransaction(null)}
        />
      )}
    </>
  );
};

export default AdminBalancePage;
