export const orgKeyTranslations: Record<string, string> = {
  legal_form: 'Организационно-правовая форма',
  full_name: 'Полное наименование',
  short_name: 'Краткое наименование',
  inn: 'ИНН',
  kpp: 'КПП',
  ogrn: 'ОГРН',
  legal_address: 'Юридический адрес',
  actual_address: 'Фактический адрес',
  authorized_person: 'Руководитель / уполномоченное лицо',
  document: 'Документ на основании, которого действует Руководитель / уполномоченное лицо',
  registration_date: 'Дата регистрации',
  phone: 'Телефон',
  email: 'Электронная почта',
};
