import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { DonationRequest } from '../../../types/DonationRequest';
import DocumentList from '../../admin/SummaryRegistry/DocumentList';

interface DocumentsDialogProps {
  isOpen: boolean;
  onClose: () => void;
  current_donation_request: DonationRequest | null;
}

export default class DocumentsDialog extends Component<DocumentsDialogProps, any> {
  onCloseHandler = (): void => {
    this.props.onClose();
  };

  onDeleteDocHandler = (): void => {};

  render() {
    const { isOpen, current_donation_request } = this.props;

    return (
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={this.onCloseHandler}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-screen-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-[28px] font-semibold leading-6 text-gray-900"
                  >
                    Сформированные документы
                  </Dialog.Title>

                  <div className="mt-10">
                    <div className="grid grid-cols-12 gap-x-2">
                      <div className="col-span-12 grid grid-cols-2 gap-x-4">
                        {/* Сформированные документы */}
                        <div className="flex flex-col border border-primary rounded-lg bg-primary overflow-hidden">
                          <div className="text-white font-semibold px-4 py-1">
                            Сформированные документы:
                          </div>
                          <div className="bg-[#E8ECF4] p-2 flex flex-col space-y-2 flex-1 justify-center items-center">
                            {current_donation_request &&
                            current_donation_request.donation_request_documents &&
                            current_donation_request.donation_request_documents.filter(
                              (d) => d.uploaded_by !== 'org',
                            ).length > 0 ? (
                              <DocumentList
                                documents={current_donation_request.donation_request_documents.filter(
                                  (d) => d.uploaded_by !== 'org',
                                )}
                                current_donation_request={current_donation_request}
                                onDeleteDocHandler={this.onDeleteDocHandler}
                                showDeleteBtn={false}
                              />
                            ) : (
                              <p className="text-gray-500 text-center">Документы отсутствуют</p>
                            )}
                          </div>
                        </div>

                        {/* Подписанные документы */}
                        <div className="flex flex-col border border-primary rounded-lg bg-primary overflow-hidden">
                          <div className="text-white font-semibold px-4 py-1">
                            Подписанные документы:
                          </div>
                          <div className="bg-[#E8ECF4] p-2 flex flex-col space-y-2 flex-1 justify-center items-center">
                            {current_donation_request &&
                            current_donation_request.donation_request_documents &&
                            current_donation_request.donation_request_documents.filter(
                              (d) => d.uploaded_by === 'org',
                            ).length > 0 ? (
                              <DocumentList
                                documents={current_donation_request.donation_request_documents.filter(
                                  (d) => d.uploaded_by === 'org',
                                )}
                                current_donation_request={current_donation_request}
                                onDeleteDocHandler={this.onDeleteDocHandler}
                                showDeleteBtn={false}
                              />
                            ) : (
                              <p className="text-gray-500 text-center">Документы отсутствуют</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  }
}
