export default function extractParticipants(_data: any[]): any[] {
  return [
    ..._data.map((_x) => {
      return _x['payer'];
    }),
    ..._data.map((_x) => {
      return _x['payee'];
    }),
  ].filter((value, index, array) => array.indexOf(value) === index);
}
