import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import DashboardPage from './pages/admin/Dashboard';
import AdminLoginPage from './pages/admin/Login';
import CompanyLoginPage from './pages/company/Login';
import CompanyRegistrationPage from './pages/company/Registration';
import SummaryReport from './pages/admin/SummaryReport';
import SummaryRegistry from './pages/admin/SummaryRegistry';
import DashboardCompanyPage from './pages/company/Dashboard';
import AppNewDonationRequestPage from './pages/company/NewDonationRequest';
import AppAccount from './pages/company/Account';
import AppMyDonationRequests from './pages/company/MyDonationRequests';
import AdminAccountPage from './pages/admin/Account/Account';
import LimitPage from './pages/admin/Limit';
import AdminBalancePage from './pages/admin/BankAccountsBalance';
import AdminBankAccountsPage from './pages/admin/BankAccounts';
import ProtectedRoute from './components/shared/AppProtectedRoute';
import TransactionsMapPage from './pages/admin/TransactionsMap';
import LimitPayeePage from './pages/admin/Limit/LimitPayee';

const AppRoutes = () => {
  // const context = useContext(UserContext);

  return (
    <Router>
      <Routes>
        <Route
          path="/login"
          element={
            <ProtectedRoute path="/login">
              <CompanyLoginPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/"
          element={
            <ProtectedRoute path="/">
              <DashboardCompanyPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/registration"
          element={
            <ProtectedRoute path="/registration">
              <CompanyRegistrationPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/account"
          element={
            <ProtectedRoute path="/account">
              <AppAccount />
            </ProtectedRoute>
          }
        />
        <Route
          path="/new_application"
          element={
            <ProtectedRoute path="/new_application">
              <AppNewDonationRequestPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/my_applications"
          element={
            <ProtectedRoute path="/my_applications">
              <AppMyDonationRequests />
            </ProtectedRoute>
          }
        />
        {/* admin */}
        <Route
          path="/admin"
          element={
            <ProtectedRoute path="/admin" isAdminRoute>
              <DashboardPage />
            </ProtectedRoute>
          }
        />
        <Route path="/admin/login" element={<AdminLoginPage />} />
        <Route
          path="/summary_report"
          element={
            <ProtectedRoute path="/summary_report" isAdminRoute>
              <SummaryReport />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/summary_registry"
          element={
            <ProtectedRoute path="/admin/summary_registry" isAdminRoute>
              <SummaryRegistry />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/account"
          element={
            <ProtectedRoute path="/admin/account" isAdminRoute>
              <AdminAccountPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/limits"
          element={
            <ProtectedRoute path="/admin/limits" isAdminRoute>
              <LimitPayeePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/limits_old"
          element={
            <ProtectedRoute path="/admin/limits" isAdminRoute>
              <LimitPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/balance"
          element={
            <ProtectedRoute path="/admin/balance" isAdminRoute>
              <AdminBalancePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/bank_accounts"
          element={
            <ProtectedRoute path="/admin/bank_accounts" isAdminRoute>
              <AdminBankAccountsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/transactions_map"
          element={
            <ProtectedRoute path="/admin/transactions_map" isAdminRoute>
              <TransactionsMapPage />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
