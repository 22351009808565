import React, { Component } from 'react';
import AuthContainer from '../../../containers/shared/Auth';
import AppLoginWithRouter from '../../../components/shared/AppLogin';
import { Navigate } from 'react-router-dom';

class CompanyLoginPage extends Component<any, any> {
  state = {
    selectedRole: null, // 'donor' or 'operator'
    redirectToAdmin: false,
  };

  handleRoleSelection = (role: 'donor' | 'operator') => {
    if (role === 'operator') {
      this.setState({ redirectToAdmin: true });
    } else {
      this.setState({ selectedRole: 'donor' });
    }
  };

  resetRole = () => {
    this.setState({ selectedRole: null });
  };

  render() {
    const { selectedRole, redirectToAdmin } = this.state;

    if (redirectToAdmin) {
      return <Navigate to="/admin/login" />;
    }

    return (
      <AuthContainer
        content={
          <div className="flex flex-col w-full items-center">
            {!selectedRole && (
              <>
                <div className="text-center font-semibold text-xl uppercase mb-4">
                  Выберите контур
                </div>
                <div className="flex flex-col space-y-4 w-full max-w-md">
                  <button
                    onClick={() => this.handleRoleSelection('donor')}
                    className="bg-white text-primary uppercase font-semibold py-3 px-4 rounded-lg hover:bg-primary-dark"
                  >
                    <i className="far fa-user"></i>
                    <span className="ml-2">Контур для жертвователей</span>
                  </button>
                  <button
                    onClick={() => this.handleRoleSelection('operator')}
                    className="text-white border border-white uppercase font-semibold py-3 px-4 rounded-lg"
                  >
                    <i className="far fa-chart-bar"></i>
                    <span className="ml-2">Контур для операторов</span>
                  </button>
                </div>
              </>
            )}

            {selectedRole === 'donor' && (
              <>
                <div className="text-center font-semibold text-xl uppercase mb-4">
                  Вход в систему
                  <br />
                  для жертвователей
                </div>
                <AppLoginWithRouter showSignupBtn={true} />
                <div
                  onClick={() => this.resetRole()}
                  className="mt-10 uppercase text-sm text-white font-medium cursor-pointer"
                >
                  Назад
                </div>
              </>
            )}
          </div>
        }
      />
    );
  }
}

export default CompanyLoginPage;
