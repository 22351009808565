import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import AppInput from '../../shared/AppInput';

interface AppValidationDialogProps {
  title: string;
  name: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (verification: { comment: string; parameter: string; approved: boolean }) => void;
}

interface AppValidationDialogState {
  comment: string;
}

class AppValidationDialog extends Component<AppValidationDialogProps, AppValidationDialogState> {
  constructor(props: AppValidationDialogProps) {
    super(props);
    this.state = {
      comment: '',
    };
  }

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    this.setState({ [name]: value } as unknown as Pick<
      AppValidationDialogState,
      keyof AppValidationDialogState
    >);
  };

  handleSubmit = (approved: boolean): void => {
    this.props.onSubmit({
      comment: this.state.comment,
      parameter: this.props.name,
      approved: approved,
    });
    this.setState({ comment: '' });
  };

  onCloseHandler = (): void => {
    this.setState({ comment: '' });
    this.props.onClose();
  };

  render() {
    const { isOpen, title } = this.props;
    const { comment } = this.state;

    return (
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={this.onCloseHandler}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-screen-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    Ручная верификация: {title}
                  </Dialog.Title>
                  <div className="mt-2">
                    <AppInput
                      id="comment"
                      name="comment"
                      label="Комментарий"
                      type="text"
                      value={comment}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div className="mt-4 w-full flex flex-row justify-between">
                    <div>
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-[#C9C8D6] px-4 py-2 text-sm font-medium text-[#8C89AC] focus:outline-none"
                        onClick={this.onCloseHandler}
                      >
                        Закрыть
                      </button>
                    </div>
                    <div>
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-none px-4 py-2 text-sm font-medium text-white focus:outline-none bg-redGradient"
                        onClick={() => this.handleSubmit(false)}
                      >
                        Не соотвествует требованиям
                      </button>
                      <button
                        type="button"
                        className="ml-4 inline-flex justify-center rounded-md border border-none px-4 py-2 text-sm font-medium text-white focus:outline-none bg-greenGradient"
                        onClick={() => this.handleSubmit(true)}
                      >
                        Соотвествует требованиям
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  }
}

export default AppValidationDialog;
