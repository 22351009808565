import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import AppFileInput from '../../../shared/AppFileInput';
import { errorToast } from '../../../../utils/errorToast';
import { BankAccount } from '../../../../types/BankAccount';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import BankAccountFastViewer from '../bank_account_viewer/BankAccountFastViewer';
import { apiWrapper } from '../../../../utils/api';

interface AppImportTransactionsDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  accounts: BankAccount[];
}

interface AppImportTransactionsDialogState {
  selectedAccount: BankAccount | null;
  uploadedTransactions: null | any[];
}

class AppImportTransactionsDialog extends Component<
  AppImportTransactionsDialogProps,
  AppImportTransactionsDialogState
> {
  constructor(props: AppImportTransactionsDialogProps) {
    super(props);

    this.state = {
      selectedAccount: props.accounts.length ? props.accounts[0] : null,
      uploadedTransactions: null,
    };
  }

  componentDidUpdate(prevProps: AppImportTransactionsDialogProps) {
    if (this.props.accounts !== prevProps.accounts && this.props.accounts.length) {
      this.setState({ selectedAccount: this.props.accounts[0] });
    }
  }

  setCurrentAccount = (account: BankAccount): void => {
    this.setState({ selectedAccount: account, uploadedTransactions: null });
  };

  handleFilesOnChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    let me = this;
    try {
      const files = event.target.files;

      if (files) {
        for (let i = 0; i < files.length; i++) {
          const formData = new FormData();
          formData.append('file_object', files[i]);

          if (me.state.selectedAccount && me.state.selectedAccount.id) {
            formData.append('bank_account_id', String(me.state.selectedAccount.id));
          }

          await apiWrapper
            .postFile(`/users/sber/upload_file`, formData)
            .then((responseData: any) => {
              toast(responseData.status, {
                position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 5000,
                type: toast.TYPE.INFO,
                pauseOnHover: true,
              });

              try {
                me.setState({
                  uploadedTransactions: responseData.progress.transactions,
                });
              } catch (e) {}
              // me.props.onSubmit();
            });

          // await fetch(`${process.env.REACT_APP_API_BASE_URL || '/api'}/users/sber/upload_file`, {
          //   method: 'POST',
          //   headers: {
          //     Authorization: `${localStorage.getItem('auth_token')}`,
          //   },
          //   body: formData,
          // })
          //   .then((res) => {
          //     return res.json();
          //   })
        }
      }
    } catch (e) {
      console.log(e);
      errorToast('Возникла ошибка. Что-то пошло не так');
    }
  };

  render() {
    let me = this;
    const { isOpen, onClose } = this.props;
    return (
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-screen-xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div>
                    <p className={`m-0 uppercase font-semibold mb-3`}>
                      Выбрать банковский счёт для импорта транзакций
                    </p>
                    <div className={`flex flex-row w-full overflow-x-scroll flex-nowrap gap-x-3`}>
                      {this.props.accounts.map((account: BankAccount, index: number) => (
                        <div
                          key={index}
                          className={`text-white rounded-lg py-1.5 px-3 cursor-pointer ${
                            this.state?.selectedAccount?.id === account.id
                              ? 'bg-primary'
                              : 'bg-[#9C9C9C]'
                          }`}
                          onClick={() => this.setCurrentAccount(account)}
                        >
                          {account.payment_account}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div>
                    <p className={`m-0 mb-4 mt-8 font-semibold text-2xl`}>
                      Импорт банковских транзакций
                    </p>

                    <div
                      className={`border border-[#BDBDBD] rounded-2xl drop-shadow-lg w-full p-5`}
                    >
                      <table className={`w-full`}>
                        <thead>
                          <tr>
                            <th className={`text-[14px] font-medium`}>Банк</th>
                            <th className={`text-[14px] font-medium`}>Номер счета</th>
                            <th className={`text-[14px] font-medium`}>Дата открытия</th>
                            <th className={`text-[14px] font-medium`}>
                              Дата последнего импорта данных
                            </th>
                            <th className={`text-[14px] font-medium`}>Текущий баланс</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className={`text-[16px] font-semibold`}>
                              {this.state.selectedAccount?.bank_name}
                            </td>
                            <td className={`text-[16px] font-semibold`}>
                              {this.state.selectedAccount?.payment_account}
                            </td>
                            <td className={`text-[16px] font-semibold`}>
                              {dayjs(this.state.selectedAccount?.opening_date).format('DD.MM.YYYY')}
                            </td>
                            <td className={`text-[16px] font-semibold`}>
                              {this.state.selectedAccount?.last_file_upload_date}
                            </td>
                            <td className={`text-[16px] font-semibold`}>
                              {this.state.selectedAccount && (
                                <BankAccountFastViewer bankAccount={this.state.selectedAccount} />
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className={`mt-5`}>
                      <p className={`mb-2 text-[#222222] font-semibold`}>Выберите файлы</p>
                      <AppFileInput onChange={this.handleFilesOnChange} multiple={true} />
                      {me.state.uploadedTransactions && (
                        <table
                          className={'mt-4 app-import-upload-result rounded-2xl'}
                          style={{
                            width: '100%',
                            border: '1px solid #C9C8D6',
                            overflow: 'hidden',
                            padding: '30px',
                            background: '#E8ECF4',
                            fontSize: '10px',
                          }}
                        >
                          <tr style={{ fontWeight: 600, borderBottom: '1px solid #DDDDDD' }}>
                            <td style={{ padding: '8px', textTransform: 'uppercase' }}>Тип</td>
                            <td style={{ padding: '8px', textTransform: 'uppercase' }}>
                              Плательщик
                            </td>
                            <td style={{ padding: '8px', textTransform: 'uppercase' }}>Сумма</td>
                            <td style={{ padding: '8px', textTransform: 'uppercase' }}>Дата</td>
                            <td style={{ padding: '8px', textTransform: 'uppercase' }}>
                              Назначение
                            </td>
                            <td style={{ padding: '8px', textTransform: 'uppercase' }}>Статус</td>
                          </tr>
                          {me.state.uploadedTransactions.map((_tr, _trIndex) => {
                            let style = { padding: '8px' };
                            return (
                              <tr style={{}}>
                                <td style={style}>{_tr.amount > 0 ? 'Поступление' : 'Расход'}</td>
                                <td style={style}>
                                  {_tr.amount > 0 ? _tr.payer_name : _tr.payee_name}
                                </td>
                                <td style={style}>
                                  {Math.abs(_tr.amount).toLocaleString()}&nbsp;руб
                                </td>
                                <td style={style}>{_tr.date_str}</td>
                                <td style={style}>{_tr.payment_purpose}</td>
                                <td style={style}>{_tr.is_new ? 'Загружен' : 'Дубль'}</td>
                                {/*<td>{JSON.stringify(_tr)}</td>*/}
                              </tr>
                            );
                          })}
                        </table>
                      )}
                    </div>
                  </div>

                  <div className="mt-4 w-full flex flex-row flex-wrap justify-between">
                    <button
                      type="button"
                      className="ml-auto inline-flex justify-center bg-primary rounded-md px-4 py-2 text-sm font-medium text-white focus:outline-none"
                      onClick={this.props.onClose}
                    >
                      Завершить
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  }
}

export default AppImportTransactionsDialog;
