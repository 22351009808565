import { LEGAL_FORMS } from '../const/LegalForms';

export const findLegalForm = (companyName: string = ''): string => {
  for (let legalForm of LEGAL_FORMS) {
    if (legalForm.regex.test(companyName.toLowerCase())) {
      return legalForm.name;
    }
  }

  return '';
};

export const findNearestLegalForm = (legal: string = ''): string => {
  for (let legalForm of LEGAL_FORMS) {
    if (legalForm.regex.test(legal.toLowerCase())) {
      return legalForm.name;
    }
  }

  return legal;
};
