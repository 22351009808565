export const validateInput = (name: string, value: string, regex?: string | RegExp): string => {
  if (!value.trim()) {
    return 'Обязательное поле';
  }

  if (regex) {
    if (!new RegExp(regex).test(value)) {
      return 'Неправильный формат';
    }
  }

  return '';
};
