import React, { Component } from 'react';
import Dashboard from '../../../containers/admin/Dashboard';
import { BankAccount } from '../../../types/BankAccount';
import { apiWrapper } from '../../../utils/api';
import { DonationStat } from '../../../types/DonationStat';
import { Fund } from '../../../types/Fund';
import { Filter } from '../../../types/Filter';
import './account.scss';
import FundContainer from './FundContainer/FundContainer';

interface AdminAccountPageState {
  availableFunds: any[];
  isImportTransactionDialogOpen: boolean;
  accounts: string[];
  bank_accounts: BankAccount[];
  donation_stats: DonationStat[];
  isFundAccount: boolean;
  funds: Fund[];
  justiceFilter: Filter | null;
  usageFilter: Filter | null;
  transactionsList: any[] | null;
  isNewBankAccountDialogOpen: boolean;
  income: number | null;
  outcome: number | null;
  currentAmount: number | null;
  mode: 'income' | 'outcome';
  shownTransactionsList: any[];
  incomeTransactions: any[] | null;
  outcomeTransactions: any[] | null;
  payerStats: any;
  currentAmounts: any[] | null;
  activeFundId: null | number | string;
  subFunds: any[];
}

class AdminAccountPage extends Component<any, AdminAccountPageState> {
  data: any[] | null = null;
  initialized: boolean = false;

  constructor(props: any) {
    super(props);

    this.state = {
      isImportTransactionDialogOpen: false,
      accounts: [],
      bank_accounts: [],
      donation_stats: [],
      isFundAccount: true,
      funds: [],
      justiceFilter: null,
      usageFilter: null,
      transactionsList: null,
      isNewBankAccountDialogOpen: false,
      income: null,
      outcome: null,
      currentAmount: null,
      mode: 'income',
      shownTransactionsList: [],
      incomeTransactions: null,
      outcomeTransactions: null,
      payerStats: {},
      currentAmounts: null,
      activeFundId: -1,
      subFunds: [],
      availableFunds: [],
    };
  }

  async componentDidMount() {
    if (this.initialized) return;
    this.initialized = true;
    await this.getFunds();

    // After funds are loaded, check if we have a previously selected fund in localStorage
    const savedFundId = localStorage.getItem('selectedFundId');
    if (savedFundId && this.state.funds.find((f) => String(f.id) === savedFundId)) {
      this.setState({ activeFundId: savedFundId });
    }
  }

  async getFunds() {
    return new Promise((_resolve) => {
      apiWrapper.get('/funds/available_funds').then((responseData: any) => {
        this.setState({ funds: responseData }, () => {
          _resolve(responseData);
        });
      });
    });
  }

  onChangeAccount = (activeFundId: number | string): void => {
    this.setState({ activeFundId: activeFundId }, () => {
      // Store the selected fund in localStorage
      localStorage.setItem('selectedFundId', String(activeFundId));
    });
  };

  render() {
    let me = this;
    let funds = me.state.funds;
    return (
      <Dashboard
        content={
          <div className={`account-view pt-10 pb-4 px-4 bg-slate-200 min-h-screen`}>
            <div className={`mb-3 flex flex-row uppercase font-semibold text-[14px]`}>
              {funds.length > 0 ? (
                funds.map((_f, _fIndex) => (
                  <div
                    key={_f.id}
                    className={`fund-button border border-[#C5C4D7] px-4 py-2 cursor-pointer ${
                      this.state.activeFundId === _f.id
                        ? 'bg-[#E65100] text-white'
                        : 'bg-white text-black'
                    }`}
                    onClick={() => {
                      if (_f.id) this.onChangeAccount(_f.id);
                    }}
                  >
                    {_f.name}
                  </div>
                ))
              ) : (
                <div className="text-center text-gray-500 text-lg font-semibold">
                  Фонды отсутствуют
                </div>
              )}
            </div>

            {this.state.activeFundId !== -1 ? (
              <FundContainer
                key={`fund-${me.state.activeFundId}`}
                fundId={String(this.state.activeFundId)}
                connectedFunds={me.state.funds.filter((_x) => {
                  return _x.id === me.state.activeFundId;
                })}
                isFundAccount={me.state.isFundAccount}
              />
            ) : (
              <div className="text-center text-gray-500 text-lg font-semibold">
                Для отображения данных выберите фонд
              </div>
            )}
          </div>
        }
      />
    );
  }
}

export default AdminAccountPage;
