import React, { Component } from 'react';
import AuthContainer from '../../../containers/shared/Auth';
import AppLogin from '../../../components/shared/AppLogin';
import { Link } from 'react-router-dom';

class AdminLoginPage extends Component<any, any> {
  render() {
    return (
      <AuthContainer
        content={
          <div className={`flex flex-col w-full items-center`}>
            <div className={`text-center font-semibold text-xl uppercase`}>
              Вход в систему
              <br />
              для оператора
            </div>

            <AppLogin showSignupBtn={false} />
            <Link to={`/login`} className="mt-10 uppercase text-sm text-white font-medium">
              Назад
            </Link>
          </div>
        }
      />
    );
  }
}

export default AdminLoginPage;
