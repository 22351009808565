import { DonationRequestStatus } from './DonationRequest';

export const applicationStatusTranslations: Record<DonationRequestStatus, string> = {
  [DonationRequestStatus.Rejected]: 'Заявка отклонена',
  [DonationRequestStatus.InProgress]: 'Заявка в обработке',
  [DonationRequestStatus.Approved]: 'Заявка утверждена',
  [DonationRequestStatus.Paid]: 'Заявка оплачена',
  [DonationRequestStatus.NotPaid]: 'Заявка не оплачена',
};

export const applicationStatusTranslationsApi: Record<string, string> = {
  rejected: 'Заявка отклонена',
  in_processing: 'Заявка в обработке',
  approved: 'Заявка утверждена',
  paid: 'Заявка оплачена',
  notpaid: 'Заявка не оплачена',
};

export const convertStatusStringToEnum = (status: string): DonationRequestStatus => {
  switch (status) {
    case 'rejected':
      return DonationRequestStatus.Rejected;
    case 'in_processing':
      return DonationRequestStatus.InProgress;
    case 'approved':
      return DonationRequestStatus.Approved;
    case 'paid':
      return DonationRequestStatus.Paid;
    default:
      return DonationRequestStatus.InProgress;
  }
};
