import React, { Component } from 'react';
import { Organization } from '../../../types/Organization';
import { orgKeyTranslations } from '../../../types/OrganizationKeysTranslations';
import { apiWrapper } from '../../../utils/api';

interface CompanyProps {
  organization: Organization;
  onOrganizationUpdate: (newOrganization: Organization) => void;
}

interface CompanyState {
  editMode: boolean;
  editField: string | null;
  currentValue: string;
}

const ALLOWED_KEYS = [
  'legal_form',
  'full_name',
  'short_name',
  'inn',
  'kpp',
  'ogrn',
  'legal_address',
  'actual_address',
  'authorized_person',
  'document',
  'registration_date',
  'phone',
  'email',
];

const EDITABLE_KEYS = [
  'legal_address',
  'actual_address',
  'phone',
  'email',
  'document',
  'authorized_person',
];

class AppCompanyInfoPanel extends Component<CompanyProps, CompanyState> {
  constructor(props: CompanyProps) {
    super(props);
    this.state = {
      editMode: false,
      editField: null,
      currentValue: '',
    };
  }

  enableEditMode = (fieldName: string, value: string | null | undefined) => {
    this.setState({
      editMode: true,
      editField: fieldName,
      currentValue: value ?? '',
    });
  };

  disableEditMode = () => {
    this.setState({
      editMode: false,
      editField: null,
      currentValue: '',
    });
  };

  saveChanges = async () => {
    const { editField, currentValue } = this.state;
    const { organization } = this.props;

    if (!organization.id || !editField) {
      this.disableEditMode();
      return;
    }

    const updateData = { donation_organization_info: { [editField]: currentValue } };

    try {
      await apiWrapper.patch(`/donation_organization_infos/${organization.id}`, updateData);

      this.props.onOrganizationUpdate({
        ...organization,
        [editField]: currentValue,
      });

      this.disableEditMode();
    } catch (error) {
      console.error('Error updating organization:', error);
    }
  };

  cancelChanges = () => {
    this.disableEditMode();
  };

  render() {
    const { organization } = this.props;
    const { editMode, editField, currentValue } = this.state;

    return (
      <div className="flex flex-col w-full">
        {Object.keys(organization)
          .filter((key) => ALLOWED_KEYS.includes(key))
          .map((key) => (
            <div key={key} className="flex flex-col border-b border-b-zinc-300">
              <div className="py-2 px-6">
                <div className="text-slate-500 uppercase text-xs font-semibold">
                  {orgKeyTranslations[key]}
                </div>
                <div className="flex flex-row items-center justify-between gap-x-3 mt-2">
                  {editMode && editField === key ? (
                    <div className="flex-1">
                      <input
                        type="text"
                        value={currentValue}
                        onChange={(e) => this.setState({ currentValue: e.target.value })}
                        className="w-full text-zinc-800 uppercase text-base font-semibold rounded-lg"
                      />
                    </div>
                  ) : (
                    <div className="text-zinc-800 uppercase text-base font-semibold">
                      {organization[key as keyof Organization] ?? ''}
                    </div>
                  )}
                  {EDITABLE_KEYS.includes(key) && (
                    <div className="cursor-pointer">
                      {editMode && editField === key ? (
                        <div className="flex flex-row items-center gap-x-2">
                          <i
                            className="far fa-save text-xl text-green-500"
                            onClick={this.saveChanges}
                          ></i>
                          <i
                            className="far fa-times text-xl text-red-800"
                            onClick={this.cancelChanges}
                          ></i>
                        </div>
                      ) : (
                        <i
                          className="far fa-pen"
                          onClick={() =>
                            this.enableEditMode(
                              key,
                              organization[key as keyof Organization] as string,
                            )
                          }
                        ></i>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
      </div>
    );
  }
}

export default AppCompanyInfoPanel;
