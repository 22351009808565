import { toast } from 'react-toastify';

export const errorToast = (message: string) => {
  toast(message, {
    position: toast.POSITION.BOTTOM_CENTER,
    autoClose: 5000,
    type: toast.TYPE.ERROR,
    pauseOnHover: true,
  });
};
