export interface Notification {
  id?: number;
  date?: string;
  title?: string;
  company?: string;
}
export interface NotificationsState {
  unread: boolean;
  notifications: Notification[];
}

export const notificationsState: NotificationsState = {
  unread: true,
  notifications: [
    {
      id: 1,
      date: '09.03.2023г.',
      title: 'Направление отчета по использованию пожертвования',
      company: 'ООО «ЗАРЯ»',
    },
  ],
};
