import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { DonationRequest } from '../types/DonationRequest';
import { apiWrapper } from '../utils/api';

export const useDonationRequests = () => {
  const [donationRequests, setDonationRequests] = useState<DonationRequest[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<string>('all');
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [ogrnFilter, setOgrnFilter] = useState<string>('');
  const [innFilter, setInnFilter] = useState<string>('');

  useEffect(() => {
    fetchDonationRequests().then(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const prepareDate = () => {
    let start_date = '';
    let end_date = '';

    if (startDate) {
      start_date = dayjs(startDate).format('YYYY-MM-DD');
    }

    if (endDate) {
      end_date = dayjs(endDate).format('YYYY-MM-DD');
    }

    return { start_date, end_date };
  };

  const fetchDonationRequests = async (
    pageNumber: number = page - 1,
    statusValue: string = status,
    start_date: string = '',
    end_date: string = '',
    ogrn: string = ogrnFilter,
    inn: string = innFilter,
  ): Promise<void> => {
    setLoading(true);
    try {
      const responseData = (await apiWrapper.get(
        `/donation_requests?page=${pageNumber}&status=${statusValue}&start_date=${start_date}&end_date=${end_date}&ogrn=${ogrn}&inn=${inn}`,
      )) as any;
      setDonationRequests(responseData.data);
      setTotalPages(responseData.pages);
    } catch (error) {
      console.error('Error fetching donation requests:', error);
    } finally {
      setLoading(false);
    }
  };

  const searchHandler = async () => {
    setDonationRequests([]);
    const { start_date, end_date } = prepareDate();
    await fetchDonationRequests(0, status, start_date, end_date, ogrnFilter, innFilter);
  };

  const onPageChange = async (pageNumber: number) => {
    setDonationRequests([]);
    setPage(pageNumber);
    const { start_date, end_date } = prepareDate();
    await fetchDonationRequests(
      pageNumber - 1,
      status,
      start_date,
      end_date,
      ogrnFilter,
      innFilter,
    );
  };

  const resetFilters = async () => {
    setStatus('all');
    setOgrnFilter('');
    setInnFilter('');
    setStartDate(null);
    setEndDate(null);
    setPage(1);
    await fetchDonationRequests(0, 'all', '', '', '', '');
  };

  return {
    donationRequests,
    loading,
    status,
    setStatus,
    page,
    totalPages,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    ogrnFilter,
    setOgrnFilter,
    innFilter,
    setInnFilter,
    searchHandler,
    onPageChange,
    resetFilters,
  };
};
