import { Component } from 'react';
import { getFoundersByInnSbis } from '../../../utils/getDataByInnEgrul';
import { OrganizationFounder } from '../../../types/Organization';

interface CompanyFoundersProps {
  inn: string;
}

interface CompanyFoundersState {
  founders: OrganizationFounder[] | null;
}

class AppCompanyFounderInfoPanel extends Component<CompanyFoundersProps, CompanyFoundersState> {
  constructor(props: CompanyFoundersProps) {
    super(props);
    this.state = {
      founders: null,
    };
  }

  async componentDidMount() {
    if (this.props.inn) {
      const res = await getFoundersByInnSbis(this.props.inn);
      if (res) {
        this.setState({ founders: [...res[0]] });
      }
    }
  }

  render() {
    return (
      <div className={`flex flex-col w-full`}>
        {this.state.founders &&
          this.state.founders.map((founder: OrganizationFounder, index: number) => (
            <div key={index} className="flex flex-col py-2 px-2">
              <div
                className={`w-full bg-primaryGradient text-white uppercase rounded-lg font-semibold text-lg lg:text-xl px-4 py-1`}
              >
                Участник {index + 1}
              </div>
              <div className={`flex flex-col`}>
                <div className={`py-2 px-6 border-b border-b-zinc-300`}>
                  <div className={`text-slate-500 uppercase text-xs font-semibold`}>ФИО:</div>
                  <div className={`text-zinc-800 uppercase text-base font-semibold`}>
                    {founder.face_name}
                  </div>
                </div>
                <div className={`py-2 px-6 border-b border-b-zinc-300`}>
                  <div className={`text-slate-500 uppercase text-xs font-semibold`}>ИНН:</div>
                  <div className={`text-zinc-800 uppercase text-base font-semibold`}>
                    {founder.inn}
                  </div>
                </div>
                <div className={`py-2 px-6 border-b border-b-zinc-300`}>
                  <div className={`text-slate-500 uppercase text-xs font-semibold`}>
                    Размер доли (в процентах):
                  </div>
                  <div className={`text-zinc-800 uppercase text-base font-semibold`}>
                    {founder.share}%
                  </div>
                </div>
              </div>
            </div>
          ))}
        {!this.state.founders ||
          (this.state.founders.length === 0 && (
            <div className={`flex flex-col py-2 px-2 text-center`}>
              <p>
                Данные о собственниках, учредителях юридического лица необходимо уточнить в
                профильных организациях в связи с отсутствием необходимых сведений в ЕГРЮЛ
              </p>
            </div>
          ))}
      </div>
    );
  }
}

export default AppCompanyFounderInfoPanel;
