import React, { useMemo } from 'react';
import { ApplicationMiniCard, DonationRequestStatus } from '../../../types/DonationRequest';
import AppNumberWithSpaces from '../../shared/AppNumberWithSpaces';
import { applicationStatusTranslations } from '../../../types/DonationRequestTranslations';

interface AppApplicationMiniCardProps extends ApplicationMiniCard {
  onClick: () => Promise<void>;
  isActive: boolean;
}

const AppDonationRequestMiniCard: React.FC<AppApplicationMiniCardProps> = ({
  date,
  debet,
  amount,
  organization = '',
  fund_name,
  status,
  isActive,
  code,
  onClick,
}) => {
  // Memoize the status components to prevent unnecessary re-renders
  const statusComponent = useMemo(() => {
    switch (status) {
      case DonationRequestStatus.Rejected:
        return (
          <div className="text-red-800 text-sm uppercase font-semibold">
            <i className="fas fa-times-circle mr-1"></i>
            {applicationStatusTranslations[DonationRequestStatus.Rejected]}
          </div>
        );
      case DonationRequestStatus.InProgress:
        return (
          <div className="text-primary text-sm uppercase font-semibold">
            <i className="far fa-spinner fa-spin mr-1"></i>
            {applicationStatusTranslations[DonationRequestStatus.InProgress]}
          </div>
        );
      case DonationRequestStatus.Approved:
        return (
          <div className="text-green-600 text-sm uppercase font-semibold">
            <i className="fas fa-check-circle mr-1"></i>
            {applicationStatusTranslations[DonationRequestStatus.Approved]}
          </div>
        );
      default:
        return null;
    }
  }, [status]);

  const paymentStatusComponent = useMemo(() => {
    if (debet && debet === amount) {
      return (
        <div className="text-green-600 text-sm uppercase font-semibold">
          <i className="fas fa-check-circle mr-1"></i>
          {applicationStatusTranslations[DonationRequestStatus.Paid]}
        </div>
      );
    } else {
      return (
        <div className="text-red-800 text-sm uppercase font-semibold">
          <i className="fas fa-times-circle mr-1"></i>
          {applicationStatusTranslations[DonationRequestStatus.NotPaid]}
        </div>
      );
    }
  }, [debet, amount]);

  return (
    <div
      className={`py-2 px-3 border-b border-b-gray-300 cursor-pointer ${
        isActive ? 'bg-[#EDEEF5]' : 'bg-white'
      }`}
      onClick={onClick}
    >
      <div className="text-slate-500 text-xs leading-tight uppercase font-semibold mb-1">
        {date}
      </div>
      <div className="text-zinc-800 text-base leading-tight uppercase font-bold mb-1">
        {code && <span className="text-primary font-semibold">№{code}</span>}
        <br />
        <span className="font-mono text-zinc-800 text-xs leading-tight uppercase font-semibold">
          Получатель:
        </span>
        <br />
        {fund_name}
      </div>
      <div
        className="text-xl leading-tight uppercase font-semibold mb-1"
        style={{ color: 'rgba(37, 36, 45, 0.7)' }}
      >
        +&nbsp;
        <AppNumberWithSpaces value={amount} />₽
      </div>
      <div className="text-zinc-800 text-xs leading-tight uppercase font-semibold mb-3">
        <span className="font-mono">Жертвователь:</span>
        <br />
        {organization}
      </div>
      <div>
        {statusComponent}
        {paymentStatusComponent}
      </div>
    </div>
  );
};

export default React.memo(AppDonationRequestMiniCard);
