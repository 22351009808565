export const REGISTRY_TABLE_HEADERS = [
  {
    name: 'file_id_and_transaction_id',
    title: 'ID',
    align: 'left',
  },
  {
    name: 'account',
    title: 'счет',
    align: 'right',
    width: '5%',
  },
  {
    name: 'payer',
    title: 'плательщик',
    align: 'center',
    width: '20%',
  },
  {
    name: 'payee',
    title: 'получатель',
    align: 'center',
    width: '10%',
  },
  {
    name: 'inn',
    title: 'инн',
    align: 'right',
    width: '7%',
  },
  {
    name: 'date',
    title: 'дата исполнения',
    align: 'center',
    is_date: true,
    width: '5%',
  },
  {
    name: 'amount',
    title: 'сумма',
    align: 'right',
    localize: true,
    is_number: true,
    width: '7%',
  },
  {
    name: 'purpose',
    title: 'Назначение платежа',
    align: 'left',
    width: '30%',
  },
];

export const REGISTRY_TABLE_HEADERS_WITH_TAGS = [
  ...REGISTRY_TABLE_HEADERS,
  {
    name: 'tag_cloud',
    title: 'Теги',
    align: 'left',
    tagView: true,
  },
];

export const ACCOUNTS_TABLE_HEADERS = [
  {
    name: 'bank_name',
    title: 'Банк',
    align: 'left',
  },
  {
    name: 'corr_account',
    title: 'Корр. счет',
    align: 'right',
  },
  {
    name: 'payment_account',
    title: 'Расчетный счет',
    align: 'right',
  },
  {
    name: 'inn',
    title: 'ИНН',
    align: 'right',
  },
  {
    name: 'opening_date',
    title: 'Дата открытия',
    align: 'center',
  },
  {
    name: 'current_amount',
    title: 'Баланс',
    align: 'right',
    localize: true,
  },
];
