import React, { Component } from 'react';
import { LEGAL_FORMS } from '../../../const/LegalForms';

interface Props {
  onFormChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  selectedForm: string;
  name: string;
}

class AppLegalFormSelect extends Component<Props> {
  render() {
    return (
      <select
        value={this.props.selectedForm || LEGAL_FORMS[0].name}
        name={this.props.name}
        onChange={this.props.onFormChange}
        className={`block text-black w-full px-4 pt-2 pb-2 border border-gray-300 
            focus:outline-none focus:border-primary rounded-lg bg-white
            text-lg font-semibold focus:ring-0 `}
        style={{ minHeight: '60px', height: 'auto', whiteSpace: 'break-spaces' }}
      >
        {LEGAL_FORMS.map((form) => (
          <option key={form.id} value={form.name}>
            {form.name}
          </option>
        ))}
      </select>
    );
  }
}

export default AppLegalFormSelect;
