import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { apiWrapper } from '../../../utils/api';
import { SberUploadFile } from '../../../types/BankAccount';
import styles from '../../../pages/admin/SummaryRegistry/SummaryRegistry.module.scss';
import dayjs from 'dayjs';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

interface State {
  uploads: SberUploadFile[];
}

class AppTransactionUploadsLogDialog extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      uploads: [],
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.isOpen && !prevProps.isOpen) {
      this.fetchUploads();
    }
  }

  async fetchUploads() {
    await apiWrapper.get('/sber_upload_files').then((response: any) => {
      this.setState({ uploads: [...response] });
    });
  }

  render() {
    const { isOpen } = this.props;
    const { uploads } = this.state;

    return (
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={this.props.onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-screen-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-[28px] font-semibold leading-6 text-gray-900"
                  >
                    История загрузки
                  </Dialog.Title>

                  <div className="mt-10">
                    <table className={`${styles.appTable}`}>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Дата и время</th>
                          <th>Банк</th>
                          <th>Номер счета</th>
                          <th>
                            Кол-во
                            <br />
                            транзакций
                          </th>
                          <th>
                            Кем
                            <br />
                            загружено
                          </th>
                          <th>
                            Загруженный
                            <br />
                            документ
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {uploads.map((u, index) => (
                          <tr key={index}>
                            <td className={`text-center`}>{u.id}</td>
                            <td className={`text-center`}>
                              {dayjs(u.created_at).format('DD.MM.YYYY HH:mm')}
                            </td>
                            <td className={`text-center`}>
                              {u.bank_account && <span>{u.bank_account.bank_name}</span>}
                            </td>
                            <td className={`text-center`}>
                              {u.bank_account && <span>{u.bank_account.payment_account}</span>}
                            </td>
                            <td className={`text-center`}>{u.transactions_count}</td>
                            <td className={`text-center`}>{u.user_full_name}</td>
                            <td className={`text-center`}>
                              <a href={u.content} className={`text-[#0085FF]`}>
                                Скачать документ
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  }
}

export default AppTransactionUploadsLogDialog;
