import { apiWrapper } from './api';

export const downloadSbisReport = async (inn: string = ''): Promise<any> => {
  await apiWrapper.get(`/organizations/saby_pdf?inn=${inn}`).then((response: any) => {
    const fileData = atob(response.file_data);
    const byteArray = new Uint8Array(fileData.length);
    for (let i = 0; i < fileData.length; i++) {
      byteArray[i] = fileData.charCodeAt(i);
    }

    const blob = new Blob([byteArray.buffer], {
      type: 'application/pdf',
    });

    // Create a link element
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = response.file_name;
    document.body.appendChild(a);
    a.click();
    a.remove();
  });
};
