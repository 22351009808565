import { apiWrapper } from './api';

export const downloadMinjust = async (fund_id: number | string): Promise<any> => {
  await apiWrapper.get(`/funds/${fund_id}/minjust`).then((response) => {
    const data = Array.isArray(response) ? response : [response];

    data.forEach((file) => {
      const fileData = atob(file.file_data);
      const byteArray = new Uint8Array(fileData.length);
      for (let i = 0; i < fileData.length; i++) {
        byteArray[i] = fileData.charCodeAt(i);
      }

      const blob = new Blob([byteArray.buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      // Create a link element
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = file.file_name;
      document.body.appendChild(a);
      a.click();
      a.remove();
    });
  });
};
