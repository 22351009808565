import React, { Component } from 'react';
import AppAccountBreadcrumb from '../../../components/admin/AppAccountBreadcrumb';
import styles from '../BankAccountsBalance/BankAccounts.module.scss';
import Dashboard from '../../../containers/admin/Dashboard';
import AppNewBankAccountDialog from '../../../components/admin/AppNewBankAccountDialog';
import { apiWrapper } from '../../../utils/api';
import { BankAccount } from '../../../types/BankAccount';
import dayjs from 'dayjs';
import { Tab } from '@headlessui/react';
import { classNames } from '../../../utils/classNames';
import AppInput from '../../../components/shared/AppInput';
import {
  BIK_MASK,
  CORR_ACCOUNT_MASK,
  INN_MASK,
  PAYMENT_ACCOUNT_MASK,
} from '../../../const/InputMasks';

interface AdminBankAccountsPageState {
  loading: boolean;
  isNewBankAccountDialogOpen: boolean;
  bank_accounts: BankAccount[];
  archived_bank_accounts: BankAccount[];
  fundId: number | string | undefined;
  editMode: boolean;
  editId: number | null;
  currentBankAccount: BankAccount | null;
  errors: { [key: string]: string };
}

class AdminBankAccountsPage extends Component<any, AdminBankAccountsPageState> {
  constructor(props: {}) {
    super(props);

    let params = new URLSearchParams(document.location.search);
    let fundId = params.get('fund_id');

    this.state = {
      loading: false,
      isNewBankAccountDialogOpen: false,
      bank_accounts: [],
      archived_bank_accounts: [],
      fundId: fundId ? parseInt(fundId) : undefined,
      editMode: false,
      editId: null,
      currentBankAccount: null,
      errors: {},
    };
  }

  async componentDidMount() {
    this.setState({ loading: true }, async () => {
      await this.getBankAccounts();
      this.setState({ loading: false });
    });
  }

  getBankAccounts = async (): Promise<void> => {
    let me = this;
    await apiWrapper.get(`/bank_accounts?fund_id=${me.state.fundId}`).then((responseData: any) => {
      this.setState({
        bank_accounts: [...responseData],
      });
    });
  };

  getArchived = async (): Promise<void> => {
    let me = this;
    await apiWrapper
      .get(`/bank_accounts/archived?fund_id=${me.state.fundId}`)
      .then((responseData: any) => {
        this.setState({
          archived_bank_accounts: [...responseData],
        });
      });
  };

  handleOnOpenNewBankAccountDialog = (): void => {
    this.setState({ isNewBankAccountDialogOpen: true });
  };

  handleOnCloseNewBankAccountDialog = (): void => {
    this.setState({ isNewBankAccountDialogOpen: false });
  };

  onSubmitNewBankAccount = async (): Promise<void> => {
    await this.getBankAccounts();
  };

  archiveBankAccount = async (id: Number): Promise<void> => {
    if (!id) return;

    const result = window.confirm('Вы уверены, что хотите архивировать этот счёт?');

    if (!result) return;

    this.setState({ loading: true }, async () => {
      try {
        await apiWrapper.post(`/bank_accounts/${id}/archive`, {});

        this.setState((prevState) => {
          const bank_accounts = prevState.bank_accounts.filter((b) => Number(b.id) !== Number(id));
          return { bank_accounts, loading: false };
        });
      } catch (error) {
        console.log(error);
        this.setState({ loading: false });
      }
    });
  };

  unArchiveBankAccount = async (id: Number): Promise<void> => {
    if (!id) return;

    this.setState({ loading: true }, async () => {
      try {
        await apiWrapper.post(`/bank_accounts/${id}/unarchive`, {});

        this.setState((prevState) => {
          const archived_bank_accounts = prevState.archived_bank_accounts.filter(
            (b) => Number(b.id) !== Number(id),
          );
          return { archived_bank_accounts, loading: false };
        });
      } catch (error) {
        console.log(error);
        this.setState({ loading: false });
      }
    });
  };

  enableEditMode = (bank: BankAccount) => {
    this.setState({
      editMode: true,
      editId: Number(bank.id),
      currentBankAccount: bank,
    });
  };

  disableEditMode = () => {
    this.setState({
      editMode: false,
      editId: null,
      currentBankAccount: null,
    });
  };

  isCurrentEditing = (id: number) => {
    return this.state.editMode && this.state.editId === id;
  };

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;

    this.setState((prevState) => {
      if (!prevState.currentBankAccount) {
        return prevState;
      }

      return {
        ...prevState,
        currentBankAccount: {
          ...prevState.currentBankAccount,
          [name]: value,
        },
      };
    });
  };

  handleUpdateBankAccount = async (): Promise<void> => {
    if (!this.state.currentBankAccount) return;

    const { id, bank_name, payment_account, corr_account, inn, bank_bik, opening_date } =
      this.state.currentBankAccount;

    console.log(this.state.currentBankAccount);
    this.setState({ loading: true }, async () => {
      try {
        await apiWrapper
          .patch(`/bank_accounts/${this.state.currentBankAccount?.id}`, {
            bank_name,
            payment_account,
            corr_account,
            inn,
            bank_bik,
            opening_date,
          })
          .then((responseData: any) => {
            console.log(responseData);
            const index = this.state.bank_accounts.findIndex((b) => Number(b.id) === id);
            if (index > -1) {
              this.setState((prevState) => {
                const bank_accounts = [...prevState.bank_accounts];
                bank_accounts[index] = responseData;

                return { bank_accounts };
              });
            }
          });

        this.setState({ loading: false, editMode: false, editId: null, currentBankAccount: null });
      } catch (error) {
        console.log(error);
      }
    });
  };

  render() {
    let me = this;

    const { errors } = this.state;

    return (
      <Dashboard
        content={
          <div className={`pt-10 pb-4 px-4 bg-slate-200`} style={{ height: 'calc(100vh - 112px)' }}>
            <div className="flex flex-row items-center mb-3">
              <div>
                <AppAccountBreadcrumb />
              </div>
              <div className="ml-1">
                <h1 className={`text-[18px] text-[#0F094F]`}>/ Расчетные счета в банках</h1>
              </div>
            </div>

            <button
              onClick={this.handleOnOpenNewBankAccountDialog}
              className={`text-[12px] bg-[#007A22] rounded-lg text-white uppercase font-semibold py-2 px-3 leading-tight`}
            >
              Добавить банковский счет
              <i className="fas fa-file-plus ml-2"></i>
            </button>

            <Tab.Group>
              <Tab.List className="flex space-x-1 rounded-xl bg-slate-200 p-1 mt-4">
                <Tab
                  className={({ selected }) =>
                    classNames(
                      'w-full rounded-lg py-2.5 text-sm font-medium leading-5',
                      'ring-white ring-opacity-60 ring-offset-2 ring-offset-primary focus:outline-none focus:ring-2',
                      selected ? 'bg-white shadow' : 'hover:bg-white/[0.12]',
                    )
                  }
                  onClick={this.getBankAccounts}
                >
                  <div className="flex flex-col items-center uppercase font-semibold">
                    Активные счета
                  </div>
                </Tab>
                <Tab
                  className={({ selected }) =>
                    classNames(
                      'w-full rounded-lg py-2.5 text-sm font-medium leading-5',
                      'ring-white ring-opacity-60 ring-offset-2 ring-offset-primary focus:outline-none focus:ring-2',
                      selected ? 'bg-white shadow' : 'hover:bg-white/[0.12]',
                    )
                  }
                  onClick={this.getArchived}
                >
                  <div className="flex flex-col items-center uppercase font-semibold">
                    Счета в архиве
                  </div>
                </Tab>
              </Tab.List>

              <Tab.Panel>
                <div className={`h-3/4 overflow-scroll`}>
                  {!this.state.loading && (
                    <>
                      {this.state.bank_accounts.length === 0 && (
                        <div className={`text-center font-semibold text-xl`}>Нет данных</div>
                      )}
                      {this.state.bank_accounts.length > 0 && (
                        <table className={`${styles.appTable}`}>
                          <thead>
                            <tr>
                              <th>ID</th>
                              <th>Банк</th>
                              <th>БИК</th>
                              <th>Расчетный счет</th>
                              <th>Корреспондентский счет</th>
                              <th>ИНН</th>
                              <th>Дата открытия</th>
                              <th>Баланс</th>
                              <th colSpan={2}>Действия</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.bank_accounts.map((b: BankAccount) => (
                              <tr key={`bank_account-${b.id}`}>
                                <td className={`text-center`}>{b.id}</td>
                                <td className={`text-left`}>
                                  {this.isCurrentEditing(Number(b.id)) ? (
                                    <>
                                      <AppInput
                                        id="bank_name"
                                        name="bank_name"
                                        label="Наименование банка"
                                        type="text"
                                        value={this.state.currentBankAccount?.bank_name || ''}
                                        onChange={this.handleInputChange}
                                      />
                                      {errors.bank_name && (
                                        <p className="text-red-500 text-sm">{errors.bank_name}</p>
                                      )}
                                    </>
                                  ) : (
                                    <span>{b.bank_name}</span>
                                  )}
                                </td>
                                <td className={`text-right`}>
                                  {this.isCurrentEditing(Number(b.id)) ? (
                                    <>
                                      <AppInput
                                        id="bank_bik"
                                        name="bank_bik"
                                        label="БИК"
                                        type="text"
                                        value={this.state.currentBankAccount?.bank_bik || ''}
                                        onChange={this.handleInputChange}
                                        mask={BIK_MASK}
                                      />
                                      {errors.bank_bik && (
                                        <p className="text-red-500 text-sm">{errors.bank_bik}</p>
                                      )}
                                    </>
                                  ) : (
                                    <span>{b.bank_bik}</span>
                                  )}
                                </td>
                                <td className={`text-right`}>
                                  {this.isCurrentEditing(Number(b.id)) ? (
                                    <>
                                      <AppInput
                                        id="payment_account"
                                        name="payment_account"
                                        label="Расчетный счет"
                                        type="text"
                                        value={this.state.currentBankAccount?.payment_account || ''}
                                        onChange={this.handleInputChange}
                                        mask={PAYMENT_ACCOUNT_MASK}
                                      />
                                      {errors.payment_account && (
                                        <p className="text-red-500 text-sm">
                                          {errors.payment_account}
                                        </p>
                                      )}
                                    </>
                                  ) : (
                                    <span>{b.payment_account}</span>
                                  )}
                                </td>
                                <td className={`text-right`}>
                                  {this.isCurrentEditing(Number(b.id)) ? (
                                    <>
                                      <AppInput
                                        id="corr_account"
                                        name="corr_account"
                                        label="Корреспондентский счет"
                                        type="text"
                                        value={this.state.currentBankAccount?.corr_account || ''}
                                        onChange={this.handleInputChange}
                                        mask={CORR_ACCOUNT_MASK}
                                      />
                                      {errors.corr_account && (
                                        <p className="text-red-500 text-sm">
                                          {errors.corr_account}
                                        </p>
                                      )}
                                    </>
                                  ) : (
                                    <span>{b.corr_account}</span>
                                  )}
                                </td>
                                <td className={`text-right`}>
                                  {this.isCurrentEditing(Number(b.id)) ? (
                                    <>
                                      <AppInput
                                        id="inn"
                                        name="inn"
                                        label="ИНН"
                                        type="text"
                                        value={this.state.currentBankAccount?.inn || ''}
                                        onChange={this.handleInputChange}
                                        mask={INN_MASK}
                                      />
                                      {errors.inn && (
                                        <p className="text-red-500 text-sm">{errors.inn}</p>
                                      )}
                                    </>
                                  ) : (
                                    <span>{b.inn}</span>
                                  )}
                                </td>
                                <td className={`text-center`}>
                                  {this.isCurrentEditing(Number(b.id)) ? (
                                    <>
                                      <AppInput
                                        id="opening_date"
                                        name="opening_date"
                                        label="Дата открытия"
                                        type="date"
                                        value={this.state.currentBankAccount?.opening_date || ''}
                                        onChange={this.handleInputChange}
                                      />
                                      {errors.opening_date && (
                                        <p className="text-red-500 text-sm">
                                          {errors.opening_date}
                                        </p>
                                      )}
                                    </>
                                  ) : (
                                    <span>{dayjs(b.opening_date).format('DD.MM.YYYY')}</span>
                                  )}
                                </td>
                                <td className={`text-right`}>
                                  {b.current_amount ? b.current_amount.toLocaleString() : ''}&nbsp;₽
                                </td>
                                <td className={`text-center cursor-pointer w-[50px]`}>
                                  {this.isCurrentEditing(Number(b.id)) ? (
                                    <div
                                      className={`flex flex-row items-center gap-x-2 justify-around w-full`}
                                    >
                                      <i
                                        className="far fa-save text-xl text-green-500"
                                        onClick={this.handleUpdateBankAccount}
                                      ></i>
                                      <i
                                        className="far fa-times text-xl text-red-800"
                                        onClick={this.disableEditMode}
                                      ></i>
                                    </div>
                                  ) : (
                                    <div
                                      className={`w-full h-full`}
                                      onClick={() => this.enableEditMode(b)}
                                    >
                                      <i className="far fa-edit text-gray-800 text-lg"></i>
                                      <p className={`text-xs text-gray-800`}>Редактировать</p>
                                    </div>
                                  )}
                                </td>
                                <td
                                  className={`text-center cursor-pointer w-[50px]`}
                                  onClick={() => this.archiveBankAccount(Number(b.id))}
                                >
                                  <i className="far fa-archive text-red-500 text-lg"></i>
                                  <p className={`text-xs text-gray-800`}>Архивировать</p>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </>
                  )}

                  {this.state.loading && (
                    <div className={`text-center text-primary`}>
                      <i className="far fa-sync fa-spin fa-3x"></i>
                    </div>
                  )}
                </div>
              </Tab.Panel>

              <Tab.Panel>
                <div className={`h-3/4 overflow-scroll`}>
                  {!this.state.loading && (
                    <>
                      {this.state.archived_bank_accounts.length === 0 && (
                        <div className={`text-center font-semibold text-xl`}>Нет данных</div>
                      )}
                      {this.state.archived_bank_accounts.length > 0 && (
                        <table className={`${styles.appTable}`}>
                          <thead>
                            <tr>
                              <th>ID</th>
                              <th>Банк</th>
                              <th>БИК</th>
                              <th>Расчетный счет</th>
                              <th>Корреспондентский счет</th>
                              <th>ИНН</th>
                              <th>Дата открытия</th>
                              <th>Баланс</th>
                              <th>Действия</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.archived_bank_accounts.map(
                              (b: BankAccount, index: number) => (
                                <tr key={index}>
                                  <td className={`text-center`}>{b.id}</td>
                                  <td className={`text-left`}>{b.bank_name}</td>
                                  <td className={`text-right`}>{b.bank_bik}</td>
                                  <td className={`text-right`}>{b.payment_account}</td>
                                  <td className={`text-right`}>{b.corr_account}</td>
                                  <td className={`text-right`}>{b.inn}</td>
                                  <td className={`text-center`}>
                                    {dayjs(b.opening_date).format('DD.MM.YYYY')}
                                  </td>
                                  <td className={`text-right`}>
                                    {b.current_amount ? b.current_amount.toLocaleString() : ''}
                                  </td>
                                  {/*<td className={`text-center cursor-pointer w-[50px]`}>*/}
                                  {/*  <i className="far fa-edit text-gray-800 text-lg"></i>*/}
                                  {/*  <p className={`text-xs text-gray-800`}>Редактировать</p>*/}
                                  {/*</td>*/}
                                  <td
                                    className={`text-center cursor-pointer w-[50px]`}
                                    onClick={() => this.unArchiveBankAccount(Number(b.id))}
                                  >
                                    <i className="far fa-power-off text-green-500 text-lg"></i>
                                    <p className={`text-xs text-gray-800`}>Активировать</p>
                                  </td>
                                </tr>
                              ),
                            )}
                          </tbody>
                        </table>
                      )}
                    </>
                  )}

                  {this.state.loading && (
                    <div className={`text-center text-primary`}>
                      <i className="far fa-sync fa-spin fa-3x"></i>
                    </div>
                  )}
                </div>
              </Tab.Panel>
            </Tab.Group>

            <AppNewBankAccountDialog
              isOpen={this.state.isNewBankAccountDialogOpen}
              onClose={this.handleOnCloseNewBankAccountDialog}
              onSubmit={this.onSubmitNewBankAccount}
              fundId={me.state.fundId}
            />
          </div>
        }
      />
    );
  }
}

export default AdminBankAccountsPage;
