import React from 'react';

interface AppBackBtnProps {
  className?: string;
}

const AppBackBtn: React.FC<AppBackBtnProps> = ({ className }) => {
  const handleClick = () => {
    window.history.back();
  };

  return (
    <button
      data-testid="back-btn"
      className={`
        bg-transparent
        text-blue-gray-400
        text-sm
        py-3
        px-5
        ${className}
        text-center
        rounded-full
        border-blue-gray-400
        border-2
        font-semibold
      `}
      onClick={handleClick}
    >
      <i className="fas fa-chevron-left mr-3"></i>
      Назад
    </button>
  );
};

export default AppBackBtn;
