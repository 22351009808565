import { Component } from 'react';
import Dashboard from '../../../containers/admin/Dashboard';
import AppCard from '../../../components/shared/AppCard';
import AppTabGroup from '../../../components/shared/AppTabGroup';

class SummaryReport extends Component<any, any> {
  render() {
    return (
      <Dashboard
        content={
          <div className={`pt-1 pb-4 px-4 bg-slate-200`} style={{ height: 'calc(100vh - 112px)' }}>
            <div className="grid grid-cols-12 gap-x-2 gap-y-2">
              <div className="col-span-4">
                <AppCard
                  title={`Процент физических и юридических лиц`}
                  content={
                    <div>
                      <svg
                        width="224"
                        height="225"
                        viewBox="0 0 224 225"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M112 1.40137C140.807 1.40137 168.486 12.6005 189.187 32.6323C209.889 52.6642 221.993 79.9596 222.94 108.751C223.887 137.542 213.605 165.574 194.264 186.924C174.924 208.273 148.042 221.268 119.297 223.161C90.5523 225.055 62.1973 215.7 40.2234 197.072C18.2495 178.444 4.378 152.004 1.53999 123.337C-1.29802 94.6701 7.11976 66.0228 25.0146 43.4481C42.9095 20.8735 68.8797 6.13998 97.4377 2.36074L112 112.401V1.40137Z"
                          fill="#E88248"
                          stroke="white"
                          stroke-width="2"
                        />
                      </svg>
                    </div>
                  }
                ></AppCard>
              </div>
              <div className="col-span-4">
                <AppCard
                  title={`Процент успешности поступлений`}
                  content={
                    <div>
                      <svg
                        width="224"
                        height="225"
                        viewBox="0 0 224 225"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M112 1.40137C140.807 1.40137 168.486 12.6005 189.187 32.6323C209.889 52.6642 221.993 79.9596 222.94 108.751C223.887 137.542 213.605 165.574 194.264 186.924C174.924 208.273 148.042 221.268 119.297 223.161C90.5523 225.055 62.1973 215.7 40.2234 197.072C18.2495 178.444 4.378 152.004 1.53999 123.337C-1.29802 94.6701 7.11976 66.0228 25.0146 43.4481C42.9095 20.8735 68.8797 6.13998 97.4377 2.36074L112 112.401V1.40137Z"
                          fill="#E88248"
                          stroke="white"
                          stroke-width="2"
                        />
                      </svg>
                    </div>
                  }
                ></AppCard>
              </div>
              <div className="col-span-4">
                <AppCard title={`ТОП пожертвований `} content={<div></div>}></AppCard>
              </div>

              <div className="col-span-12">
                <AppTabGroup
                  tabs={[
                    {
                      title: 'график успешных платежей',
                      content: <div className={`w-full bg-white h-100`}>chart1</div>,
                    },
                    {
                      title: 'график отклоненных платежей',
                      content: <div className={`w-full bg-white h-100`}>chart2</div>,
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        }
      ></Dashboard>
    );
  }
}

export default SummaryReport;
