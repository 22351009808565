import { Component } from 'react';
import { DonationRequestStatus } from '../../../types/DonationRequest';
import { applicationStatusTranslations } from '../../../types/DonationRequestTranslations';

interface Props {
  status: DonationRequestStatus;
}

class AppDonationRequestStatusLabel extends Component<Props, any> {
  render() {
    const { status } = this.props;

    const getClassName = (): string => {
      switch (status) {
        case DonationRequestStatus.Rejected:
          return 'bg-redGradient';
        case DonationRequestStatus.InProgress:
          return 'bg-primaryGradient';
        case DonationRequestStatus.Approved:
          return 'bg-teal';
        case DonationRequestStatus.Paid:
          return 'bg-greenGradient';
        default:
          return '';
      }
    };

    return (
      <div
        className={`
          py-1 px-2
          rounded-lg 
          min-h-[33px] max-w-[240px] mx-auto 
          flex items-center justify-center 
          text-white leading-tight
          ${getClassName()}
        `}
      >
        {applicationStatusTranslations[status]}
      </div>
    );
  }
}

export default AppDonationRequestStatusLabel;
