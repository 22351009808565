import { Component } from 'react';
import { Slides } from '../../../types/Slide';

interface Props {
  slides?: Slides;
}

interface SliderState {
  activeSlideIndex: number;
  slidesLen: number;
}

class AppSlides extends Component<Props, SliderState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      activeSlideIndex: 0,
      slidesLen: props.slides?.length || 0,
    };
  }

  handleClick = (newIndex: number): void => {
    if (newIndex >= this.state.slidesLen) {
      newIndex = 0;
    }
    if (newIndex < 0) {
      newIndex = this.state.slidesLen - 1;
    }

    this.setState({ activeSlideIndex: newIndex });
  };

  render() {
    const { slides } = this.props;
    const { activeSlideIndex } = this.state;

    return (
      <div>
        {slides &&
          slides.map((slide, index) => (
            <div
              key={slide.title}
              className={`
                grid grid-cols-12 rounded-lg 
                px-8 py-10 text-4xl
                relative
                ${slide.className}
                ${activeSlideIndex === index ? 'block' : 'hidden'}
                h-[55vh]
              `}
              style={slide.css}
            >
              <div className="col-span-12 lg:col-span-5 leading-tight text-[40px]">
                {slide.title}
              </div>
              {slide.icon && (
                <div className={`opacity-50 absolute right-8 top-10`}>
                  <i className={`${slide.icon}`}></i>
                </div>
              )}

              <div className="col-span-12 grid grid-cols-12 mt-auto h-auto">
                <div className="col-span-12 lg:col-span-4">
                  {slide.downloadLink && (
                    <a
                      href={slide.downloadLink}
                      target={`_blank`}
                      className={`inline-block text-primary uppercase text-base font-semibold py-5 px-7 rounded-xl`}
                      style={{
                        background: 'linear-gradient(77.75deg, #FFFFFF 15.26%, #D4D8E7 88.6%)',
                        boxShadow: '0px 6px 15px rgba(16, 16, 31, 0.42)',
                      }}
                    >
                      Материал в разработке
                    </a>
                  )}
                  {slide.caption && (
                    <p className={`text-white opacity-70 text-base leading-tight`}>
                      {slide.caption}
                    </p>
                  )}
                </div>
                <div className="col-span-12 lg:col-span-8 self-center">
                  <div className="flex flex-row gap-x-4 float-right">
                    <button
                      className={`bg-white text-primary rounded-lg h-10 w-10 text-base text-center`}
                      onClick={() => this.handleClick(index - 1)}
                    >
                      <i className={`far fa-chevron-left`}></i>
                    </button>
                    <button
                      className={`bg-white text-primary rounded-lg h-10 w-10 text-base text-center`}
                      onClick={() => this.handleClick(index + 1)}
                    >
                      <i className={`far fa-chevron-right`}></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    );
  }
}

export default AppSlides;
