import React, { Component } from 'react';
import { Fund } from '../../../types/Fund';
import { Filter } from '../../../types/Filter';

interface AppQFilterProps {
  funds: Fund[];
  bgColor?: string;
  onApplyFilter?: (filter: Filter) => void;
}

interface AppQFilterState {
  selectedFundId: number | string | null;
  selectedYear: string;
  selectedQ: string;
}

class AppQFilter extends Component<AppQFilterProps, AppQFilterState> {
  constructor(props: AppQFilterProps) {
    super(props);
    this.state = {
      selectedFundId: null,
      selectedYear: new Date().getFullYear().toString(),
      selectedQ: 'q1',
    };
  }

  onSubmit = (): void => {
    if (this.props.onApplyFilter) {
      this.props.onApplyFilter({
        year: this.state.selectedYear,
        q: this.state.selectedQ,
        fund_id: Number(this.state.selectedFundId) || -1,
      });
    }
  };

  handleChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const { name, value } = event.target;
    this.setState({ [name]: value } as unknown as Pick<AppQFilterState, keyof AppQFilterState>);
  };

  render() {
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 6 }, (v, i) => currentYear - i);

    return (
      <div
        className={`flex flex-row flex-wrap rounded-lg py-2 px-5 items-center gap-x-4 gap-y-2 ${
          this.props.bgColor ? this.props.bgColor : 'bg-primary'
        }`}
      >
        <div className={`text-white uppercase font-semibold text-sm`}>Фильтрация:</div>
        <div className={`w-1/4`}>
          <select
            id="fund"
            name="selectedFundId"
            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
            defaultValue=""
            onChange={this.handleChange}
          >
            <option value="" disabled>
              Выбор фонда
            </option>
            <option value="">Все</option>
            {this.props.funds.map((f: Fund, index: number) => (
              <option value={f.id} key={index}>
                {f.name}
              </option>
            ))}
          </select>
        </div>
        <div className={`text-sm flex flex-row items-center`}>
          <div>
            <select
              id="q"
              name="selectedQ"
              className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
              defaultValue={'q1'}
              onChange={this.handleChange}
            >
              <option value="q1">1 квартал</option>
              <option value="q2">2 квартал</option>
              <option value="q3">3 квартал</option>
              <option value="q4">4 квартал</option>
              <option value="q5">12 месяцев</option>
            </select>
          </div>
        </div>

        <div className={`text-sm flex flex-row items-center`}>
          <div>
            <span className={`mr-4 text-white font-semibold`}>Год</span>
          </div>

          <div>
            <select
              id="year"
              name="selectedYear"
              className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
              defaultValue={new Date().getFullYear().toString()}
              onChange={this.handleChange}
            >
              {years.map((y, index) => (
                <option value={y.toString()} key={index}>
                  {y}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div>
          <button
            type={`button`}
            className={`bg-white text-primary font-semibold uppercase rounded-lg py-2 px-3 cursor-pointer`}
            onClick={this.onSubmit}
            disabled={this.state.selectedFundId === null}
          >
            Применить
          </button>
        </div>
      </div>
    );
  }
}

export default AppQFilter;
