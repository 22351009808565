import React, { Component } from 'react';
import styles from './AppRegistrationSwitch.module.scss';
import { RegistrationType } from '../../../types/RegistrationType';

interface Props {
  onRegistrationTypeChange: (registrationType: RegistrationType) => void;
}

class AppRegistrationSwitch extends Component<Props, any> {
  constructor(props: Props) {
    super(props);
    this.handleButtonClick = this.handleButtonClick.bind(this);
  }

  handleButtonClick(registrationType: RegistrationType) {
    this.props.onRegistrationTypeChange(registrationType);
  }

  render() {
    return (
      <div className={`w-full grid grid-cols-1 lg:grid-cols-1 gap-5`}>
        {/*<div*/}
        {/*  className={`flex w-full ${styles.switchBtn}`}*/}
        {/*  onClick={() => this.handleButtonClick(RegistrationType.USER)}*/}
        {/*>*/}
        {/*  <div className={`flex flex-col w-80`}>*/}
        {/*    <div>*/}
        {/*      <i className="fas fa-user text-5xl"></i>*/}
        {/*    </div>*/}
        {/*    <div className={`uppercase mt-10 font-semibold text-lg`}>физическое лицо</div>*/}
        {/*  </div>*/}
        {/*  <div className={`h-full flex flex-col justify-center text-right w-20`}>*/}
        {/*    <i className="fal fa-chevron-right text-3xl"></i>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div
          className={`flex w-full ${styles.switchBtn}`}
          onClick={() => this.handleButtonClick(RegistrationType.COMPANY)}
        >
          <div className={`flex flex-col w-80`}>
            <div>
              <i className="fas fa-users text-5xl"></i>
            </div>
            <div className={`uppercase mt-10 font-semibold text-lg`}>юридическое лицо</div>
          </div>
          <div className={`h-full flex flex-col justify-center text-right w-20 ml-auto`}>
            <i className="fal fa-chevron-right text-3xl"></i>
          </div>
        </div>
      </div>
    );
  }
}

export default AppRegistrationSwitch;
