import React from 'react';

interface DateTimeInputProps {
  placeholder: string;
  value: Date | null;
  onChange: (_val: Date) => void;
}

interface DateTimeInputState {
  value: Date | null;
}

export default class DateTimeInput extends React.Component<DateTimeInputProps, DateTimeInputState> {
  private inputRef: React.RefObject<HTMLInputElement>;

  constructor(_props: DateTimeInputProps) {
    super(_props);
    this.state = {
      value: this.props.value,
    };

    this.inputRef = React.createRef<HTMLInputElement>();
  }

  render() {
    let me = this;

    return (
      <div
        onClick={() => {
          // alert(me.inputRef.current);
          me.inputRef.current?.showPicker();
        }}
        style={{ cursor: 'pointer', background: 'rgb(15,9,79)' }}
        className={'p-1 relative flex flex-row items-center content-center rounded-xl'}
      >
        {/*<p className={"m-0 mr-2 ml-1 mt-1"}>{me.props.placeholder}</p>*/}
        {/*<p>{me.state.value?.toString()}</p>*/}
        {me.state.value ? (
          <p style={{ color: '#fff', fontSize: '0.8em' }} className={'m-0 ml-2'}>
            {me.state.value.getDate().toString().padStart(2, '0')}.
            {(me.state.value.getMonth() + 1).toString().padStart(2, '0')}.
            {me.state.value.getFullYear()}
          </p>
        ) : (
          <p className={'m-0 ml-1'} style={{ opacity: 0, fontSize: '0.8em' }}>
            00.00.0000
          </p>
        )}

        <input
          placeholder={me.props.placeholder}
          ref={me.inputRef}
          onChange={(_ev: React.ChangeEvent<HTMLInputElement>) => {
            console.log(_ev);
            let val = new Date(_ev.currentTarget.value);
            me.setState(
              {
                value: val,
              },
              () => {
                me.props.onChange(val);
              },
            );
          }}
          style={{
            border: 'none',
            pointerEvents: 'none',
            opacity: 0,
            position: 'absolute',
            top: 0,
          }}
          type={'date'}
        />
        <i
          style={{ cursor: 'pointer', color: 'rgb(255,255,255)' }}
          className={'fas fa-calendar fa-1x ml-2 mr-2'}
        />
      </div>
    );
  }
}
