import React, { FC } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export interface WithRouterProps {
  location?: ReturnType<typeof useLocation>;
  navigate?: ReturnType<typeof useNavigate>;
  params?: ReturnType<typeof useParams>;
}

export function withRouter<P extends object>(
  WrappedComponent: React.ComponentType<P>,
): FC<P & WithRouterProps> {
  // Creating a functional component
  return (props: P) => {
    // Getting the data from the hooks
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    // Rendering the wrapped component with the new props
    return <WrappedComponent {...props} location={location} navigate={navigate} params={params} />;
  };
}
