export enum CategoryType {
  Income = 'income',
  Expense = 'expense',
}

export interface TransactionCategory {
  id: number;
  name: string;
  code: string;
  category_type: CategoryType;
  parent_category_id: null | number;
}
