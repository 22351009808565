import React, { Component } from 'react';
import { Organization, OrganizationEdit } from '../../../types/Organization';
import AppInput from '../../shared/AppInput';
import { UserContext } from '../../../store/user';
import { apiWrapper } from '../../../utils/api';
import AppLegalFormSelect from '../AppLegalFormSelect';
import AppTextarea from '../../shared/AppTextarea';
import { toast } from 'react-toastify';

class AppCompanyEditForm extends Component<any, OrganizationEdit> {
  static contextType = UserContext;
  context!: React.ContextType<typeof UserContext>;

  constructor(props: {}) {
    super(props);
    this.state = {
      legal_form: '',
      full_name: '',
      short_name: '',
      inn: '',
      kpp: '',
      ogrn: '',
      legal_address: '',
      actual_address: '',
      authorized_person: '',
      registration_date: '',
      document: '',
      phone: '',
      email: '',
      errors: {},
    };
  }

  componentDidMount() {
    if (this.context?.is_authed && this.context?.organization) {
      const {
        id,
        legal_form,
        full_name,
        short_name,
        inn,
        kpp,
        ogrn,
        legal_address,
        actual_address,
        authorized_person,
        registration_date,
        document,
        phone,
        email,
      } = this.context?.organization;

      this.setState({
        id: id,
        legal_form: legal_form,
        full_name: full_name,
        short_name: short_name,
        inn: inn,
        kpp: kpp,
        ogrn: ogrn,
        legal_address: legal_address,
        actual_address: actual_address,
        authorized_person: authorized_person,
        registration_date: registration_date,
        document: document,
        phone: phone || '',
        email: email || '',
      });
    }
  }

  handleInputChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ): void => {
    const { name, value } = event.target;
    this.setState({ [name]: value } as unknown as Pick<Organization, keyof Organization>);
  };

  handleSubmit = async () => {
    // TODO: check error

    await apiWrapper
      .patch(`/organizations/${this.state.id}`, {
        organization: {
          legal_form: this.state.legal_form,
          full_name: this.state.full_name,
          short_name: this.state.short_name,
          inn: this.state.inn,
          kpp: this.state.kpp,
          ogrn: this.state.ogrn,
          legal_address: this.state.legal_address,
          actual_address: this.state.actual_address,
          authorized_person: this.state.authorized_person,
          registration_date: this.state.registration_date,
          document: this.state.document,
          email: this.state.email,
          phone: this.state.phone,
        },
      })
      .then(() => {
        toast('Данные успешно обновлены', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          pauseOnHover: true,
        });
      });
  };

  render() {
    const {
      legal_form,
      full_name,
      inn,
      kpp,
      ogrn,
      legal_address,
      actual_address,
      authorized_person,
      document,
      registration_date = '',
      phone = '',
      email = '',
      errors,
    } = this.state;

    return (
      <div className="grid grid-cols-12">
        <div className="col-span-12 xl:col-span-8">
          <span className={`uppercase text-gray-400 text-base font-semibold`}>Данные компании</span>
          <div className="grid grid-cols-12 mt-5">
            <div className="col-span-12 xl:col-span-6 space-y-1.5 mb-1.5 lg:mb-0">
              <AppLegalFormSelect
                selectedForm={legal_form}
                onFormChange={this.handleInputChange}
                name="legal_form"
              />
              {errors.legalForm && <p className="text-red-500 text-sm">{errors.legalForm}</p>}

              <AppTextarea
                id="full_name"
                name="full_name"
                label="Полное наименование"
                value={full_name}
                onChange={this.handleInputChange}
              />
              {errors.full_name && <p className="text-red-500 text-sm">{errors.full_name}</p>}

              <AppInput
                id="inn"
                name="inn"
                label="ИНН"
                type="text"
                value={inn}
                onChange={this.handleInputChange}
                mask={'9999999999'}
              />
              {errors.inn && <p className="text-red-500 text-sm">{errors.inn}</p>}

              <AppInput
                id="kpp"
                name="kpp"
                label="КПП"
                type="text"
                value={kpp}
                onChange={this.handleInputChange}
                mask={'999999999'}
              />
              {errors.kpp && <p className="text-red-500 text-sm">{errors.kpp}</p>}

              <AppInput
                id="ogrn"
                name="ogrn"
                label="ОГРН"
                type="text"
                value={ogrn}
                onChange={this.handleInputChange}
                mask={'999999999999999'}
              />
              {errors.ogrn && <p className="text-red-500 text-sm">{errors.ogrn}</p>}
            </div>
            <div className="col-span-12 xl:col-span-6 space-y-1.5 xl:ml-5">
              <AppTextarea
                id="legal_address"
                name="legal_address"
                label="Юридический адрес"
                value={legal_address}
                onChange={this.handleInputChange}
              />
              {/*<AppInput*/}
              {/*  id="legal_address"*/}
              {/*  name="legal_address"*/}
              {/*  label="Юридический адрес"*/}
              {/*  type="text"*/}
              {/*  value={legal_address}*/}
              {/*  onChange={this.handleInputChange}*/}
              {/*/>*/}
              {errors.legalAddress && <p className="text-red-500 text-sm">{errors.legalAddress}</p>}

              <AppTextarea
                id="actual_address"
                name="actual_address"
                label="Фактический адрес"
                value={actual_address}
                onChange={this.handleInputChange}
              />
              {/*<AppInput*/}
              {/*  id="actual_address"*/}
              {/*  name="actual_address"*/}
              {/*  label="Фактический адрес"*/}
              {/*  type="text"*/}
              {/*  value={actual_address}*/}
              {/*  onChange={this.handleInputChange}*/}
              {/*/>*/}
              {errors.actualAddress && (
                <p className="text-red-500 text-sm">{errors.actualAddress}</p>
              )}

              <AppInput
                id="authorized_person"
                name="authorized_person"
                label="Руководитель / уполномоченное лицо"
                type="text"
                value={authorized_person}
                onChange={this.handleInputChange}
              />
              {errors.authorizedPerson && (
                <p className="text-red-500 text-sm">{errors.authorizedPerson}</p>
              )}

              <AppInput
                id="document"
                name="document"
                label="Документ на основании, которого действует Руководитель / уполномоченное лицо"
                type="text"
                value={document}
                onChange={this.handleInputChange}
              />
              {errors.document && <p className="text-red-500 text-sm">{errors.document}</p>}

              <AppInput
                id="registration_date"
                name="registration_date"
                label="Дата регистрации"
                type="date"
                value={registration_date}
                onChange={this.handleInputChange}
              />
              {errors.registration_date && (
                <p className="text-red-500 text-sm">{errors.registration_date}</p>
              )}
            </div>
          </div>
        </div>
        <div className="col-span-12 xl:col-span-4 lg:ml-10 mt-5 lg:mt-0">
          <span className={`uppercase text-gray-400 text-base font-semibold`}>
            Контактные данные
          </span>
          <div className="grid grid-cols-12 mt-5">
            <div className="col-span-12 space-y-1.5">
              <AppInput
                id="phone"
                name="phone"
                label="телефон"
                type="text"
                value={phone}
                onChange={this.handleInputChange}
                mask={'+7 (999) 999-99-99'}
              />
              {errors.phone && <p className="text-red-500 text-sm">{errors.phone}</p>}

              <AppInput
                id="email"
                name="email"
                label="ЭЛЕКТРОННАЯ ПОЧТА"
                type="email"
                value={email}
                onChange={this.handleInputChange}
              />
              {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
            </div>
          </div>
        </div>
        <div className="col-span-12 mt-10">
          <button
            onClick={() => this.handleSubmit()}
            className={`text-[#828193] border-2 border-[#C9C8D6] font-semibold rounded-lg py-3 px-10 cursor-pointer`}
          >
            Изменить данные
          </button>
        </div>
      </div>
    );
  }
}

export default AppCompanyEditForm;
