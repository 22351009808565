import React from 'react';
import { Organization } from '../types/Organization';

export interface UserState {
  id: number;
  email: string;
  phone?: string;
  full_name?: string;
  is_moderator: boolean;
  is_authed: boolean;
  organization?: Organization;
  loading: boolean;
}

export interface UserContextType extends UserState {
  setUser: (user: UserState) => void;
}

export const UserContext = React.createContext<UserContextType>({
  id: -1,
  email: '',
  phone: '',
  is_moderator: false,
  is_authed: false,
  full_name: '',
  loading: false,
  setUser: () => {},
});
