import React, { useState } from 'react';
import { apiWrapper } from '../../../utils/api';
import { classNames } from '../../../utils/classNames';

interface Props {
  status?: null | undefined | boolean;
  title: string;
  name: string;
  id: number;
  verificationItems?: any[];
  donationStatus: string;
  onClickManualVerification: (verification: { name: string; title: string }) => void;
  onRemove?: () => void;
}

const AppDonationRequestCheckingItem: React.FC<Props> = ({
  status,
  title,
  name,
  id,
  verificationItems,
  donationStatus,
  onClickManualVerification,
  onRemove,
}) => {
  const [showComment] = useState(false);
  const [loading, setLoading] = useState(false);

  const destroyVerificationItem = async (_donation: any) => {
    setLoading(true);
    const url = `/donation_requests/${_donation.donation_request_id}/verification_items/${_donation.id}`;
    try {
      await apiWrapper.delete(url);
      if (onRemove) onRemove();
    } catch (error) {
      console.error('Error deleting verification item:', error);
      // Optionally, display an error message to the user
    } finally {
      setLoading(false);
    }
  };

  let statusText;
  switch (status) {
    case false:
      statusText = 'Проверка не пройдена';
      break;
    case null:
    case undefined:
      statusText = 'В обработке';
      break;
    case true:
      statusText = 'Проверка проведена успешно';
      break;
    default:
      statusText = 'Не определено';
  }

  const isValid = status === true;
  const isNotValid = status === false;
  const inProgress = status === null || status === undefined;

  let verificationItem: any = null;
  if (verificationItems) {
    const filteredItems = verificationItems.filter((_x: any) => JSON.stringify(_x).includes(name));
    if (filteredItems.length > 0) {
      verificationItem = filteredItems[filteredItems.length - 1];
    }
  }

  return (
    <div
      data-testid="status-container"
      className={classNames(
        'border-2 py-1 px-3 rounded-lg grid grid-cols-12',
        isNotValid ? 'border-red-800 bg-[#a72c2c0c]' : '',
        inProgress ? 'border-gray-300 bg-[#c9c8d60c]' : '',
        isValid ? 'border-green-500 bg-[#2eba550c]' : '',
      )}
    >
      <div className="col-span-1">
        <div
          className={classNames(
            'w-6 h-6 2xl:w-8 2xl:h-8 rounded-full flex items-center justify-center',
            isNotValid ? 'bg-redGradient' : '',
            isValid ? 'bg-greenGradient' : '',
          )}
        >
          {isNotValid && <i className="fas fa-times text-white text-lg"></i>}
          {inProgress && <i className="fas fa-spinner fa-spin text-gray-400 text-2xl"></i>}
          {isValid && <i className="fas fa-check text-white text-lg"></i>}
        </div>
      </div>
      <div className="col-span-11 lg:pl-2">
        <div className="text-neutral-900 leading-none font-semibold">{title}</div>
        <div
          className={classNames(
            'font-semibold uppercase text-xs mt-1',
            isNotValid ? 'text-red-800' : '',
            inProgress ? 'text-gray-400' : '',
            isValid ? 'text-green-500' : '',
          )}
        >
          {statusText}
        </div>
      </div>
      {verificationItem && (
        <div
          className="w-full col-span-12 mt-2 p-2 rounded-lg"
          style={{
            background: isNotValid ? '#f1d5d5' : isValid ? '#ebf4e8' : '',
          }}
        >
          <div className="flex justify-between items-center">
            <p className="opacity-90 m-0 text-[12px] font-semibold leading-tight">
              Верификация проведена вручную
            </p>
            <button
              className="text-[12px] font-semibold underline m-0 opacity-90 text-red-600"
              onClick={() => !loading && destroyVerificationItem(verificationItem)}
              disabled={loading}
            >
              {loading ? <i className="fas fa-spinner fa-spin"></i> : 'Отменить'}
            </button>
          </div>
          <div className="flex items-center mt-2">
            <i className="fal fa-user fa-1x mr-2"></i>
            <p className="opacity-90 m-0 text-[12px] font-semibold leading-tight">
              {verificationItem.user.full_name}
            </p>
          </div>
          {(showComment || true) && (
            <p
              className="opacity-90 mt-2 pl-2"
              style={{
                borderLeft: `1px solid ${isNotValid ? '#ba2e2e' : isValid ? '#17a63f' : ''}`,
                textTransform: 'none',
              }}
            >
              {verificationItem.comment}
            </p>
          )}
        </div>
      )}
      <div className="col-span-12 mt-2 mb-1">
        <button
          className="w-full py-2 px-2 uppercase text-white font-semibold text-sm bg-primaryGradient rounded-lg"
          onClick={() =>
            onClickManualVerification({
              title,
              name,
            })
          }
          disabled={['REJECTED', 'PAID', 'ACCEPTED'].includes(donationStatus.toUpperCase())}
        >
          Верифицировать вручную
        </button>
      </div>
    </div>
  );
};

export default AppDonationRequestCheckingItem;
