import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { apiWrapper } from '../../../utils/api';

const months = [
  { name: 'Январь', value: 1 },
  { name: 'Февраль', value: 2 },
  { name: 'Март', value: 3 },
  { name: 'Апрель', value: 4 },
  { name: 'Май', value: 5 },
  { name: 'Июнь', value: 6 },
  { name: 'Июль', value: 7 },
  { name: 'Август', value: 8 },
  { name: 'Сентябрь', value: 9 },
  { name: 'Октябрь', value: 10 },
  { name: 'Ноябрь', value: 11 },
  { name: 'Декабрь', value: 12 },
];

const FundReportDownload = ({ activeFundId }: { activeFundId: string | number }) => {
  const [selectedMonth, setSelectedMonth] = useState<number>(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
  const [startDate, setStartDate] = useState<string>(new Date().toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState<string>(new Date().toISOString().split('T')[0]);

  const handleDownloadReport = async (url: string, fileName: string) => {
    try {
      const response = await apiWrapper.getBlob(url);

      const blob = new Blob([response], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error('Error downloading the report:', error);
      toast.error('Возникла ошибка при генерации отчета. Попробуйте еще раз');
    }
  };

  const handleMonthlyReportDownload = () => {
    const url = `/funds/${activeFundId}/report_by_transaction_categories?month=${selectedMonth}&year=${selectedYear}`;
    handleDownloadReport(url, `отчет_${selectedMonth}_${selectedYear}.xlsx`).then(() => {});
  };

  const handleAnnualSummaryReportDownload = () => {
    const url = `/funds/${activeFundId}/annual_summary_report_fprsr?start_date=${startDate}&end_date=${endDate}`;
    handleDownloadReport(url, `сводный_отчет_${startDate}_${endDate}.xlsx`).then(() => {});
  };

  return (
    <div className="border border-[#C5C4D7] rounded-lg p-5 mt-4">
      {/* Monthly Report Section */}
      <div className="mb-6">
        <div className="flex flex-row items-center justify-between mb-4">
          <div className="uppercase text-white bg-primary font-semibold py-2 px-5 rounded-xl text-sm">
            Отчет о деятельности фонда (Месячный)
          </div>
        </div>

        <div className="flex flex-row items-center justify-start w-3/4 gap-4">
          {/* Month Selector */}
          <div className="flex flex-col items-start">
            <label className="block text-sm font-medium text-gray-700">Месяц</label>
            <select
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(Number(e.target.value))}
            >
              {months.map((month) => (
                <option key={month.value} value={month.value}>
                  {month.name}
                </option>
              ))}
            </select>
          </div>

          {/* Year Selector */}
          <div className="flex flex-col items-start">
            <label className="block text-sm font-medium text-gray-700">Год</label>
            <select
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
              value={selectedYear}
              onChange={(e) => setSelectedYear(Number(e.target.value))}
            >
              {[2023, 2024].map((_, i) => {
                const year = new Date().getFullYear() - i;
                return (
                  <option key={year} value={year}>
                    {year}
                  </option>
                );
              })}
            </select>
          </div>

          {/* Download Button */}
          <button
            onClick={handleMonthlyReportDownload}
            className="bg-primary text-white font-semibold py-2 px-6 rounded-lg hover:bg-primary-dark self-end"
          >
            Скачать
          </button>
        </div>
      </div>

      {/* Annual Summary Report Section */}
      <div>
        <div className="flex flex-row items-center justify-between mb-4">
          <div className="uppercase text-white bg-primary font-semibold py-2 px-5 rounded-xl text-sm">
            Годовой сводный отчет о деятельности фондов регионального сотрудничества и развития
          </div>
        </div>

        <div className="flex flex-row items-center justify-start w-3/4 gap-4">
          {/* Start Date Selector */}
          <div className="flex flex-col items-start">
            <label className="block text-sm font-medium text-gray-700">Начало периода</label>
            <input
              type="date"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>

          {/* End Date Selector */}
          <div className="flex flex-col items-start">
            <label className="block text-sm font-medium text-gray-700">Конец периода</label>
            <input
              type="date"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>

          {/* Download Button */}
          <button
            onClick={handleAnnualSummaryReportDownload}
            className="bg-primary text-white font-semibold py-2 px-6 rounded-lg hover:bg-primary-dark self-end"
          >
            Скачать
          </button>
        </div>
      </div>
    </div>
  );
};

export default FundReportDownload;
