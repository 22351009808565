import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { UserContext, UserContextType, UserState } from '../store/user';
import { apiWrapper } from '../utils/api';

interface UserProviderProps {
  children: ReactNode;
}

const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [state, setState] = useState<UserContextType>({
    id: -1,
    email: '',
    phone: '',
    is_moderator: false,
    is_authed: false,
    loading: true,
    setUser: (user: UserState) => setState((prev) => ({ ...prev, ...user })),
  });

  const redirectUser = useCallback(() => {
    const isAdminPage = window.location.pathname.includes('admin');
    const redirectUrl = isAdminPage ? '/admin/login' : '/login';

    if (window.location.pathname !== redirectUrl) {
      window.location.replace(redirectUrl);
    }
  }, []);

  const loginUser = useCallback(async () => {
    const token = localStorage.getItem('auth_token');

    if (token) {
      try {
        const responseData = (await apiWrapper.get<{ user: UserState; organization?: any }>(
          '/users/me',
        )) as any;
        setState((prev) => ({
          ...prev,
          id: responseData.user.id,
          email: responseData.user.email,
          phone: responseData.user.phone || '',
          is_moderator: responseData.user.is_moderator,
          full_name: responseData.user.full_name || '',
          is_authed: true,
          organization: responseData.organization || null,
          loading: false,
        }));
      } catch (error) {
        console.error('Error fetching user data:', error);
        redirectUser();
      }
    } else {
      redirectUser();
    }
  }, [redirectUser]);

  useEffect(() => {
    if (!state.is_authed) {
      setState((prev) => ({ ...prev, loading: true }));
      loginUser().finally(() => {
        setState((prev) => ({ ...prev, loading: false }));
      });
    }
  }, [state.is_authed, loginUser]);

  if (state.loading) {
    return (
      <div className="w-screen h-screen bg-primary z-10 flex flex-col items-center justify-center">
        <i className="far fa-sync fa-spin text-white fa-5x"></i>
        <p className="text-center text-white font-semibold uppercase text-lg mt-10">Загрузка</p>
      </div>
    );
  }

  return <UserContext.Provider value={state}>{children}</UserContext.Provider>;
};

export default UserProvider;
