import React, { useState } from 'react';
import { DonationRequest } from '../../../types/DonationRequest';
import dayjs from 'dayjs';
import AppNumberWithSpaces from '../../../components/shared/AppNumberWithSpaces';
import AppDonationRequestStatusLabel from '../../../components/shared/AppDonationRequestStatusLabel';
import { convertStatusStringToEnum } from '../../../types/DonationRequestTranslations';
import { downloadLetter } from '../../../utils/downloadLetter';
import { downloadAgreement } from '../../../utils/downloadAgreement';
import { downloadUsageReportDonationRequest } from '../../../utils/downloadUsageReportDonationRequest';
import AppTableDocumentBtn from '../../../components/shared/AppTableDocumentBtn';

interface DonationRequestRowProps {
  donationRequest: DonationRequest;
  onOpenHandler: (donationRequest: DonationRequest) => void;
  rowStyle: string;
}

const DonationRequestRow: React.FC<DonationRequestRowProps> = ({
  donationRequest,
  onOpenHandler,
  rowStyle,
}) => {
  const [letterLoading, setLetterLoading] = useState<boolean>(false);
  const [agreementLoading, setAgreementLoading] = useState<boolean>(false);
  const [reportLoading, setReportLoading] = useState<boolean>(false);

  const handleDownloadLetter = async () => {
    setLetterLoading(true);
    try {
      await downloadLetter(Number(donationRequest.id));
    } catch (error) {
      console.error('Error downloading letter:', error);
    } finally {
      setLetterLoading(false);
    }
  };

  const handleDownloadAgreement = async () => {
    setAgreementLoading(true);
    try {
      await downloadAgreement(Number(donationRequest.id));
    } catch (error) {
      console.error('Error downloading agreement:', error);
    } finally {
      setAgreementLoading(false);
    }
  };

  const handleDownloadReport = async () => {
    setReportLoading(true);
    try {
      await downloadUsageReportDonationRequest(Number(donationRequest.id));
    } catch (error) {
      console.error('Error downloading usage report:', error);
    } finally {
      setReportLoading(false);
    }
  };

  const isPaid = Number(donationRequest.debet) === Number(donationRequest.amount);
  const paymentStatusClass = isPaid ? 'bg-greenGradient' : 'bg-redGradient';

  return (
    <tr className={rowStyle}>
      <td className="p-3 text-center font-semibold text-zinc-800 border border-gray-300">
        {donationRequest.code}
      </td>
      <td className="p-3 text-center font-medium text-sm text-zinc-800 border border-gray-300">
        <AppDonationRequestStatusLabel status={convertStatusStringToEnum(donationRequest.status)} />
      </td>
      <td className="p-3 text-center font-medium text-sm text-zinc-800 border border-gray-300">
        <div className={`py-1 px-2 rounded-lg text-white ${paymentStatusClass}`}>
          {isPaid ? 'Заявка оплачена' : 'Заявка не оплачена'}
        </div>
      </td>
      <td className="p-3 text-center font-semibold text-zinc-800 border border-gray-300">
        {dayjs(donationRequest.created_at).format('DD.MM.YYYY')}
      </td>
      <td className="p-3 text-center font-semibold text-zinc-800 border border-gray-300">
        {donationRequest.status_updated_at &&
          dayjs(donationRequest.status_updated_at).format('DD.MM.YYYY')}
      </td>
      <td className="p-3 text-center font-semibold text-zinc-800 border border-gray-300">
        {dayjs(donationRequest.donation_organization_info.registration_date).format('DD.MM.YYYY')}
      </td>
      <td className="p-3 text-center font-semibold text-zinc-800 border border-gray-300">
        {donationRequest.donation_organization_info.short_name}
      </td>
      <td className="p-3 text-right font-semibold text-zinc-800 border border-gray-300">
        <AppNumberWithSpaces value={Number(donationRequest.amount) || 0} />
        &nbsp;₽
      </td>
      <td className="p-3 text-left font-semibold text-zinc-800 border border-gray-300">
        {donationRequest?.donation_organization_info?.authorized_person || 'не указано'}
      </td>
      <td className="p-3 text-center font-semibold text-zinc-800 border border-gray-300">
        <AppTableDocumentBtn
          title="Письмо"
          icon={<i className="far fa-envelope"></i>}
          onClick={handleDownloadLetter}
          loading={letterLoading}
        />
      </td>
      <td className="p-3 text-center font-semibold text-zinc-800 border border-gray-300">
        <AppTableDocumentBtn
          title="Договор"
          onClick={handleDownloadAgreement}
          loading={agreementLoading}
        />
      </td>
      <td className="p-3 text-center font-semibold text-zinc-800 border border-gray-300">
        {donationRequest?.is_report_needed && (
          <AppTableDocumentBtn
            title="Отчет по использованию пожертвования"
            onClick={handleDownloadReport}
            loading={reportLoading}
          />
        )}
      </td>
      <td className="p-3 text-center font-semibold text-zinc-800 border border-gray-300">
        <button
          className="bg-primary text-sm text-white py-2 font-semibold rounded-lg w-full"
          aria-label="Открыть"
          onClick={() => onOpenHandler(donationRequest)}
        >
          Открыть
        </button>
      </td>
    </tr>
  );
};

export default DonationRequestRow;
