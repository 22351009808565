import React, { Component } from 'react';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

class AppEntityPercentageDoughnutChart extends Component<any, any> {
  options = {
    responsive: true,
    aspectRatio: 1,
    plugins: {
      legend: {
        display: false,
        // position: 'bottom' as const,
      },
    },
  };

  render() {
    let me = this;

    return (
      <>
        <div
          className={'flex mb-4 flex-row content-center items-center justify-center'}
          style={{
            marginRight: 'auto',
            marginLeft: 'auto',
            height: '30vh',
          }}
        >
          <Doughnut data={me.props.data} options={me.options} />
        </div>
        {me.props.data.labels.map((_p: any, _pIndex: number) => {
          return (
            <div className={'flex content-center items-center'} key={`${_pIndex}`}>
              <span
                style={{
                  minWidth: '30px',
                  height: '15px',
                  display: 'flex',
                  background: me.props.data.datasets[0].backgroundColor[_pIndex],
                }}
              ></span>
              <p className={'ml-2'}>{_p}</p>
            </div>
          );
        })}
      </>
    );
  }
}

export default AppEntityPercentageDoughnutChart;
